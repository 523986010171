import React, { JSX } from 'react';

import { emptyValue, StandardValueType } from 'models/app/standardValues';

import { decorateValue, formatValue } from './StandardValue.helpers';
import Styled from './StandardValue.styled';
import { StandardValueProps } from './StandardValue.types';


function StandardValue({
    value,
    dataTestId,
    searchQuery,
    valueType,
    dateFormat,
    timeFormat,
    showTime = false,
    showTimeInTooltip = true,
    disableTextEllipsis = false,
    showCopyToClipboard = true,
    showTooltip = true,
    convertFromUTC,
    userPreferences,
    precision,
    amountValuePrefix,
    t,
    tReady,
    ...boxProps
}: StandardValueProps): JSX.Element {
    const isTextEllipsisDisabled = disableTextEllipsis || valueType === StandardValueType.AMOUNT;
    const formattedValue = formatValue({
        userPreferences,
        valueType: valueType || StandardValueType.TEXT,
        value,
        dateFormat,
        showTime,
        timeFormat,
        precision,
        amountValuePrefix,
        convertFromUTC,
    });
    const isAmount = valueType === StandardValueType.AMOUNT;
    const isEmpty = formattedValue === emptyValue;
    const decoratedValue = decorateValue({
        userPreferences,
        timeFormat,
        dateFormat,
        valueType,
        value,
        formattedValue,
        searchQuery,
        showCopyToClipboard,
        isTextEllipsisDisabled,
        isEmpty,
        showTimeInTooltip,
        isAmount,
        showTooltip,
        t,
    });

    return (
        <Styled.StandardValueWrapper
            className={isAmount ? 'amount-type-value' : ''}
            {...boxProps}
            data-test-id={dataTestId}
            onClick={(e) => e.stopPropagation()}
        >
            {decoratedValue}
        </Styled.StandardValueWrapper>
    );
}

export default StandardValue;

