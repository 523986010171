import { Box, Skeleton } from '@chakra-ui/react';
import * as React from 'react';

import RefreshButton from 'components/common/atoms/RefreshButton';

import Styled from './StandardDataListFooter.styled';
import { StandardDataTableFooterProps } from './StandardDataListFooter.types';


function StandardDataListFooter({
    queryParams,
    fetchData,
    t,
    isListReady,
}: StandardDataTableFooterProps) {

    return (
        <Box>
            <Styled.StandardDataListFooterWrapper>
                <Styled.StandardDataListFooterRefreshWrapper>
                    {isListReady ? (
                        <RefreshButton onClick={() => fetchData(queryParams)} t={t} />
                    ) : (
                        <Skeleton height="20px" width="70px" />
                    )}
                </Styled.StandardDataListFooterRefreshWrapper>
            </Styled.StandardDataListFooterWrapper>
        </Box>
    );
}


export default StandardDataListFooter;
