import { Action } from '@manigo/manigo-commons';
import { createReducer } from '@reduxjs/toolkit';

import { LoginType } from 'models/domain/authorisation';

import { addLoadingState } from 'utils/redux-tools';

import {
    CLEAR_CURRENT_USER,
    EXTEND_TOKEN_VALIDITY, EXTEND_TOKEN_VALIDITY_FAILURE,
    EXTEND_TOKEN_VALIDITY_SUCCESS,
} from 'store/current-user/actions.types';

import {
    ACTIVATE_USER, ACTIVATE_USER_FAILURE, ACTIVATE_USER_SUCCESS, CLEAR_ALL_PASSCODE_DIGITS,
    CLEAR_LOGIN_STATE,
    CONFIRM_LOGIN,
    CONFIRM_LOGIN_FAILURE,
    CONFIRM_LOGIN_SUCCESS,
    INITIALISE_LOGIN,
    INITIALISE_LOGIN_FAILURE,
    INITIALISE_LOGIN_SUCCESS, InitialiseLoginPayload, InitialiseLoginSuccessPayload,
    REFRESH_TOKEN, REFRESH_TOKEN_FAILURE, REFRESH_TOKEN_SUCCESS,
    SET_LOGIN_TYPE,
    SET_NEW_PASSWORD,
    SET_NEW_PASSWORD_FAILURE,
    SET_NEW_PASSWORD_SUCCESS, SET_PASSCODE_DIGIT,
    SIGN_UP,
    SIGN_UP_FAILURE,
    SIGN_UP_SUCCESS,
} from './actions.types';
import handleAuthFailure from './reducer.helpers';
import { AuthorisationStateType } from './reducer.types';


export const authorisationReducerName = 'authorisation';


const initialLoginConfirmationValues = {
    digit1Value: '',
    digit2Value: '',
    digit3Value: '',
    digit4Value: '',
    digit5Value: '',
    digit6Value: '',
};

const initialState: AuthorisationStateType = {
    isLoadingLoginForm: false,
    isLoadingLoginConfirmationForm: false,
    isLoadingForgetPassword: false,
    isLoadingSetNewPassword: false,
    isRefreshingSession: false,

    loginRequestId: undefined,
    loginToken: undefined,
    submittedLoginField: undefined,
    loginFormErrorMessage: undefined,
    loginConfirmFormError: undefined,

    loginBlockUntil: undefined,
    loginPermanentBlock: false,

    loginConfirmationValues: { ...initialLoginConfirmationValues },

    loginType: LoginType.EMAIL,
};

const createAuthorisationReducerBuilderCases = (builder) => {
    builder
        .addCase(INITIALISE_LOGIN, (state: AuthorisationStateType, action: Action<InitialiseLoginPayload>) => {
            state.isLoadingLoginForm = true;
            state.loginFormErrorMessage = undefined;
            state.submittedLoginField = action.payload.login;
        })
        .addCase(INITIALISE_LOGIN_SUCCESS, (state: AuthorisationStateType, action: Action<InitialiseLoginSuccessPayload>) => {
            state.isLoadingLoginForm = false;
            state.loginRequestId = action.payload.id;
            state.loginToken = action.payload.token;
        })
        .addCase(INITIALISE_LOGIN_FAILURE, (state: AuthorisationStateType, action) => {
            const errorMessage = action.payload.error.message;

            state.isLoadingLoginForm = false;
            state.loginFormErrorMessage = errorMessage;
            state.submittedLoginField = action.payload.login;

            handleAuthFailure(state, errorMessage);
        })
        .addCase(CLEAR_LOGIN_STATE, (state: AuthorisationStateType) => {
            return {
                ...state,
                loginBlockUntil: initialState.loginBlockUntil,
                loginPermanentBlock: initialState.loginPermanentBlock,
                submittedUserLoginField: initialState.submittedLoginField,
                loginConfirmFormError: initialState.loginConfirmFormError,
                loginFormErrorMessage: initialState.loginFormErrorMessage,
            };
        })
        .addCase(CONFIRM_LOGIN, (state: AuthorisationStateType) => {
            state.isLoadingLoginConfirmationForm = true;
        })
        .addCase(CONFIRM_LOGIN_SUCCESS, (state: AuthorisationStateType) => {
            state.isLoadingLoginConfirmationForm = false;
        })
        .addCase(CONFIRM_LOGIN_FAILURE, (state: AuthorisationStateType, action) => {
            state.isLoadingLoginConfirmationForm = false;
            state.loginConfirmFormError = action.payload;

            handleAuthFailure(state, action.payload);
        })
        .addCase(SET_PASSCODE_DIGIT, (state: AuthorisationStateType, action) => {
            state.loginConfirmationValues[action.payload?.key] = action.payload?.value;
        })
        .addCase(CLEAR_ALL_PASSCODE_DIGITS, (state: AuthorisationStateType) => {
            state.loginConfirmationValues = { ...initialLoginConfirmationValues };
            state.loginConfirmFormError = undefined;
        })
        .addCase(SET_LOGIN_TYPE, (state: AuthorisationStateType, action: Action<LoginType>) => {
            state.loginType = action.payload;
        })
        .addCase(CLEAR_CURRENT_USER, () => ({ ...initialState }));

    addLoadingState([ACTIVATE_USER, ACTIVATE_USER_SUCCESS, ACTIVATE_USER_FAILURE], 'isLoadingActivateUser', builder);
    addLoadingState([SET_NEW_PASSWORD, SET_NEW_PASSWORD_SUCCESS, SET_NEW_PASSWORD_FAILURE], 'isLoadingSetNewPassword', builder);
    addLoadingState([SIGN_UP, SIGN_UP_SUCCESS, SIGN_UP_FAILURE], 'isLoadingSignUpForm', builder);
    addLoadingState([REFRESH_TOKEN, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE], 'isRefreshingSession', builder);
    addLoadingState([EXTEND_TOKEN_VALIDITY, EXTEND_TOKEN_VALIDITY_SUCCESS, EXTEND_TOKEN_VALIDITY_FAILURE], 'isRefreshingSession', builder);
};

export default createReducer(initialState, createAuthorisationReducerBuilderCases);
