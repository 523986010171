import { Grid, GridItem } from '@chakra-ui/react';
import { getPropObj } from '@manigo/manigo-commons';
import React from 'react';

import { ColumnConfiguration } from 'models/app/tableColumnsConfiguration';


export const renderColumn = (column: ColumnConfiguration<any>, item) => {
    const propertyPath = column?.dataIndex;
    const propertyValue = propertyPath ? getPropObj(item, propertyPath) : '-';
    return (
        <>
            {column.render
                ? column.render(propertyValue, item)
                : <div>{propertyValue}</div>}
        </>
    );
};

export const GridContainer = ({ children }) => (
    <Grid
        gridColumnGap={{ base: 0, md: 4, lg: 6 }}
        gridRowGap={{ base: 4, md: 1, lg: 1 }}
        padding={{ base: 1, md: 2, lg: 3 }}
        templateColumns={{ base: 'repeat(6, minmax(0,1fr))', md: 'repeat(12, minmax(0,1fr))' }}
    >
        {children}
    </Grid>
);

export const GridItemContainer = ({ children, gridItemConfig }) => (
    <GridItem
        colEnd={gridItemConfig?.colEnd}
        colSpan={gridItemConfig?.colSpan ?? 6}
        colStart={gridItemConfig?.colStart}
        rowEnd={gridItemConfig?.rowEnd}
        rowSpan={gridItemConfig?.rowSpan}
        rowStart={gridItemConfig?.rowStart}
    >
        {children}
    </GridItem>
);


export default { GridContainer, GridItemContainer };
