import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { setClipboardContent } from 'store/application/actions';
import { applicationReducerName } from 'store/application/reducer';

import StandardTooltip from './StandardTooltip.layout';
import { StandardTooltipDispatchProps, StandardTooltipOwnProps, StandardTooltipStateProps } from './StandardTooltip.types';


const mapStateToProps = (state, ownProps):StandardTooltipStateProps =>
    ownProps.showCopyToClipboard
        ? { clipboardContent: state[applicationReducerName].clipboardContent }
        : {};
const mapDispatchToProps = (dispatch, ownProps) => <StandardTooltipDispatchProps>
    (ownProps.showCopyToClipboard ? { dispatchSetClipboardContent: (text) => dispatch(setClipboardContent(text)) } : {});


const decorate = compose<ComponentType<StandardTooltipOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(StandardTooltip);
