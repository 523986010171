import { ChakraProvider, createStandaloneToast, localStorageManager } from '@chakra-ui/react';
import { getDirectionFromLocale } from '@manigo/manigo-commons';
import React, { useMemo, JSX, useEffect } from 'react';

import { primaryColorSchemeKey, defaultLocale } from 'config/config';
import createTheme from 'config/theme';

import { GlobalStyles } from 'assets/styles/global/global.styled';

import { RtlProvider } from './RtlProvider';
import ApplicationContent from '../ApplicationContent';


function Main({ selectedLocale }): JSX.Element {
    const theme = useMemo(
        () => createTheme({ configuration: { colorScheme: primaryColorSchemeKey } }),
        [],
    );
    const lang = selectedLocale || defaultLocale;
    const dir = getDirectionFromLocale(lang);

    const { ToastContainer } = createStandaloneToast();

    useEffect(() => {
        document.documentElement.lang = lang;
        document.documentElement.dir = dir;
    }, [dir, lang]);
    return (
        <>
            <ChakraProvider
                theme={{ ...theme }}
                cssVarsRoot="#root"
                colorModeManager={localStorageManager}
            >
                <RtlProvider dir={dir}>
                    <ApplicationContent />
                    <ToastContainer />
                    <GlobalStyles />
                </RtlProvider>
            </ChakraProvider>
        </>
    );
}

export default Main;
