import {
    Card, CardBody, CardFooter, CardHeader, Divider, Heading,
} from '@chakra-ui/react';
import React, { JSX } from 'react';

import Styled from './StandardUnauthorisedPage.styled';
import { StandardUnauthorisedPageProps } from './StandardUnauthorisedPage.types';


function StandardUnauthorisedPage({
    children,
    pageTitle,
    footerContent,
    width = ['90%', '90%', '500px'],
}: StandardUnauthorisedPageProps): JSX.Element {
    return (
        <Styled.StandardAuthorisedPageContentWrapper width={width}>
            <Card>
                {pageTitle && (
                    <>
                        <CardHeader>
                            <Heading size="md">{pageTitle}</Heading>
                        </CardHeader>
                        <Divider />
                    </>
                )}

                <CardBody>
                    {children}
                </CardBody>

                {footerContent
                    ? (
                        <>
                            <Divider />

                            <CardFooter>
                                {footerContent}
                            </CardFooter>
                        </>
                    ) : <></>}
            </Card>
        </Styled.StandardAuthorisedPageContentWrapper>

    );
}

export default StandardUnauthorisedPage;
