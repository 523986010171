import styled from 'styled-components';


const TagContentWrapper = styled.span`
  display: block;
  max-width: 100%;
  white-space: nowrap;
`;

export default { TagContentWrapper };
