import { RefObject, useMemo, useRef } from 'react';


const useCreateDigitsCodeInputReferences = ():{
    refInputDigit1: RefObject<HTMLInputElement>,
    refInputDigit2: RefObject<HTMLInputElement>,
    refInputDigit3: RefObject<HTMLInputElement>,
    refInputDigit4: RefObject<HTMLInputElement>,
    refInputDigit5: RefObject<HTMLInputElement>,
    refInputDigit6: RefObject<HTMLInputElement>,
    refButtonSubmit: RefObject<HTMLButtonElement>,
} => {
    const refInputDigit1 = useRef(null);
    const refInputDigit2 = useRef(null);
    const refInputDigit3 = useRef(null);
    const refInputDigit4 = useRef(null);
    const refInputDigit5 = useRef(null);
    const refInputDigit6 = useRef(null);
    const refButtonSubmit = useRef(null);

    return useMemo(() => (
        {
            refInputDigit1,
            refInputDigit2,
            refInputDigit3,
            refInputDigit4,
            refInputDigit5,
            refInputDigit6,
            refButtonSubmit,
        }
    ), []);
};

export default useCreateDigitsCodeInputReferences;
