import { createAction } from '@reduxjs/toolkit';

import { Language } from 'models/app/i18n';

import {
    INIT_I18NEXT,
    INIT_I18NEXT_FAILURE,
    INIT_I18NEXT_SUCCESS,
    CHANGE_LANGUAGE,
    CHANGE_LANGUAGE_SUCCESS,
    CHANGE_LANGUAGE_FAILURE,
    LOAD_NAMESPACES,
    LOAD_NAMESPACES_SUCCESS,
    LOAD_NAMESPACES_FAILURE,
    LOAD_LANGUAGE,
    LOAD_LANGUAGE_SUCCESS,
    LOAD_LANGUAGE_FAILURE,
    GET_LOCALES_MANIFEST,
    GET_LOCALES_MANIFEST_SUCCESS,
} from './action.types';


export const getLocalesManifest = createAction(GET_LOCALES_MANIFEST);
export const getLocalesManifestSuccess = createAction(GET_LOCALES_MANIFEST_SUCCESS, (payload: Record<string, string>) => ({ payload }));

export const initI18next = createAction(INIT_I18NEXT);
export const initI18nextSuccess = createAction(INIT_I18NEXT_SUCCESS, (locale: Language) => ({ payload: locale }));
export const initI18nextFailure = createAction(INIT_I18NEXT_FAILURE);

export const changeLanguage = createAction(CHANGE_LANGUAGE, (locale: Language) => ({ payload: locale }));
export const changeLanguageSuccess = createAction(CHANGE_LANGUAGE_SUCCESS);
export const changeLanguageFailure = createAction(CHANGE_LANGUAGE_FAILURE);

export const loadLanguage = createAction(LOAD_LANGUAGE, (locale: Language) => ({ payload: locale }));
export const loadLanguageSuccess = createAction(LOAD_LANGUAGE_SUCCESS);
export const loadLanguageFailure = createAction(LOAD_LANGUAGE_FAILURE);

export const loadNamespaces = createAction(LOAD_NAMESPACES, (namespaces: string[]) => ({ payload: namespaces }));
export const loadNamespacesSuccess = createAction(LOAD_NAMESPACES_SUCCESS);
export const loadNamespacesFailure = createAction(LOAD_NAMESPACES_FAILURE);

