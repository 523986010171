import { Heading, Container } from '@chakra-ui/react';
import { ConsentScope } from '@manigo/manigo-domain-typings';
import React, { JSX } from 'react';

import Countdown from 'components/common/atoms/Countdown';
import AlertInfo from 'components/common/molecules/AlertInfo';

import Styled from './NewConsentHeading.styled';
import { NewConsentHeadingProps } from './NewConsentHeading.types';


function NewConsentHeading({
    t,
    consentRequestExpiry,
    consentScope,
    dispatchNewConsentHasExpired,
}: NewConsentHeadingProps): JSX.Element {
    return (
        <>
            <Heading textAlign="center" size="md" mb={'3rem'}>
                {consentScope === ConsentScope.accounts
                    ? t('consent:newConsentPage.newConsentDetails.introText')
                    : t('consent:newConsentPage.newConsentDetails.introTextPaymentScope')}
            </Heading>

            {consentRequestExpiry && (
                <Container>
                    <AlertInfo
                        m={'0 1rem 2rem 1rem'}
                        status={'warning'}
                        message={(
                            <Styled.CountdownWrapper>
                                {t('consent:newConsentPage.consentWillExpireWarning')}
                                <Countdown
                                    date={new Date(consentRequestExpiry)}
                                    onCountdownEnd={() => dispatchNewConsentHasExpired()}
                                />
                            </Styled.CountdownWrapper>
                        )}
                    />
                </Container>
            )}
        </>
    );
}

export default NewConsentHeading;
