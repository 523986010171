import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';


export const MenuItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: 6px;
  
  line-height: normal !important;

`;

export default { MenuItem };
