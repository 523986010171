export enum RedirectionToDefaultPathTypes {
    ROUTER_REDIRECTION_TO_DEFAULT_PATH = 'ROUTER_REDIRECTION_TO_DEFAULT_PATH',
    STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT = 'STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT',
    STANDARD_TABS_ACTIVE_SUB_TAB_REPLACEMENT = 'STANDARD_TABS_ACTIVE_SUB_TAB_REPLACEMENT',
}


export enum AnimationType {
    slideLeft = 'slideLeft',
    slideRight = 'slideRight',
}


export default {
    AnimationType,
    RedirectionToDefaultPathTypes,
};
