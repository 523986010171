import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';

import { developmentMode } from 'config/environment';

import {
    onInitApplication,
    onInitApplicationUnauthorisedFlow,
    onInitApplicationAuthorisedFlow,
    onGetJwtTokenFormStorage,
    onRemoveJwtTokenFromStorage,
    onSetClipboardContent,
    onShowToast,
    onApplicationReadyUnauthorisedUser,
} from 'store/application/epics';
import {
    onInitialiseLogin,
    onConfirmLogin,
    onGetTokensForDuplicatedTab,
    onRefreshToken,
    onConfirmLoginFailure,
} from 'store/authorisation/epics';
import { onFetchConsentDetails, onRevokeConsent } from 'store/consent/epics';
import { onFetchConsentsList } from 'store/consents/epics';
import { onfetchCountries } from 'store/countries/epics';
import { onFetchCurrencies } from 'store/currencies/epics';
import {
    onSetCurrentUserSuccess,
    onApplicationReadyAuthorisedUser,
    onSetCurrentUserFailure,
    onSetCurrentUser,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity, onDocumentVisibilityChange, onCheckTokens,
} from 'store/current-user/epic';
import { onChangeLanguage, onGetLocalesManifest, onInitI18next, onLoadLanguage, onLoadNamespaces } from 'store/locales/epics';
import {
    onNavigationRequested,
    onPreformNavigation,
    onNavigationBackRequested, onPreformNavigationBack,
    onBlockNavigation,
    onUnblockNavigation, onLocationChanged,
} from 'store/navigation/epic';
import {
    onChangeConsentStatus,
    onClearNewConsentMode,
    onNewConsentHasExpired,
    onNewConsentNotFound,
    onSetNewConsentMode,
    onUnauthorisedDeclineConsentRequest,
} from 'store/new-consent/epics';


const applicationEpics = [
    onInitApplication,
    onInitApplicationUnauthorisedFlow, onInitApplicationAuthorisedFlow,
    onApplicationReadyUnauthorisedUser,
    onGetJwtTokenFormStorage, onRemoveJwtTokenFromStorage,
    onSetClipboardContent,
    onShowToast,
];

const authorisation = [
    onInitialiseLogin,
    onConfirmLogin,
    onConfirmLoginFailure,
    onGetTokensForDuplicatedTab,
    onRefreshToken,
];

const countriesEpics = [
    onfetchCountries,
];

const currenciesEpics = [
    onFetchCurrencies,
];

const consentEpics = [
    onFetchConsentDetails,
    onChangeConsentStatus,
    onRevokeConsent,
];

const consentsEpics = [
    onFetchConsentsList,
];

const currentUserEpics = [
    onSetCurrentUser,
    onSetCurrentUserSuccess,
    onApplicationReadyAuthorisedUser,
    onSetCurrentUserFailure,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity,
    onCheckTokens,
    onDocumentVisibilityChange,
];

const localesEpics = [
    onGetLocalesManifest,
    onInitI18next,
    onLoadNamespaces,
    onLoadLanguage,
    onChangeLanguage,
];

const newConsent = [
    onSetNewConsentMode,
    onClearNewConsentMode,
    onNewConsentHasExpired,
    onNewConsentNotFound,
    onUnauthorisedDeclineConsentRequest,
];

const navigationEpics = [
    onNavigationRequested,
    onPreformNavigation,
    onNavigationBackRequested,
    onPreformNavigationBack,
    onLocationChanged,
    onBlockNavigation,
    onUnblockNavigation,
];


const epics = [
    ...applicationEpics,
    ...authorisation,
    ...countriesEpics,
    ...currenciesEpics,
    ...consentEpics,
    ...consentsEpics,
    ...currentUserEpics,
    ...localesEpics,
    ...newConsent,
    ...navigationEpics,
];


export const areEpicsDoubled = Array.from(new Set(epics)).length !== epics.length;

if (areEpicsDoubled) {
    throw Error(`Doubled epics! ${Array.from(new Set(epics)).length} vs ${epics.length}`);
}

const onAnyEpic = combineEpics(...epics);

function errorHandler(error, source) {
    if (developmentMode) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return source;
}

const onEpic = (action$, store$, dependencies) => onAnyEpic(action$, store$, dependencies).pipe(catchError(errorHandler));


export default onEpic;
