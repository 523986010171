import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

import SetPasswordPage from './TermsOfService.layout';


const decorate = compose<ComponentType>(
    withTranslation('termsOfService'),
);

export default decorate(SetPasswordPage);
