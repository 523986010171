import {
    FormLabel,
    HStack,
    Input,
    PinInput,
    PinInputField,
    Radio,
    RadioGroup,
    Checkbox,
    Stack,
    Switch,
    InputLeftElement,
    InputRightElement,
} from '@chakra-ui/react';
import React, { JSX } from 'react';

import { CommonFormFieldConfigItem, FormInputTypes } from 'models/app/formContols';

import InfoIconTooltip from 'components/common/molecules/InfoIconTooltip';
import StandardSelect from 'components/common/molecules/StandardSelect';
import LazyAutocomplete from 'components/common/organisms/LazyAutocomplete';

import StandardAmountInput from '../StandardAmountInput';
import StandardNumberInput from '../StandardNumberInput';
import StandardPhoneNumberInput from '../StandardPhoneNumberInput';


export interface RenderFormInputTypeProps {
    fieldConfiguration: CommonFormFieldConfigItem,
    field: any,
    formState: any,
    fieldState: any,
    error: any,
}

export const renderFormInputType = ({
    field,
    fieldConfiguration,
    error,
}: RenderFormInputTypeProps): JSX.Element => {


    const { name } = fieldConfiguration;
    const onChangeHandler = (value: any) => {
        if (fieldConfiguration.onChange) fieldConfiguration.onChange(value);
        field.onChange(value);

    };

    const commonSpecialInputProps = {
        ...fieldConfiguration,
        value: field.value,
        onChange: onChangeHandler,
        onBlur: field.onBlur,
        hasError: error,
    };

    const commonStandardInputProps = {
        type: fieldConfiguration.fieldType,
        name: fieldConfiguration.name,
        id: fieldConfiguration.id,
        value: field.value,
        placeholder: fieldConfiguration.placeholderText,
        'data-test-id': fieldConfiguration.dataTestId,
        size: fieldConfiguration?.optionalConfiguration?.size,
        onChange: ((event) => onChangeHandler(event.target.value)),
        onFocus: fieldConfiguration.onFocus,
        onKeyDown: fieldConfiguration.onKeyDown,
        onBlur: field.onBlur,
        hasError: error,
    };

    const handlers = [
        // XXX an escape hatch... render any children as input
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.CUSTOM,
            handler: () => fieldConfiguration.children as JSX.Element,
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.TEXT
                || fieldConfiguration.fieldType === FormInputTypes.PASSWORD
                || fieldConfiguration.fieldType === FormInputTypes.EMAIL
                || fieldConfiguration.fieldType === FormInputTypes.COLOR,
            handler: () => (
                <Input
                    // XXX very important to disable autoComplete for honeypot fields, notice 'new-password' instead 'off'
                    autoComplete={fieldConfiguration.optionalConfiguration?.isHoneypot ? 'new-password' : undefined}
                    {...commonStandardInputProps}
                />
            ),
        },

        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.IBAN,
            handler: () => {
                return (
                    <Input {...commonStandardInputProps} />
                );
            },
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.INTEGER,
            handler: () => (
                <StandardNumberInput fieldConfiguration={{
                    ...commonSpecialInputProps,
                    optionalConfiguration: {
                        ...commonSpecialInputProps.optionalConfiguration,
                        precision: 0,
                    },
                }}
                />
            ),
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.DECIMAL || fieldConfiguration.fieldType === FormInputTypes.AMOUNT,
            handler: () => (
                <StandardAmountInput fieldConfiguration={{
                    ...commonSpecialInputProps,
                    optionalConfiguration: {
                        ...commonSpecialInputProps.optionalConfiguration,
                        precision: commonSpecialInputProps.optionalConfiguration?.precision || 2,
                    },
                }}
                />
            ),
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.PHONE_NUMBER,
            handler: () => (
                <StandardPhoneNumberInput fieldConfiguration={commonSpecialInputProps} />
            ),
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.RADIO,
            handler: () => (
                <RadioGroup
                    onChange={(onChangeHandler)}
                    value={field.value}
                >
                    <Stack direction="row">
                        {
                            fieldConfiguration.optionalConfiguration?.options?.map((option) => (
                                <Radio
                                    key={option.value}
                                    value={typeof option.value === 'string' ? option.value : JSON.stringify(option.value)}
                                    data-test-id={`${fieldConfiguration.dataTestId}-${option.value}`}
                                >
                                    {option.label}
                                </Radio>

                            ))
                        }
                    </Stack>
                </RadioGroup>
            ),

        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.CHECK_BOX,
            handler: () => (
                <HStack alignItems={'center'} justifyContent={'space-between'}>
                    <Checkbox
                        size={'lg'}
                        isChecked={field.value}
                        onChange={(event) => onChangeHandler(event.target.checked)}
                    >
                        {fieldConfiguration.optionalConfiguration?.checkBoxLabel}
                    </Checkbox>

                    {fieldConfiguration.optionalConfiguration?.checkBoxNonLabelContent}
                </HStack>
            ),

        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.SWITCH,
            handler: () => {
                return (
                    <HStack alignItems={'center'}>
                        <Switch
                            name={name}
                            id={fieldConfiguration.id}
                            isChecked={!!field.value}
                            onChange={(event) => {
                                onChangeHandler(event.target.checked);
                            }}
                            value={field.value}
                            size="lg"
                            data-test-id={fieldConfiguration.dataTestId}
                            role={'switch'}
                        />
                        {fieldConfiguration.blockLabel
                            ? (
                                <FormLabel
                                    htmlFor={fieldConfiguration.id}
                                    display={'flex'}
                                    whiteSpace={'nowrap'}
                                    alignItems={'center'}
                                    gap={1}
                                >
                                    {fieldConfiguration.blockLabel}

                                    {fieldConfiguration?.infoTooltipText
                                        ? (<InfoIconTooltip infoTooltipText={fieldConfiguration?.infoTooltipText} />)
                                        : (<></>)}
                                </FormLabel>
                            ) : (<></>)}
                    </HStack>
                );
            },
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.SELECT_SINGLE_VALUE,
            handler: () => {
                return (
                    <StandardSelect
                        useOptionValueOnly={fieldConfiguration?.optionalConfiguration?.useOptionValueOnly}
                        fieldConfiguration={commonSpecialInputProps}
                        style={{ width: '100%' }}
                    />
                );
            },
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.SELECT_MULTI_VALUES,
            handler: () => {
                return (
                    <StandardSelect
                        useOptionValueOnly={fieldConfiguration?.optionalConfiguration?.useOptionValueOnly}
                        fieldConfiguration={commonSpecialInputProps}
                        style={{ width: '100%' }}
                    />
                );
            },
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.LAZY_AUTOCOMPLETE_SINGLE_VALUE,
            handler: () => {
                return (
                    <LazyAutocomplete
                        fieldConfiguration={commonSpecialInputProps}
                    />
                );
            },
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.PIN,
            handler: () => (
                <div style={{ display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                    <HStack>
                        <PinInput
                            value={field.value}
                            onChange={(pinInputValue) => field.onChange(pinInputValue)}
                            onComplete={(pinInputValue) => field.onChange(pinInputValue)}
                            otp
                            autoFocus
                            size={{ base: 'md', md: 'lg' }}
                            placeholder={'\u{25C8}'}
                        >

                            {
                                Array.from({ length: 6 }).fill(0).map((value, index) => {
                                    return (
                                        <PinInputField
                                            data-test-id={`${fieldConfiguration.dataTestId}-${index}`}
                                            key={index}
                                        />
                                    );
                                })
                            }
                        </PinInput>
                    </HStack>
                </div>
            ),
        },
        {
            predicate: () => true,
            handler: () => <div />,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export const renderFormLabel = (fieldConfiguration: CommonFormFieldConfigItem) => {
    return fieldConfiguration?.labelText && !fieldConfiguration.blockLabel ? (
        <FormLabel htmlFor={fieldConfiguration.id} display={'flex'} whiteSpace={'nowrap'} alignItems={'center'} gap={1}>
            {fieldConfiguration.labelText}


            {fieldConfiguration?.infoTooltipText
                ? (<InfoIconTooltip infoTooltipText={fieldConfiguration?.infoTooltipText} />)
                : (<></>)}
        </FormLabel>
    ) : (<></>);
};

export const renderInputLeftElement = ({ fieldConfiguration, reverseIcons }: {
    fieldConfiguration: CommonFormFieldConfigItem,
    reverseIcons: boolean
}) => {
    return (fieldConfiguration?.inputLeftElement && !reverseIcons)
    || (fieldConfiguration?.inputRightElement && reverseIcons)
        ? (
            <InputLeftElement pointerEvents="none">
                {fieldConfiguration?.inputLeftElement && !reverseIcons ? fieldConfiguration.inputLeftElement : undefined}
                {fieldConfiguration?.inputRightElement && reverseIcons ? fieldConfiguration.inputRightElement : undefined}
            </InputLeftElement>
        ) : null;

};

export const renderInputRightElement = ({ fieldConfiguration, reverseIcons }: {
    fieldConfiguration: CommonFormFieldConfigItem,
    reverseIcons: boolean
}) => {
    return (fieldConfiguration?.inputLeftElement && !reverseIcons)
    || (fieldConfiguration?.inputRightElement && reverseIcons)
        ? (
            <InputRightElement pointerEvents="none">
                {fieldConfiguration?.inputLeftElement && reverseIcons ? fieldConfiguration.inputLeftElement : undefined}
                {fieldConfiguration?.inputRightElement && !reverseIcons ? fieldConfiguration.inputRightElement : undefined}
            </InputRightElement>
        ) : null;

};

export default { renderFormInputType, renderFormLabel, renderInputLeftElement, renderInputRightElement };

