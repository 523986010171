import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { loadLanguage } from 'store/locales/actions';
import { localesReducerName } from 'store/locales/reducer';
import { RootState } from 'store/rootReducer';

import LanguageSelector from './LanguageSelector.layout';
import { LanguageSelectorDispatchProps, LanguageSelectorStateProps } from './LanguageSelector.types';


const mapStateToProps = (state: RootState): LanguageSelectorStateProps => ({ selectedLocale: state[localesReducerName].selectedLocale });

const mapDispatchToProps = (dispatch: Dispatch): LanguageSelectorDispatchProps => ({
    dispatchLoadLanguage: (locale: string) => dispatch(loadLanguage(locale)),
    //
});

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(LanguageSelector);
