import { Tag, TagLabel, TagLeftIcon, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import { primaryColorSchemeKey } from 'config/config';

import Styled from './StandardTag.styled';
import { StandardTagProps } from './StandardTag.types';
import StandardTooltip from '../StandardTooltip';


function StandardTag({
    t,
    value,
    size = { base: 'sm', md: 'md', lg: 'md' },
    color,
    showTooltip,
    leftIcon,
    variant,
    dataTestId,
    dataTestValue,
}: StandardTagProps) {
    const colorScheme = useColorModeValue(`${primaryColorSchemeKey}.500`, `${primaryColorSchemeKey}.200`);

    const tag = (
        <Tag
            variant={variant}
            size={size}
            colorScheme={color || colorScheme}
            data-test-id={dataTestId}
            data-value={dataTestValue}
        >
            <TagLabel style={{
                display: 'grid',
                width: 'max-content',
                gridTemplateColumns: 'auto max-content',
                alignItems: 'center',
            }}
            >
                {leftIcon ? <TagLeftIcon boxSize="12px" as={leftIcon} /> : null}
                <Styled.TagContentWrapper>
                    {t(value)}
                </Styled.TagContentWrapper>
            </TagLabel>
        </Tag>
    );

    return showTooltip
        ? (
            <StandardTooltip title={value} showCopyToClipboard>
                {tag}
            </StandardTooltip>
        )
        : tag;
}


export default StandardTag;

