import { createReducer } from '@reduxjs/toolkit';

import { ApplicationState } from 'models/app/applicationState';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    APPLICATION_READY_AUTHORISED_USER, APPLICATION_READY_AUTHORISED_USER_NEW_CONSENT, APPLICATION_READY_UNAUTHORISED_USER,
    HIDE_AUTO_LOGOUT_BANNER, SHOW_AUTO_LOGOUT_BANNER,
    SET_CLIPBOARD_CONTENT,
    SET_SESSION_UUID,
} from './action.types';


export interface ApplicationStateType {
    readonly sessionUuid?: string;
    readonly applicationState: ApplicationState;
    readonly isAutoLogoutBannerVisible: boolean;

    readonly clipboardContent: string;
}

const initialState: ApplicationStateType = {
    sessionUuid: undefined,
    applicationState: ApplicationState.APPLICATION_STARTED,
    isAutoLogoutBannerVisible: false,
    clipboardContent: '',
};

export const applicationReducerName = 'application';

const applicationBuilderCases = (builder) =>
    builder
        .addCase(APPLICATION_READY_UNAUTHORISED_USER, (state) => {
            state.applicationState = ApplicationState.UNAUTHORISED_READY;
        })
        .addCase(APPLICATION_READY_AUTHORISED_USER_NEW_CONSENT, (state) => {
            state.applicationState = ApplicationState.AUTHORISED_NEW_CONSENT;
        })
        .addCase(APPLICATION_READY_AUTHORISED_USER, (state) => {
            state.applicationState = ApplicationState.AUTHORISED_READY;
        })
        .addCase(SET_SESSION_UUID, (state, action) => {
            state.sessionUuid = action.payload;
        })
        .addCase(SHOW_AUTO_LOGOUT_BANNER, (state) => {
            state.isAutoLogoutBannerVisible = true;
        })
        .addCase(HIDE_AUTO_LOGOUT_BANNER, (state) => {
            state.isAutoLogoutBannerVisible = false;
        })
        .addCase(SET_CLIPBOARD_CONTENT, (state, action) => {
            state.clipboardContent = action.payload;
        })
        .addCase(CLEAR_CURRENT_USER, (state) => {
            state.isAutoLogoutBannerVisible = false;
            state.clipboardContent = '';
            state.applicationState = ApplicationState.UNAUTHORISED_READY;
        });
export default createReducer(initialState, applicationBuilderCases);
