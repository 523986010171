import styled, { css } from 'styled-components';


const AppBlurOverlay = styled.div<{ $activeBlur?: boolean }>`
  transition: filter 200ms ease;

  ${({ $activeBlur = false }) => $activeBlur && css`
    filter: blur(10px);
    pointer-events: none;
    user-select: none;
    z-index: 100;
  `};
`;

export default { AppBlurOverlay };
