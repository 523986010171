import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { showAutoLogoutBanner } from 'store/application/actions';
import { applicationReducerName } from 'store/application/reducer';
import { userActivity } from 'store/current-user/actions';
import { RootState } from 'store/rootReducer';

import AppIdleTimer from './AppIdleTimer.layout';
import { AppIdleTimerDispatchProps, AppIdleTimerOwnProps } from './AppIdleTimer.types';


const mapStateToProps = (state: RootState) => ({ isAutoLogoutBannerVisible: state[applicationReducerName].isAutoLogoutBannerVisible });
const mapDispatchToProp = (dispatch) => <AppIdleTimerDispatchProps> ({
    dispatchShowAutoLogoutBanner: () => dispatch(showAutoLogoutBanner()),
    dispatchUserActivity: () => dispatch(userActivity()),
});

const decorate = compose<ComponentType<AppIdleTimerOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProp),
);
export default decorate(AppIdleTimer);
