import { removeSessionStorageObjectItem, setSessionStorageObjectItem } from '@manigo/manigo-commons';
import { ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { Epic } from 'models/meta/epic';

import { newConsentKey } from 'config/config';
import { consentHasExpiredRootRoutePath, consentNotFoundRootRoutePath } from 'config/routes';

import { clearCurrentUser } from 'store/current-user/actions';
import { requestNavigation } from 'store/navigation/actions';

import { changeConsentStatusFailure, changeConsentStatusSuccess, clearNewConsentMode } from './actions';
import {
    SET_NEW_CONSENTS_MODE,
    CLEAR_NEW_CONSENTS_MODE,
    CHANGE_CONSENT_STATUS,
    NEW_CONSENT_HAS_EXPIRED,
    NEW_CONSENT_NOT_FOUND,
    UNAUTHORISED_DECLINE_CONSENT_REQUEST,
} from './actions.types';


export const onSetNewConsentMode: Epic = (action$) => action$.pipe(
    ofType(SET_NEW_CONSENTS_MODE),
    tap(({ payload }) => {
        setSessionStorageObjectItem(newConsentKey, payload);
    }),
    switchMap(() => EMPTY),
);

export const onClearNewConsentMode: Epic = (action$) => action$.pipe(
    ofType(CLEAR_NEW_CONSENTS_MODE),
    tap(() => {
        removeSessionStorageObjectItem(newConsentKey);
    }),
    switchMap(() => EMPTY),
);

export const onNewConsentHasExpired: Epic = (action$) => action$.pipe(
    ofType(NEW_CONSENT_HAS_EXPIRED),
    switchMap(() => of(
        clearCurrentUser(),
        requestNavigation({ locationPathname: consentHasExpiredRootRoutePath, meta: { replace: true } }),
    )),
);

export const onNewConsentNotFound: Epic = (action$) => action$.pipe(
    ofType(NEW_CONSENT_NOT_FOUND),
    switchMap(() => of(
        clearCurrentUser(),
        requestNavigation({ locationPathname: consentNotFoundRootRoutePath, meta: { replace: true } }),
    )),
);

export const onChangeConsentStatus: Epic = (action$, state$, { consents }) => action$.pipe(
    ofType(CHANGE_CONSENT_STATUS),
    switchMap(({ payload }) => from(consents.changeConsentStatus(payload))
        .pipe(
            switchMap((response) => of(changeConsentStatusSuccess(response?.data?.location))),
            catchError(() => of(changeConsentStatusFailure())),
        )),
);

export const onUnauthorisedDeclineConsentRequest: Epic = (action$, state$, { consents }) => action$.pipe(
    ofType(UNAUTHORISED_DECLINE_CONSENT_REQUEST),
    switchMap(({ payload }) => from(consents.declineConsentRequest(payload))
        .pipe(
            switchMap((response) => of(
                clearNewConsentMode(),
                requestNavigation({ locationPathname: `${response.data?.location}` }),
            )),
            catchError(() => {
                const genericCallbackURL = state$.value.newConsent?.newConsentCallbackUrl;
                return of(
                    clearNewConsentMode(),
                    requestNavigation({ locationPathname: `${genericCallbackURL}` }),
                );
            }),
        )),
);

export default [
    onSetNewConsentMode,
    onClearNewConsentMode,
    onChangeConsentStatus,
    onNewConsentHasExpired,
    onNewConsentNotFound,
    onUnauthorisedDeclineConsentRequest,
];

