import {
    BreadcrumbItem, BreadcrumbLink, Flex, Grid, GridItem, Heading, useColorModeValue,
} from '@chakra-ui/react';
import React, { JSX, useEffect, useMemo } from 'react';
import { MdArrowForwardIos } from 'react-icons/md';

import { WL_CONFIG } from 'config/environment';

import {
    getChakraVarColorByChakraHookReturn,
    getDefaultLightDarkValues,
} from 'utils/theme-tools';

import AppVersionInfo from 'components/common/atoms/AppVersionInfo';
import CopyrightNotice from 'components/common/atoms/CopyrightNotice';
import TheIcon from 'components/common/atoms/TheIcon';

import AdditionalButtons from './AdditionalButtons';
import Styled from './StandardAuthorisedPage.styled';
import { StandardAuthorisedPageProps } from './StandardAuthorisedPage.types';


function StandardAuthorisedPage({
    children,
    tabsConfiguration,
    pageTitle,
    breadCrumbConfig,
    footerContent,
    additionalButtons,
    dispatchRequestNavigation,
    t,
}: StandardAuthorisedPageProps): JSX.Element {
    const colorScheme = useColorModeValue(...getDefaultLightDarkValues());

    const handleBreadCrumbLink = (to) => {
        if (to) {
            dispatchRequestNavigation({
                locationPathname: to,
                meta: { replace: true },
            });
        }
    };

    const breadCrumbItems = useMemo(() => breadCrumbConfig && [
        { title: breadCrumbConfig.parentRouteLabel, to: breadCrumbConfig?.parentRoute },
        { title: breadCrumbConfig?.entityRouteLabel, isCurrentPage: true },
        ...(breadCrumbConfig?.activeTabLabel ? [{ title: breadCrumbConfig?.activeTabLabel, isCurrentPage: true }] : []),
    ], [breadCrumbConfig]);

    useEffect(() => {
        const handlers = [{
            predicate: () => pageTitle,
            handler: () => pageTitle,
        },
        {
            predicate: () => breadCrumbConfig,
            handler: () => `${breadCrumbConfig?.entityRouteLabel} ${breadCrumbConfig?.activeTabLabel}`,
        },
        {
            predicate: () => true,
            handler: () => '',
        },
        ];
        const title = handlers.find(({ predicate }) => predicate())?.handler();
        if (title) {
            document.title = `${WL_CONFIG.brandShortName} ${WL_CONFIG.applicationName} - ${title}`;
        }
    }, [breadCrumbConfig]);

    return (
        <Styled.StandardAuthorisedPageWrapper className="app-height">
            <Styled.StandardAuthorisedPageInnerWrapper className="app-height">
                <Grid
                    height="100%"
                    templateAreas={`
                        "header"
                        "main"
                        "footer"`}
                    gridTemplateRows={'minmax(0,auto) minmax(0,1fr) minmax(0, auto)'}
                    gridTemplateColumns={'minmax(0,1fr)'}
                >
                    <GridItem
                        area="header"
                        key="header"
                        pb={'10px'}
                    >
                        <Flex
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            flexWrap={'wrap'}
                            gap={4}
                        >
                            <GridItem key="pageTitle">
                                {
                                    breadCrumbItems ? (
                                        <Styled.BreadCrumbContainer
                                            separator={<TheIcon Icon={MdArrowForwardIos} boxSize={'2.5'} />}
                                        >
                                            {breadCrumbItems?.map((value) => (
                                                <BreadcrumbItem key={value.title} isCurrentPage={value?.isCurrentPage}>
                                                    <BreadcrumbLink
                                                        onClick={() => handleBreadCrumbLink(value.to)}
                                                        color={!value.isCurrentPage ? getChakraVarColorByChakraHookReturn(colorScheme) : 'var(--chakra-colors-chakra-body-text)'}
                                                    >
                                                        {value.title}
                                                    </BreadcrumbLink>
                                                </BreadcrumbItem>
                                            ))}
                                        </Styled.BreadCrumbContainer>
                                    ) : (
                                        <Heading
                                            noOfLines={1}
                                            as="h2"
                                            size="lg"
                                            pb={3}
                                        >
                                            {pageTitle}
                                        </Heading>
                                    )
                                }
                            </GridItem>

                            <AdditionalButtons additionalButtons={additionalButtons} />
                        </Flex>
                    </GridItem>

                    <GridItem
                        key="main"
                        padding={0}
                        area="main"
                        overflow="hidden"
                    >
                        {children || <></>}
                        {tabsConfiguration ? tabsConfiguration.map((tabConfigItem) => tabConfigItem) : <></>}

                        {!children && !tabsConfiguration ? (<div />) : <></>}
                    </GridItem>


                    <GridItem
                        key="footer"
                        area="footer"
                        padding="10px"
                        position={'relative'}
                    >
                        <Grid
                            gridTemplateColumns={'minmax(0,1fr) auto'}
                            gridColumnGap={'2rem'}
                        >
                            <GridItem key="copyrightNotice">
                                {footerContent || <CopyrightNotice />}
                            </GridItem>

                            <GridItem key="AppVersionInfo">
                                <AppVersionInfo
                                    t={t}
                                    width={'min-content'}
                                    padding={0}
                                    margin={0}
                                />
                            </GridItem>

                        </Grid>
                    </GridItem>
                </Grid>
            </Styled.StandardAuthorisedPageInnerWrapper>
        </Styled.StandardAuthorisedPageWrapper>

    );
}

export default StandardAuthorisedPage;
