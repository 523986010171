import { Button, Heading, useColorModeValue } from '@chakra-ui/react';
import { ConsentStatus } from '@manigo/manigo-domain-typings';
import React, { JSX } from 'react';

import { secondaryColorSchemeKey } from 'config/config';

import { getChakraVarColorByChakraHookReturn, getDefaultLightDarkValues } from 'utils/theme-tools';

import AnimatedCheckMark from 'components/common/atoms/AnimatedCheckMark';
import AnimatedCrossMark from 'components/common/atoms/AnimatedCrossMark';
import Countdown from 'components/common/atoms/Countdown';
import StandardTranslationWithMarkup from 'components/common/atoms/StandardTranslationWithMarkup';

import Styled from './SuccessWithRedirection.styled';
import { SuccessWithRedirectionProps } from './SuccessWithRedirection.types';


function SuccessWithRedirection({
    t,
    entityDetailsData,
    newConsentStatus,
    redirectionUrl,
    dispatchRequestNavigation,
    dispatchClearCurrentUser,
}: SuccessWithRedirectionProps): JSX.Element {
    const now = new Date();
    const future = new Date(now.getTime() + 15 * 1000);
    const secondaryColor = getChakraVarColorByChakraHookReturn(useColorModeValue(...getDefaultLightDarkValues(secondaryColorSchemeKey)));

    const redirect = () => {
        dispatchClearCurrentUser();

        setTimeout(() => {
            // delay outgoing redirection
            dispatchRequestNavigation({ locationPathname: `${redirectionUrl}` });
        });
    };

    const tppName = entityDetailsData?.entityDetails?.tppName;
    const resultTranslationKey = newConsentStatus === ConsentStatus.Authorised ? 'approvedText' : 'rejectedText';

    return (
        <Styled.SuccessStepWrapper>
            {newConsentStatus === ConsentStatus.Authorised
                ? <AnimatedCheckMark />
                : <AnimatedCrossMark />}

            <Styled.SuccessStepCopyWrapper $secondaryColor={secondaryColor}>
                <>
                    <Heading size="md" mb={'2rem'}>
                        <StandardTranslationWithMarkup
                            translationKey={`consent:newConsentPage.successPage.${resultTranslationKey}`}
                            namespace="consent"
                            values={{ tppName }}
                            t={t}
                        />
                    </Heading>
                    <Heading size="sm" fontWeight="500">{t('consent:newConsentPage.successPage.redirectInfoText', { tppName })}</Heading>

                    <Countdown
                        date={future}
                        style={{
                            justifyContent: 'center',
                            gridTemplateColumns: 'repeat(2, auto)',
                        }}
                        onCountdownEnd={redirect}
                    />

                    <Button onClick={redirect} mt={'2rem'}>{t('consent:newConsentPage.successPage.redirectButtonText')}</Button>
                </>
            </Styled.SuccessStepCopyWrapper>
        </Styled.SuccessStepWrapper>
    );
}

export default SuccessWithRedirection;
