import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { getNavbarInnerWrapperCss } from 'components/App/layouts/layouts.helpers';


const NavbarWrapper = styled(Box)`
    position: sticky;
    top: 0;
`;
const NavbarInnerWrapper = styled(Box)`
  ${getNavbarInnerWrapperCss()};
`;
export default {
    NavbarWrapper,
    NavbarInnerWrapper,
};
