import { Box, FormLabel, HStack, Stack, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import React, { JSX, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { MdDoNotDisturb, MdSend } from 'react-icons/md';


import { LoginType } from 'models/domain/authorisation';

import Button from 'components/common/atoms/Button';
import Countdown from 'components/common/atoms/Countdown';
import Description from 'components/common/atoms/Description';
import SpinnerOverlay from 'components/common/atoms/SpinnerOverlay';
import StandardLink from 'components/common/atoms/StandardLink';
import TheIcon from 'components/common/atoms/TheIcon';
import AlertInfo from 'components/common/molecules/AlertInfo';
import FormFieldWrapper from 'components/common/molecules/FormFieldWrapper';
import InfoIconTooltip from 'components/common/molecules/InfoIconTooltip';
import StandardUnauthorisedPage from 'components/common/templates/StandardUnauthorisedPage';

import {
    createLoginFormLoginTypeConfiguration,
    createLoginFormDynamicInputsConfiguration,
    createLoginFormPasswordRememberMeConfiguration,
    createLoginFormValidationSchema,
} from './LoginPage.helpers';
import Styled from './LoginPage.styled';
import { LoginFormFields, LoginPageProps } from './LoginPage.types';


function LoginPage({
    t,

    loginFormErrorMessage,
    loginBlockUntil,
    loginPermanentBlock,

    isLoadingLoginForm,
    submittedLoginField,

    dispatchInitialiseLogin,
    dispatchClearLoginState,

    newConsentUuid,
    isLoadingUnauthorisedDeclineConsentRequest,
    dispatchUnauthorisedDeclineConsentRequest,
}: LoginPageProps): JSX.Element {
    const isBlocked = useMemo(() => !!(loginPermanentBlock || loginBlockUntil), [loginPermanentBlock, loginBlockUntil]);

    const onSubmit = (data: LoginFormFields) => {
        if (loginBlockUntil && (submittedLoginField === data.login)) return;

        dispatchInitialiseLogin(data);
    };
    const loginTypeConfiguration = useMemo(
        () => createLoginFormLoginTypeConfiguration({ t }),
        [t, isBlocked],
    );
    const dynamicInputsConfiguration = useMemo(
        () => createLoginFormDynamicInputsConfiguration({ t }),
        [t],
    );
    const passwordRememberMeConfiguration = useMemo(
        () => createLoginFormPasswordRememberMeConfiguration({ t }),
        [t],
    );

    const {
        handleSubmit,
        reset,
        control,
        watch,
        clearErrors,
        formState: { errors },
    } = useForm<LoginFormFields>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(createLoginFormValidationSchema({ t })),
        defaultValues: {
            loginType: LoginType.EMAIL,
            login: '',
            password: '',
        },
    });

    const loginType = watch('loginType');

    useEffect(() => {
        clearErrors('login');
        dispatchClearLoginState();
        reset({
            loginType,
            login: '',
            password: '',
        });
    }, [loginType]);

    return (
        <StandardUnauthorisedPage
            pageTitle={t('unauthorised:screenTitles.login')}
            footerContent={newConsentUuid ? (
                <HStack align={'flex-start'}>
                    <StandardLink
                        leftIcon={<TheIcon Icon={MdDoNotDisturb} />}
                        title={t('unauthorised:formsElements.declineConsentRequestAndGoBackToTpp.text')}
                        dataTestId="decline-consent-request-and-go-back-to-tpp"
                        onClick={() => dispatchUnauthorisedDeclineConsentRequest({ consentUuid: newConsentUuid })}
                    />
                    <InfoIconTooltip infoTooltipText={t('unauthorised:formsElements.declineConsentRequestAndGoBackToTpp.infoTooltipText')} />
                </HStack>
            ) : undefined}
        >
            <SpinnerOverlay isLoading={isLoadingUnauthorisedDeclineConsentRequest}>
                <Stack gap={4}>
                    <div>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            id={'passcode-confirmation-form'}
                            noValidate
                        >
                            <VStack
                                spacing={5}
                                align="stretch"
                            >
                                <Box>
                                    <FormLabel
                                        display={'flex'}
                                        whiteSpace={'nowrap'}
                                        alignItems={'center'}
                                        gap={1}
                                        key={loginTypeConfiguration.id}
                                    >
                                        <FormFieldWrapper
                                            errors={errors}
                                            control={control as any}
                                            fieldConfiguration={loginTypeConfiguration}
                                        />
                                    </FormLabel>
                                </Box>

                                <Styled.AnimatedInputContainer>
                                    <motion.div
                                        key={loginType}
                                        initial={{
                                            opacity: 0,
                                            transform: 'translateX(20px)',
                                        }}
                                        animate={{
                                            opacity: 1,
                                            transform: 'translateY(0px) translateX(0)',
                                            transition: { duration: 0.3 },
                                        }}
                                        exit={{
                                            opacity: 0,
                                            transform: 'translateX(20px)',
                                        }}
                                    >
                                        {<FormFieldWrapper
                                            errors={errors}
                                            control={control as any}
                                            fieldConfiguration={loginType === LoginType.EMAIL ? dynamicInputsConfiguration[0] : dynamicInputsConfiguration[1]}
                                        />}
                                    </motion.div>
                                </Styled.AnimatedInputContainer>

                                {
                                    passwordRememberMeConfiguration.map((fieldConfiguration) => (
                                        <FormLabel display={'flex'} whiteSpace={'nowrap'} alignItems={'center'} gap={1} key={fieldConfiguration.id}>
                                            <FormFieldWrapper
                                                errors={errors}
                                                control={control as any}
                                                fieldConfiguration={fieldConfiguration}
                                            />
                                        </FormLabel>
                                    ))
                                }

                                <HStack justify={'flex-end'}>
                                    <Button
                                        icon={MdSend}
                                        type={'submit'}
                                        id={'login-submit-button'}
                                        dataTestId={'login-submit-button'}
                                        label={t('unauthorised:formsElements.login.text')}
                                        isDisabled={isBlocked}
                                        isLoading={isLoadingLoginForm}
                                        loadingText={t('unauthorised:formsElements.login.loadingText')}
                                        marginLeft={'auto'}
                                    />
                                </HStack>
                            </VStack>
                        </form>
                    </div>

                    {loginFormErrorMessage && <AlertInfo status={'warning'} message={loginFormErrorMessage} />}

                    {
                        loginBlockUntil && (
                            <Description
                                label={t('unauthorised:actionMessages.userLoginIsBlocked')}
                                value={(
                                    <Countdown
                                        date={loginBlockUntil}
                                        onCountdownEnd={dispatchClearLoginState}
                                    />
                                )}
                            />
                        )
                    }
                </Stack>
            </SpinnerOverlay>
        </StandardUnauthorisedPage>
    );
}


export default LoginPage;
