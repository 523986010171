export enum ModalName {
    // unauthorized
    TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    FAQ = 'FAQ',
}

export interface ModalProps {
    title: string;
    modalSize?: string;
    footer?: string;
    data?: object;
    id?: string
}

export interface ModalType {
    modalType: string | null;
}

export interface ModalState {
    open: boolean;
    modalType: ModalType['modalType'];
    modalProps: ModalProps;
}

export interface ShowModalPayload {
    modalType: ModalType['modalType'];
    modalProps: ModalProps;
}
