import { Grid } from '@chakra-ui/react';
import * as React from 'react';
import { ReactElement } from 'react';

import StandardDataListCard from 'components/common/atoms/StandardCardSection';

import { StandardDetailsCardsProps } from './StandardDetailsCards';
import Styled from './StandardDetailsCards.styled';


function StandardDetailsCards<ItemDefinition>({
    item,
    cardsConfiguration,
}: StandardDetailsCardsProps<ItemDefinition>): ReactElement {
    return (
        <Styled.StandardDetailsCardsWrapper>
            <Grid
                templateColumns={{ base: 'repeat(6, minmax(0,1fr))', md: 'repeat(12, minmax(0,1fr))' }}
                gridColumnGap={{ base: 0, md: 4, lg: 6 }}
                gridRowGap={{ base: 1, md: 4, lg: 6 }}
                padding={{ base: 1, md: 2, lg: 3 }}
            >
                {cardsConfiguration.map((card, index) => (
                    <StandardDataListCard<ItemDefinition>
                        key={index}
                        item={item}
                        gridItemConfig={card?.gridItemConfig}
                        cardTitle={card.cardTitle}
                        columnsConfiguration={card.columnsConfiguration}
                        showCopyButton={card.showCopyButton}
                        createCopyContent={card?.createCopyContent}
                    />
                ))}
            </Grid>
        </Styled.StandardDetailsCardsWrapper>
    );
}


export default StandardDetailsCards;
