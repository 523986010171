import { FormLabel } from '@chakra-ui/react';
import styled from 'styled-components';


export const AnimatedInputContainer = styled(FormLabel)`
    display: flex;
    white-space: nowrap;
    align-items: center;
    > div {
        width: 100%;
    }
`;

export default { AnimatedInputContainer };
