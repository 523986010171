import { Image, ImageProps, Skeleton } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/system';
import React, { useState } from 'react';


interface ImageRootProps extends ImageProps {
    srcDark:string
}
function ImageRoot({
    height,
    src,
    srcDark,
    borderRadius,
    ...rest
}:ImageRootProps) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const { colorMode } = useColorMode();
    return (
        <Skeleton isLoaded={imageLoaded} borderRadius={borderRadius} height={height}>
            <Image
                src={colorMode === 'light' ? src : srcDark ?? src}
                onLoad={() => setImageLoaded(true)}
                height={height}
                borderRadius={borderRadius}
                {...rest}
            />
        </Skeleton>
    );
}
export default ImageRoot;
