import styled, { css } from 'styled-components';

import { BASE_COLOR_NUMBER, getChakraVarColorWithEndpoint } from 'utils/theme-tools';


const StandardSelectWrapper = styled.div<{
    $colorMode: 'light' | 'dark';
    $colorScheme: string;
    $activeColorScheme: string;
    $scrollCSS?: ReturnType<typeof css>;
    $errorBorderColor?: string;
    $hasError?: boolean;
}>`
  width: 100%;

  .react-dropdown-select {
    border-color: ${({
        $hasError,
        $errorBorderColor,
    }) => ($hasError ? $errorBorderColor : 'var(--chakra-colors-chakra-border-color)')};
    border-width: ${({ $hasError }) => ($hasError ? '2px' : '1px')};
    height: var(--chakra-sizes-10);
    font-size: var(--chakra-fontSizes-md);
    border-radius: var(--chakra-radii-md);
  }

  .react-dropdown-select-input {
    font-size: var(--chakra-fontSizes-md);
  }

  .react-dropdown-select-content {
    overflow: hidden;

    input::placeholder {
      color: var(--chakra-colors-gray-500);
    }
  }

  .react-dropdown-select-dropdown-position-bottom {
    width: 100% !important;
    top: 125% !important;
  }

  .react-dropdown-select-dropdown {
    ${({ $scrollCSS }) => $scrollCSS};

    border-color: var(--chakra-colors-chakra-border-color);

    background: var(--chakra-colors-chakra-body-bg);
    border-radius: var(--chakra-radii-md);

    .react-dropdown-select-item {
      border-color: var(--chakra-colors-chakra-border-color);
    }

    .react-dropdown-select-item-active {
      background-color: ${({ $activeColorScheme }) => ($activeColorScheme)};
    }

    .react-dropdown-select-item-selected {
      background-color: ${({ $colorScheme }) => ($colorScheme)};
    }

    /* TODO: add proper styling once one singe theme will be in use */

    .react-dropdown-select-item.react-dropdown-select-item-selected {
      color: ${({ $colorMode }) => ($colorMode === 'dark' ? 'var(--chakra-colors-chakra-body-bg)' : 'var(--chakra-colors-white)')};
    }


  }

  /* TODO proper styling */

  .react-dropdown-select[aria-expanded="true"] {
    box-shadow: ${() => (`0 0 0 1px ${getChakraVarColorWithEndpoint(BASE_COLOR_NUMBER)}`)};


    .react-dropdown-select-dropdown-handle {
      color: ${({ $colorScheme }) => ($colorScheme)};
    }
  }

  ${({ $colorMode }) => $colorMode === 'dark' && css`
    .react-dropdown-select-dropdown {
      background: var(--chakra-colors-chakra-body-bg);
    }
  `};

`;

const MultiOptionCustomItem = styled.div`
  padding: 10px;
  cursor: pointer;
`;

const CustomNoDataWrapper = styled.div`
  text-align: center;
  display: grid;
  justify-content: center;
  padding: 10px;
  background: var(--chakra-colors-chakra-body-bg);
  color: var(--chakra-colors-chakra-body-text);;

`;
export default { StandardSelectWrapper, MultiOptionCustomItem, CustomNoDataWrapper };
