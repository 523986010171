import { createAction } from '@reduxjs/toolkit';

import { ClearEntityDetailsPayload } from 'models/app/standardEntityDetails';

import {
    FETCH_CONSENT_DETAILS,
    FETCH_CONSENT_DETAILS_FAILURE,
    FETCH_CONSENT_DETAILS_SUCCESS,
    CLEAR_CONSENT_DETAILS,
    FetchConsentDetailsPayload,
    FetchConsentDetailsSuccessPayload,
    FetchConsentDetailsFailurePayload,
    REVOKE_CONSENT,
    RevokeConsentPayload,
    REVOKE_CONSENT_SUCCESS,
    REVOKE_CONSENT_FAILURE,
} from './actions.types';


export const fetchConsentDetails = createAction(FETCH_CONSENT_DETAILS,
    ({ queryParams, locationPathname }: FetchConsentDetailsPayload) => ({ payload: { queryParams, locationPathname } }));

export const fetchConsentDetailsSuccess = createAction(FETCH_CONSENT_DETAILS_SUCCESS,
    ({ responsePayload, locationPathname }: FetchConsentDetailsSuccessPayload) => ({
        payload: {
            responsePayload,
            locationPathname,
        },
    }));

export const fetchConsentDetailsFailure = createAction(FETCH_CONSENT_DETAILS_FAILURE,
    ({ locationPathname }: FetchConsentDetailsFailurePayload) => ({ payload: { locationPathname } }));

export const clearConsentDetails = createAction(CLEAR_CONSENT_DETAILS,
    ({ locationPathname }: ClearEntityDetailsPayload) => ({ payload: { locationPathname } }));

export const revokeConsent = createAction(REVOKE_CONSENT,
    ({ consentUuid, locationPathname }:RevokeConsentPayload) => ({ payload: { consentUuid, locationPathname } }));
export const revokeConsentSuccess = createAction(REVOKE_CONSENT_SUCCESS);
export const revokeConsentFailure = createAction(REVOKE_CONSENT_FAILURE);
