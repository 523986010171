function mapArabicToLatinNumbers(text: string): string {

    return text
        .replace(/[\u0660-\u0669]/g, (c) => `${c.charCodeAt(0) - 0x0660}`)
        .replace(/[\u06f0-\u06f9]/g, (c) => `${c.charCodeAt(0) - 0x06f0}`);
}

function getSpecialChars(arrayFormInputString: string[]): string[] {

    return arrayFormInputString.reduce((acc, char) => {
        return isNaN(parseInt(char, 10)) ? acc.concat(char) : acc;
    }, <string[]>[]);
}

export const parseLocalizedStringNumberToNumber = (
    text = '',
    precision = 2,
    decimalSeparator = '.',
): number => {

    const numbersConverted2English = mapArabicToLatinNumbers(text.trim());
    const arrayFormInputString = Array.from(numbersConverted2English);
    const specialChars = getSpecialChars(arrayFormInputString);
    const uniqueSpecialChars = [...new Set<string>(specialChars)];
    const handlers = [
        {
            p: () => specialChars.length === 0 || (specialChars.length === 1 && specialChars[0] === '.'),
            h: () => parseFloat(numbersConverted2English),
        },
        {
            p: () => specialChars.length === 1 && decimalSeparator === specialChars[0],
            h: () => {
                return parseFloat(numbersConverted2English.replace(decimalSeparator, '.'));
            },
        },
        {
            p: () => uniqueSpecialChars.length === 2,
            h: () => {
                const pattern = uniqueSpecialChars[0];

                const ptrn: string = pattern === decimalSeparator ? `\\${pattern}` : pattern;
                const regExp = new RegExp(ptrn, 'g');
                const tmp = numbersConverted2English.replace(regExp, '');

                return parseFloat(tmp.replace(uniqueSpecialChars[1], decimalSeparator));
            },
        },
        {
            p: () => true,
            h: () => {

                const regExp = new RegExp(`\\${uniqueSpecialChars[0]}`, 'g');
                const tmp = numbersConverted2English.replace(regExp, '');

                return parseFloat(tmp);
            },
        },
    ];

    const result: number = handlers.filter(({ p }) => p())[0].h();

    return +result.toFixed(precision);
};
