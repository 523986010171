import styled from 'styled-components';


const AuthorisedLogoWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 20px;
    align-items: center;

    h2, h3 {
        margin: 0;
        font-family: monospace;
        font-size: 12px;
        text-transform: uppercase;
        word-spacing: 1px;

        @media (min-width: 768px) {
            font-size: 17px;
            word-spacing: 3px;
        }
    }
`;


export default { AuthorisedLogoWrapper };
