import { ApiError, AuthErrors, TFunction } from '@manigo/manigo-commons';


enum SET_CURRENT_USER_VARIANT {
    LOGIN = 'LOGIN',
    GET_JWT_FROM_STORAGE = 'GET_JWT_FROM_STORAGE',
    REFRESH_SESSION = 'REFRESH_SESSION',
    DUPLICATE_TAB = 'DUPLICATE_TAB',
}

export const createChangeBusinessUserPasswordErrorMessage = (t: TFunction, error: ApiError) => {
    const handlers = [
        {
            predicate: () => error.message === AuthErrors.INVALID_CREDENTIALS,
            handler: () => (t('businessUsers:actionMessages.changePasswordInvalidOldPassword')),
        },
        {
            predicate: () => true,
            handler: () => (t('unauthorised:genericApiError')),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default SET_CURRENT_USER_VARIANT;
