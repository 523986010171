import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { hideModal } from 'store/modal/actions';
import { RootState } from 'store/rootReducer';

import ModalRoot from './ModalRoot.layout';
import { ModalDispatchProps, ModalStateProps } from './ModalRoot.types';


const mapStateToProps = (state: RootState):ModalStateProps => ({ modalState: state.modal });
const mapDispatchToProps = (dispatch):ModalDispatchProps => ({ dispatchHideModal: () => dispatch(hideModal()) });

const decorate = compose<ComponentType>(
    connect(mapStateToProps, mapDispatchToProps),

);
export default decorate(ModalRoot);
