import { Uuid } from '@manigo/manigo-domain-typings';

import { RequestConfig } from 'models/api/http';

import { HttpService } from 'services/http';

// Used in new consent mode
export const fetchConsentDetailsAPI = (consentUuid: Uuid) => HttpService.get(`/v2/open-banking/consents/${consentUuid}`, { noErrorHandling: true });
export const changeConsentStatusAPI = (requestPayload) => HttpService.post('/v2/open-banking/consent/result', requestPayload);
// XXX this one is public
export const declineConsentRequestAPI = (requestPayload) => HttpService.post('/v2/open-banking/consent/reject', requestPayload, { noErrorHandling: true });

// used in view consents mode
export const fetchConsentsAPI = (requestConfig?: RequestConfig) => HttpService.get('/v2/open-banking/consents', requestConfig);
export const revokeConsentAPI = (consentUuid: Uuid) => HttpService.delete(`/v2/open-banking/consents/${consentUuid}`);


export default {
    fetchConsentDetailsAPI,
    declineConsentRequestAPI,
    changeConsentStatusAPI,
    fetchConsentsAPI,
    revokeConsentAPI,
};
