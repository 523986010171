import { createAction } from '@reduxjs/toolkit';

import { LoginType } from 'models/domain/authorisation';

import {
    CONFIRM_LOGIN,
    CONFIRM_LOGIN_FAILURE,
    CONFIRM_LOGIN_SUCCESS,
    GET_TOKENS_FOR_DUPLICATED_TAB,
    INITIALISE_LOGIN,
    INITIALISE_LOGIN_FAILURE,
    INITIALISE_LOGIN_SUCCESS,
    SET_NEW_PASSWORD,
    SET_NEW_PASSWORD_FAILURE,
    SET_NEW_PASSWORD_SUCCESS,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILURE,
    ACTIVATE_USER,
    ACTIVATE_USER_SUCCESS,
    ACTIVATE_USER_FAILURE,
    CLEAR_LOGIN_STATE,
    SET_PASSCODE_DIGIT,
    CLEAR_ALL_PASSCODE_DIGITS,
    SetPasscodePayload,
    SET_LOGIN_TYPE,
    InitialiseLoginPayload,
    InitialiseLoginSuccessPayload,
    ConfirmLoginPayload,
    ConfirmLoginSuccessPayload,
} from './actions.types';


export const activateUser = createAction(ACTIVATE_USER, (requestPayload) => {
    return { payload: requestPayload };
});
export const activateUserSuccess = createAction(ACTIVATE_USER_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const activateUserFailure = createAction(ACTIVATE_USER_FAILURE);


export const initialiseLogin = createAction(INITIALISE_LOGIN, (requestPayload: InitialiseLoginPayload) => {
    return { payload: requestPayload };
});
export const initialiseLoginSuccess = createAction(INITIALISE_LOGIN_SUCCESS, (responsePayload: InitialiseLoginSuccessPayload) => {
    return { payload: responsePayload };
});
export const initialiseLoginFailure = createAction(INITIALISE_LOGIN_FAILURE, (responsePayload) => {
    return { payload: responsePayload };
});

export const clearLoginState = createAction(CLEAR_LOGIN_STATE);

export const confirmLogin = createAction(CONFIRM_LOGIN, (requestPayload: ConfirmLoginPayload) => {
    return { payload: requestPayload };
});
export const confirmLoginSuccess = createAction(CONFIRM_LOGIN_SUCCESS, (responsePayload: ConfirmLoginSuccessPayload) => {
    return { payload: responsePayload };
});
export const confirmLoginFailure = createAction(CONFIRM_LOGIN_FAILURE, (responsePayload) => {
    return { payload: responsePayload };
});


export const setNewPassword = createAction(SET_NEW_PASSWORD, (requestPayload) => {
    return { payload: requestPayload };
});
export const setNewPasswordSuccess = createAction(SET_NEW_PASSWORD_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const setNewPasswordFailure = createAction(SET_NEW_PASSWORD_FAILURE);

export const getTokensForDuplicatedTab = createAction(GET_TOKENS_FOR_DUPLICATED_TAB, (requestPayload: object, sessionStorageData?: any) => {
    return { payload: { requestPayload, sessionStorageData } };
});

export const refreshToken = createAction(REFRESH_TOKEN);
export const refreshTokenSuccess = createAction(REFRESH_TOKEN_SUCCESS);
export const refreshTokenFailure = createAction(REFRESH_TOKEN_FAILURE);

export const setPasscodeDigit = createAction(SET_PASSCODE_DIGIT,
    ({ key, value }:SetPasscodePayload) => ({ payload: { key, value } }));
export const clearAllPasscodeDigits = createAction(CLEAR_ALL_PASSCODE_DIGITS);


export const setLoginType = createAction(SET_LOGIN_TYPE, (loginType: LoginType) => ({ payload: loginType }));
