import { css } from 'styled-components';

import { getChakraVarColorByChakraHookReturn } from 'utils/theme-tools';


export const scrollBarCSS = ({ scrollBg, scrollColor, scrollColorActive }: {
    scrollBg: string;
    scrollColor: string;
    scrollColorActive: string;
}): ReturnType<typeof css> => css`
  &::-webkit-scrollbar {
    width: .4rem;
    height: .6rem;
    background-color: ${getChakraVarColorByChakraHookReturn(scrollBg)};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: ${getChakraVarColorByChakraHookReturn(scrollColor)};
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: ${getChakraVarColorByChakraHookReturn(scrollColorActive)};
  }

  &::-webkit-scrollbar-track {
    border-radius: .6rem;
    background-color: ${getChakraVarColorByChakraHookReturn(scrollBg)};
  }
`;
