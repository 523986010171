import { HttpService } from 'services/http';


export const loginInitAPI = (requestPayload: object) => HttpService.post('/v3/obawa/auth/login/init', requestPayload, { noErrorHandling: true });
export const loginConfirmAPI = (requestPayload: object) => HttpService.post('/v3/obawa/auth/login/confirm', requestPayload, { noErrorHandling: true });
export const extendTokenValidityAPI = (requestPayload: object) => HttpService.put('/v3/bwp/auth/session-refresh', requestPayload);

// XXX note 'bop' in endpoint path!
export const getTokensForDuplicatedSessionAPI = (requestPayload: object) => HttpService.put('/v3/bwp/auth/session-duplicate', requestPayload);
export const expireTokenAPI = () => HttpService.put('/v2/auth/session-logout', undefined, { noErrorHandling: true });
