import React from 'react';
import { withTranslation } from 'react-i18next';

import StandardTag from 'components/common/atoms/StandardTag';

import { createBooleanValueHandlers } from './BooleanValueTag.helpers';
import { BooleanValueTagProps } from './BooleanValueTag.types';


function BooleanValueTag({ value, t }: BooleanValueTagProps) {
    const tagData = createBooleanValueHandlers({ value, t });

    return (
        <StandardTag
            color={tagData.color}
            value={tagData.text}
            leftIcon={tagData.leftIcon}
            size={'lg'}
            variant={'outline'}
        />
    );
}

export default withTranslation('common')(BooleanValueTag);
