import { useColorModeValue } from '@chakra-ui/react';
import { useMemo } from 'react';

import { primaryColorSchemeKey } from 'config/config';

import { scrollBarCSS } from 'assets/styles/mixins/scrollbar.styled';


const useScrollThemeCss = () => {
    const scrollBg = useColorModeValue('gray.300', 'gray.200');
    const scrollColor = useColorModeValue(`${primaryColorSchemeKey}.300`, `${primaryColorSchemeKey}.200`);
    const scrollColorActive = useColorModeValue(`${primaryColorSchemeKey}.400`, `${primaryColorSchemeKey}.300`);

    const css = useMemo(() => scrollBarCSS({
        scrollBg,
        scrollColor,
        scrollColorActive,
    }), [scrollBg, scrollColor, scrollColorActive]);
    return css;
};
export default useScrollThemeCss;
