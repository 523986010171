import React, { JSX } from 'react';

import ConsentRequestErrors from 'components/common/templates/ConsentRequestErrors';

import { SuccessWithRedirectionProps } from './ConsentHasExpired.types';


function ConsentHasExpired({ t }: SuccessWithRedirectionProps): JSX.Element {

    return (
        <ConsentRequestErrors
            headingCopy={t('consent:newConsentPage.consentHasExpired.heading')}
            infoCopy={t('consent:newConsentPage.consentHasExpired.info')}
            buttonCopy={t('consent:newConsentPage.consentHasExpired.redirectButtonText')}
        />
    );
}


export default ConsentHasExpired;
