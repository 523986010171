import React, { JSX, useMemo } from 'react';

import ButtonMenu from 'components/common/molecules/ButtonMenu';

import { createLanguageSelectorItemsConfig } from './LanguageSelector.helpers';
import { LanguageSelectorProps } from './LanguageSelector.types';


function LanguageSelector({ dispatchLoadLanguage, selectedLocale }: LanguageSelectorProps): JSX.Element {

    const languageOptions = useMemo(() => {
        return createLanguageSelectorItemsConfig({ dispatchLoadLanguage, selectedLocale }).map((item) => ({
            id: item.id,
            dataTestId: `language-selector-${item.id}`,
            label: item.label,
            onClick: item.onClick,
        }));
    }, [selectedLocale]);

    const selectedLanguageOption = useMemo(() => {
        return languageOptions.find((language) => language.id === selectedLocale)?.label;
    }, [selectedLocale]);

    return languageOptions?.length > 1
        ? (
            <ButtonMenu
                label={selectedLanguageOption}
                items={languageOptions}
            />
        )
        : <></>;
}

export default LanguageSelector;
