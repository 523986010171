export enum StandardValueType {
    TEXT = 'TEXT',
    AMOUNT = 'AMOUNT',
    COUNTER = 'COUNTER',
    PERCENTAGE = 'PERCENTAGE',
    DATE = 'DATE',
    PHONE = 'PHONE',
    IBAN = 'IBAN',
    BOOLEAN = 'BOOLEAN',
}
export const emptyValue = '-';

