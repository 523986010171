import { Input, InputGroup, InputLeftElement, InputRightElement, Skeleton } from '@chakra-ui/react';
import React, { JSX } from 'react';
import { MdSearch } from 'react-icons/md';

import TheIcon from 'components/common/atoms/TheIcon';
import InfoIconTooltip from 'components/common/molecules/InfoIconTooltip';

import Styled from '../StandardFilters.styled';


function SorterFields({
    queryParams,
    handleQueryParamsChange,
    filtersConfiguration,
    colorScheme,
    isListReady,
    t,
}): JSX.Element {

    // TODO add debounce or throttling over onChange
    return filtersConfiguration?.searchBy && filtersConfiguration?.searchBy?.length > 0
        ? (
            <Styled.FilterWrapper>
                {isListReady ? (
                    <InputGroup
                        colorScheme={colorScheme}
                        marginTop="18px"
                    >
                        <InputLeftElement pointerEvents="none">
                            <TheIcon Icon={MdSearch} />
                        </InputLeftElement>

                        <Input
                            colorScheme={colorScheme}
                            id="data-list-search-input"
                            data-test-id="data-list-search-input"
                            name="search"
                            onChange={(event) => handleQueryParamsChange({
                                fieldName: 'search',
                                value: event.target.value,
                                resetOffset: true,
                            })}
                            placeholder={t('common:typeToSearch')}
                            value={queryParams.search || ''}
                        />
                        <InputRightElement>
                            <InfoIconTooltip
                                infoTooltipText={t('common:searchOverFields', { fieldsNamesList: filtersConfiguration?.searchBy.join(', ') })}
                            />
                        </InputRightElement>
                    </InputGroup>
                ) : (
                    <Skeleton height="50px" width="100%" rounded="md" />
                )}
            </Styled.FilterWrapper>

        ) : (<></>);
}

export default SorterFields;
