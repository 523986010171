import { getDirectionFromLocale } from '@manigo/manigo-commons';
import { useSelector } from 'react-redux';

import { LanguageDirection } from 'models/app/i18n';

import { defaultLocale } from 'config/config';

import { localesReducerName } from 'store/locales/reducer';
import { RootState } from 'store/rootReducer';


export function useLanguageDirection(): LanguageDirection {
    const selectedLocale = useSelector((state: RootState) => state[localesReducerName].selectedLocale);
    return getDirectionFromLocale(selectedLocale || defaultLocale);
}
