import /* webpackPreload: true */ /* webpackChunkName: "AD_country_flag" */ ad
    from 'assets/images/country-flags/AD.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AE_country_flag" */ ae
    from 'assets/images/country-flags/AE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AF_country_flag" */ af
    from 'assets/images/country-flags/AF.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AG_country_flag" */ ag
    from 'assets/images/country-flags/AG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AI_country_flag" */ ai
    from 'assets/images/country-flags/AI.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AL_country_flag" */ al
    from 'assets/images/country-flags/AL.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AM_country_flag" */ am
    from 'assets/images/country-flags/AM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AO_country_flag" */ ao
    from 'assets/images/country-flags/AO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AQ_country_flag" */ aq
    from 'assets/images/country-flags/AQ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AR_country_flag" */ ar
    from 'assets/images/country-flags/AR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AS_country_flag" */ as
    from 'assets/images/country-flags/AS.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AT_country_flag" */ at
    from 'assets/images/country-flags/AT.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AU_country_flag" */ au
    from 'assets/images/country-flags/AU.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AW_country_flag" */ aw
    from 'assets/images/country-flags/AW.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AX_country_flag" */ ax
    from 'assets/images/country-flags/AX.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "AZ_country_flag" */ az
    from 'assets/images/country-flags/AZ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BA_country_flag" */ ba
    from 'assets/images/country-flags/BA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BB_country_flag" */ bb
    from 'assets/images/country-flags/BB.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BD_country_flag" */ bd
    from 'assets/images/country-flags/BD.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BE_country_flag" */ be
    from 'assets/images/country-flags/BE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BF_country_flag" */ bf
    from 'assets/images/country-flags/BF.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BG_country_flag" */ bg
    from 'assets/images/country-flags/BG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BH_country_flag" */ bh
    from 'assets/images/country-flags/BH.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BI_country_flag" */ bi
    from 'assets/images/country-flags/BI.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BJ_country_flag" */ bj
    from 'assets/images/country-flags/BJ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BL_country_flag" */ bl
    from 'assets/images/country-flags/BL.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BM_country_flag" */ bm
    from 'assets/images/country-flags/BM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BN_country_flag" */ bn
    from 'assets/images/country-flags/BN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BO_country_flag" */ bo
    from 'assets/images/country-flags/BO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BR_country_flag" */ br
    from 'assets/images/country-flags/BR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BS_country_flag" */ bs
    from 'assets/images/country-flags/BS.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BT_country_flag" */ bt
    from 'assets/images/country-flags/BT.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BV_country_flag" */ bv
    from 'assets/images/country-flags/BV.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BW_country_flag" */ bw
    from 'assets/images/country-flags/BW.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BY_country_flag" */ by
    from 'assets/images/country-flags/BY.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "BZ_country_flag" */ bz
    from 'assets/images/country-flags/BZ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CA_country_flag" */ ca
    from 'assets/images/country-flags/CA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CD_country_flag" */ cd
    from 'assets/images/country-flags/CD.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CF_country_flag" */ cf
    from 'assets/images/country-flags/CF.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CG_country_flag" */ cg
    from 'assets/images/country-flags/CG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CH_country_flag" */ ch
    from 'assets/images/country-flags/CH.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CI_country_flag" */ ci
    from 'assets/images/country-flags/CI.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CK_country_flag" */ ck
    from 'assets/images/country-flags/CK.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CL_country_flag" */ cl
    from 'assets/images/country-flags/CL.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CM_country_flag" */ cm
    from 'assets/images/country-flags/CM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CN_country_flag" */ cn
    from 'assets/images/country-flags/CN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CO_country_flag" */ co
    from 'assets/images/country-flags/CO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CR_country_flag" */ cr
    from 'assets/images/country-flags/CR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CU_country_flag" */ cu
    from 'assets/images/country-flags/CU.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CV_country_flag" */ cv
    from 'assets/images/country-flags/CV.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CW_country_flag" */ cw
    from 'assets/images/country-flags/CW.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CX_country_flag" */ cx
    from 'assets/images/country-flags/CX.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CY_country_flag" */ cy
    from 'assets/images/country-flags/CY.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "CZ_country_flag" */ cz
    from 'assets/images/country-flags/CZ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "DE_country_flag" */ de
    from 'assets/images/country-flags/DE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "DJ_country_flag" */ dj
    from 'assets/images/country-flags/DJ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "DK_country_flag" */ dk
    from 'assets/images/country-flags/DK.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "DM_country_flag" */ dm
    from 'assets/images/country-flags/DM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "DO_country_flag" */ dominicanRepublic
    from 'assets/images/country-flags/DO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "DZ_country_flag" */ dz
    from 'assets/images/country-flags/DZ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "EC_country_flag" */ ec
    from 'assets/images/country-flags/EC.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "EE_country_flag" */ ee
    from 'assets/images/country-flags/EE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "EG_country_flag" */ eg
    from 'assets/images/country-flags/EG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "ER_country_flag" */ er
    from 'assets/images/country-flags/ER.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "ES_country_flag" */ es
    from 'assets/images/country-flags/ES.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "ET_country_flag" */ et
    from 'assets/images/country-flags/ET.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "EU_country_flag" */ eu
    from 'assets/images/country-flags/EU.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "FI_country_flag" */ fi
    from 'assets/images/country-flags/FI.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "FJ_country_flag" */ fj
    from 'assets/images/country-flags/FJ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "FO_country_flag" */ fo
    from 'assets/images/country-flags/FO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "FR_country_flag" */ fr
    from 'assets/images/country-flags/FR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GA_country_flag" */ ga
    from 'assets/images/country-flags/GA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GB_country_flag" */ gb
    from 'assets/images/country-flags/GB.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GD_country_flag" */ gd
    from 'assets/images/country-flags/GD.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GE_country_flag" */ ge
    from 'assets/images/country-flags/GE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GG_country_flag" */ gg
    from 'assets/images/country-flags/GG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GH_country_flag" */ gh
    from 'assets/images/country-flags/GH.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GI_country_flag" */ gi
    from 'assets/images/country-flags/GI.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GL_country_flag" */ gl
    from 'assets/images/country-flags/GL.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GM_country_flag" */ gm
    from 'assets/images/country-flags/GM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GN_country_flag" */ gn
    from 'assets/images/country-flags/GN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GQ_country_flag" */ gq
    from 'assets/images/country-flags/GQ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GR_country_flag" */ gr
    from 'assets/images/country-flags/GR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GT_country_flag" */ gt
    from 'assets/images/country-flags/GT.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GU_country_flag" */ gu
    from 'assets/images/country-flags/GU.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GW_country_flag" */ gw
    from 'assets/images/country-flags/GW.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "GY_country_flag" */ gy
    from 'assets/images/country-flags/GY.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "HK_country_flag" */ hk
    from 'assets/images/country-flags/HK.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "HN_country_flag" */ hn
    from 'assets/images/country-flags/HN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "HR_country_flag" */ hr
    from 'assets/images/country-flags/HR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "HT_country_flag" */ ht
    from 'assets/images/country-flags/HT.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "HU_country_flag" */ hu
    from 'assets/images/country-flags/HU.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "ID_country_flag" */ id
    from 'assets/images/country-flags/ID.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "IE_country_flag" */ ie
    from 'assets/images/country-flags/IE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "IL_country_flag" */ il
    from 'assets/images/country-flags/IL.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "IM_country_flag" */ im
    from 'assets/images/country-flags/IM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "IN_country_flag" */ india
    from 'assets/images/country-flags/IN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "IQ_country_flag" */ iq
    from 'assets/images/country-flags/IQ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "IR_country_flag" */ ir
    from 'assets/images/country-flags/IR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "IS_country_flag" */ is
    from 'assets/images/country-flags/IS.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "IT_country_flag" */ it
    from 'assets/images/country-flags/IT.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "JE_country_flag" */ je
    from 'assets/images/country-flags/JE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "JM_country_flag" */ jm
    from 'assets/images/country-flags/JM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "JO_country_flag" */ jo
    from 'assets/images/country-flags/JO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "JP_country_flag" */ jp
    from 'assets/images/country-flags/JP.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KE_country_flag" */ ke
    from 'assets/images/country-flags/KE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KG_country_flag" */ kg
    from 'assets/images/country-flags/KG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KH_country_flag" */ kh
    from 'assets/images/country-flags/KH.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KI_country_flag" */ ki
    from 'assets/images/country-flags/KI.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KM_country_flag" */ km
    from 'assets/images/country-flags/KM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KN_country_flag" */ kn
    from 'assets/images/country-flags/KN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KP_country_flag" */ kp
    from 'assets/images/country-flags/KP.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KR_country_flag" */ kr
    from 'assets/images/country-flags/KR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KW_country_flag" */ kw
    from 'assets/images/country-flags/KW.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KY_country_flag" */ ky
    from 'assets/images/country-flags/KY.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "KZ_country_flag" */ kz
    from 'assets/images/country-flags/KZ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LA_country_flag" */ la
    from 'assets/images/country-flags/LA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LB_country_flag" */ lb
    from 'assets/images/country-flags/LB.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LC_country_flag" */ lc
    from 'assets/images/country-flags/LC.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LI_country_flag" */ li
    from 'assets/images/country-flags/LI.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LK_country_flag" */ lk
    from 'assets/images/country-flags/LK.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LR_country_flag" */ lr
    from 'assets/images/country-flags/LR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LS_country_flag" */ ls
    from 'assets/images/country-flags/LS.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LT_country_flag" */ lt
    from 'assets/images/country-flags/LT.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LU_country_flag" */ lu
    from 'assets/images/country-flags/LU.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LV_country_flag" */ lv
    from 'assets/images/country-flags/LV.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "LY_country_flag" */ ly
    from 'assets/images/country-flags/LY.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MA_country_flag" */ ma
    from 'assets/images/country-flags/MA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MC_country_flag" */ mc
    from 'assets/images/country-flags/MC.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MD_country_flag" */ md
    from 'assets/images/country-flags/MD.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "ME_country_flag" */ me
    from 'assets/images/country-flags/ME.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MG_country_flag" */ mg
    from 'assets/images/country-flags/MG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MH_country_flag" */ mh
    from 'assets/images/country-flags/MH.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MK_country_flag" */ mk
    from 'assets/images/country-flags/MK.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "ML_country_flag" */ ml
    from 'assets/images/country-flags/ML.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MM_country_flag" */ mm
    from 'assets/images/country-flags/MM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MN_country_flag" */ mn
    from 'assets/images/country-flags/MN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MO_country_flag" */ mo
    from 'assets/images/country-flags/MO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MP_country_flag" */ mp
    from 'assets/images/country-flags/MP.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MR_country_flag" */ mr
    from 'assets/images/country-flags/MR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MS_country_flag" */ ms
    from 'assets/images/country-flags/MS.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MT_country_flag" */ mt
    from 'assets/images/country-flags/MT.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MU_country_flag" */ mu
    from 'assets/images/country-flags/MU.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MV_country_flag" */ mv
    from 'assets/images/country-flags/MV.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MW_country_flag" */ mw
    from 'assets/images/country-flags/MW.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MX_country_flag" */ mx
    from 'assets/images/country-flags/MX.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MY_country_flag" */ my
    from 'assets/images/country-flags/MY.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "MZ_country_flag" */ mz
    from 'assets/images/country-flags/MZ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NA_country_flag" */ na
    from 'assets/images/country-flags/NA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NE_country_flag" */ ne
    from 'assets/images/country-flags/NE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NF_country_flag" */ nf
    from 'assets/images/country-flags/NF.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NG_country_flag" */ ng
    from 'assets/images/country-flags/NG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NI_country_flag" */ ni
    from 'assets/images/country-flags/NI.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NL_country_flag" */ nl
    from 'assets/images/country-flags/NL.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NO_country_flag" */ no
    from 'assets/images/country-flags/NO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NP_country_flag" */ np
    from 'assets/images/country-flags/NP.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NR_country_flag" */ nr
    from 'assets/images/country-flags/NR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NU_country_flag" */ nu
    from 'assets/images/country-flags/NU.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "NZ_country_flag" */ nz
    from 'assets/images/country-flags/NZ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "OM_country_flag" */ om
    from 'assets/images/country-flags/OM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PA_country_flag" */ pa
    from 'assets/images/country-flags/PA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PE_country_flag" */ pe
    from 'assets/images/country-flags/PE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PF_country_flag" */ pf
    from 'assets/images/country-flags/PF.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PG_country_flag" */ pg
    from 'assets/images/country-flags/PG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PH_country_flag" */ ph
    from 'assets/images/country-flags/PH.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PK_country_flag" */ pk
    from 'assets/images/country-flags/PK.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PL_country_flag" */ pl
    from 'assets/images/country-flags/PL.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PN_country_flag" */ pn
    from 'assets/images/country-flags/PN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PR_country_flag" */ pr
    from 'assets/images/country-flags/PR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PT_country_flag" */ pt
    from 'assets/images/country-flags/PT.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PW_country_flag" */ pw
    from 'assets/images/country-flags/PW.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "PY_country_flag" */ py
    from 'assets/images/country-flags/PY.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "QA_country_flag" */ qa
    from 'assets/images/country-flags/QA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "RO_country_flag" */ ro
    from 'assets/images/country-flags/RO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "RS_country_flag" */ rs
    from 'assets/images/country-flags/RS.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "RU_country_flag" */ ru
    from 'assets/images/country-flags/RU.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "RW_country_flag" */ rw
    from 'assets/images/country-flags/RW.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SA_country_flag" */ sa
    from 'assets/images/country-flags/SA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SB_country_flag" */ sb
    from 'assets/images/country-flags/SB.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SC_country_flag" */ sc
    from 'assets/images/country-flags/SC.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SD_country_flag" */ sd
    from 'assets/images/country-flags/SD.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SE_country_flag" */ se
    from 'assets/images/country-flags/SE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SG_country_flag" */ sg
    from 'assets/images/country-flags/SG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SI_country_flag" */ si
    from 'assets/images/country-flags/SI.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SK_country_flag" */ sk
    from 'assets/images/country-flags/SK.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SL_country_flag" */ sl
    from 'assets/images/country-flags/SL.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SM_country_flag" */ sm
    from 'assets/images/country-flags/SM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SN_country_flag" */ sn
    from 'assets/images/country-flags/SN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SO_country_flag" */ so
    from 'assets/images/country-flags/SO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SR_country_flag" */ sr
    from 'assets/images/country-flags/SR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SS_country_flag" */ ss
    from 'assets/images/country-flags/SS.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "ST_country_flag" */ st
    from 'assets/images/country-flags/ST.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SV_country_flag" */ sv
    from 'assets/images/country-flags/SV.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SX_country_flag" */ sx
    from 'assets/images/country-flags/SX.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SY_country_flag" */ sy
    from 'assets/images/country-flags/SY.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "SZ_country_flag" */ sz
    from 'assets/images/country-flags/SZ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TC_country_flag" */ tc
    from 'assets/images/country-flags/TC.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TD_country_flag" */ td
    from 'assets/images/country-flags/TD.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TG_country_flag" */ tg
    from 'assets/images/country-flags/TG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TH_country_flag" */ th
    from 'assets/images/country-flags/TH.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TJ_country_flag" */ tj
    from 'assets/images/country-flags/TJ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TK_country_flag" */ tk
    from 'assets/images/country-flags/TK.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TL_country_flag" */ tl
    from 'assets/images/country-flags/TL.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TM_country_flag" */ tm
    from 'assets/images/country-flags/TM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TN_country_flag" */ tn
    from 'assets/images/country-flags/TN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TO_country_flag" */ to
    from 'assets/images/country-flags/TO.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TR_country_flag" */ tr
    from 'assets/images/country-flags/TR.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TT_country_flag" */ tt
    from 'assets/images/country-flags/TT.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TV_country_flag" */ tv
    from 'assets/images/country-flags/TV.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "TZ_country_flag" */ tz
    from 'assets/images/country-flags/TZ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "UA_country_flag" */ ua
    from 'assets/images/country-flags/UA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "UG_country_flag" */ ug
    from 'assets/images/country-flags/UG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "US_country_flag" */ us
    from 'assets/images/country-flags/US.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "UY_country_flag" */ uy
    from 'assets/images/country-flags/UY.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "UZ_country_flag" */ uz
    from 'assets/images/country-flags/UZ.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "VA_country_flag" */ va
    from 'assets/images/country-flags/VA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "VC_country_flag" */ vc
    from 'assets/images/country-flags/VC.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "VE_country_flag" */ ve
    from 'assets/images/country-flags/VE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "VG_country_flag" */ vg
    from 'assets/images/country-flags/VG.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "VN_country_flag" */ vn
    from 'assets/images/country-flags/VN.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "VU_country_flag" */ vu
    from 'assets/images/country-flags/VU.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "WS_country_flag" */ ws
    from 'assets/images/country-flags/WS.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "XK_country_flag" */ xk
    from 'assets/images/country-flags/XK.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "YE_country_flag" */ ye
    from 'assets/images/country-flags/YE.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "ZA_country_flag" */ za
    from 'assets/images/country-flags/ZA.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "ZM_country_flag" */ zm
    from 'assets/images/country-flags/ZM.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "ZW_country_flag" */ zw
    from 'assets/images/country-flags/ZW.svg?url';


const svgCountries = {
    ad,
    ae,
    af,
    ag,
    ai,
    al,
    am,
    ao,
    aq,
    ar,
    as,
    at,
    au,
    aw,
    ax,
    az,
    ba,
    bb,
    bd,
    be,
    bf,
    bg,
    bh,
    bi,
    bj,
    bl,
    bm,
    bn,
    bo,
    br,
    bs,
    bt,
    bv,
    bw,
    by,
    bz,
    ca,
    cd,
    cf,
    cg,
    ch,
    ci,
    ck,
    cl,
    cm,
    cn,
    co,
    cr,
    cu,
    cv,
    cw,
    cx,
    cy,
    cz,
    de,
    dj,
    dk,
    dm,
    do: dominicanRepublic,
    dz,
    ec,
    ee,
    eg,
    er,
    es,
    et,
    eu,
    fi,
    fj,
    fo,
    fr,
    ga,
    gb,
    gd,
    ge,
    gg,
    gh,
    gi,
    gl,
    gm,
    gn,
    gq,
    gr,
    gt,
    gu,
    gw,
    gy,
    hk,
    hn,
    hr,
    ht,
    hu,
    id,
    ie,
    il,
    im,
    in: india,
    iq,
    ir,
    is,
    it,
    je,
    jm,
    jo,
    jp,
    ke,
    kg,
    kh,
    ki,
    km,
    kn,
    kp,
    kr,
    kw,
    ky,
    kz,
    la,
    lb,
    lc,
    li,
    lk,
    lr,
    ls,
    lt,
    lu,
    lv,
    ly,
    ma,
    mc,
    md,
    me,
    mg,
    mh,
    mk,
    ml,
    mm,
    mn,
    mo,
    mp,
    mr,
    ms,
    mt,
    mu,
    mv,
    mw,
    mx,
    my,
    mz,
    na,
    ne,
    nf,
    ng,
    ni,
    nl,
    no,
    np,
    nr,
    nu,
    nz,
    om,
    pa,
    pe,
    pf,
    pg,
    ph,
    pk,
    pl,
    pn,
    pr,
    pt,
    pw,
    py,
    qa,
    ro,
    rs,
    ru,
    rw,
    sa,
    sb,
    sc,
    sd,
    se,
    sg,
    si,
    sk,
    sl,
    sm,
    sn,
    so,
    sr,
    ss,
    st,
    sv,
    sx,
    sy,
    sz,
    tc,
    td,
    tg,
    th,
    tj,
    tk,
    tl,
    tm,
    tn,
    to,
    tr,
    tt,
    tv,
    tz,
    ua,
    ug,
    us,
    uy,
    uz,
    va,
    vc,
    ve,
    vg,
    vn,
    vu,
    ws,
    xk,
    ye,
    za,
    zm,
    zw,
};


export default svgCountries;
