import { NumericId } from '@manigo/manigo-domain-typings';

import { SimplifiedAccount } from './account';


export enum LoginType {
    PHONE_NUMBER = 'PHONE_NUMBER',
    EMAIL = 'EMAIL',
}


export interface ConfirmLoginResponsePayload {
    accessToken: string,
    refreshToken: string,
    expirationTimeOfAccessToken: string,
    expirationTimeOfRefreshToken: string,
    statusCode: number,
    clientId: NumericId,
    companyId: NumericId,
    businessUserId: NumericId,
    memberId: NumericId,
    permissions: any,
    accounts: SimplifiedAccount[],
}
