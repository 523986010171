import { Box, ButtonGroup, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/system';
import React, { JSX } from 'react';

import useIsOverflowing from 'hook/useIsOverflowing';

import AppVersionInfo from 'components/common/atoms/AppVersionInfo';
import ColorModeToggler from 'components/common/atoms/CollorModeToggler';
import CopyrightNotice from 'components/common/atoms/CopyrightNotice';
import ScrollableContainer from 'components/common/atoms/ScrollableContainer';
import LanguageSelector from 'components/common/organisms/LanguageSelector';
import ModalRoot from 'components/common/organisms/ModalRoot';

import Styled from './PublicLayout.styled';
import { PublicLayoutProps } from './PublicLayout.types';
import PublicNavbar from './PublicNavbar';


function PublicLayout({ children, t }: PublicLayoutProps): JSX.Element {
    const navRef = React.useRef<HTMLDivElement>(null);

    const { colorMode } = useColorMode();
    const { isOverflowing } = useIsOverflowing(navRef);
    const isDesktop = Boolean(useBreakpointValue({ base: false, lg: true })) && !isOverflowing;

    return (
        <Styled.OuterWrapper className={colorMode} boxShadow="md">
            <PublicNavbar />
            <ScrollableContainer>
                <Styled.ContentWrapper>
                    <Styled.ContentRouteWrapper id="route-wrapper">
                        {children}
                    </Styled.ContentRouteWrapper>
                </Styled.ContentWrapper>
            </ScrollableContainer>
            <VStack gap={0}>
                <ButtonGroup marginTop={'2'}>
                    {!isDesktop && <LanguageSelector />}
                    {!isDesktop && <ColorModeToggler />}
                </ButtonGroup>

                <Box padding={{ base: '10px', md: '20px' }} textAlign="center">
                    <CopyrightNotice namespace="unauthorised" />
                    <AppVersionInfo
                        display={'inline'}
                        t={t}
                        width={'min-content'}
                        padding={'0 1ch'}
                        margin={0}
                    />
                </Box>
            </VStack>

            <ModalRoot />

        </Styled.OuterWrapper>
    );
}

export default PublicLayout;
