import { useEffect, useState } from 'react';


const useIsOverflowing = (navRef):{ isOverflowing: boolean } => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [minWidth, setMinWidth] = useState(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            const navElement = navRef.current;
            if (!navElement) return;

            const handlers = [
                {
                    predicate: () => !isOverflowing && navElement?.offsetWidth > window.innerWidth,
                    handler: () => { setIsOverflowing(true); setMinWidth(window.innerWidth); },
                },
                {
                    predicate: () => isOverflowing && navElement?.offsetWidth < window.innerWidth,
                    handler: () => { setIsOverflowing(false); },
                },
                {
                    predicate: () => isOverflowing && window.innerWidth > minWidth,
                    handler: () => { setIsOverflowing(false); },
                },
            ];

            handlers.forEach(({ predicate, handler }) => predicate() && handler());
        });

        resizeObserver.observe(document.body);
        return () => {
            resizeObserver.disconnect();
        };
    }, [isOverflowing, minWidth]);

    return { isOverflowing };

};
export default useIsOverflowing;
