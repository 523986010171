import { Heading, Button } from '@chakra-ui/react';
import React, { JSX } from 'react';

import AnimatedCrossMark from 'components/common/atoms/AnimatedCrossMark';
import Countdown from 'components/common/atoms/Countdown';

import Styled from './ConsentRequestErrors.styled';
import { ConsentRequestErrorsProps } from './ConsentRequestErrors.types';


function ConsentHasExpired({
    redirectionUrl,
    dispatchRequestNavigation,
    headingCopy,
    infoCopy,
    buttonCopy,
}: ConsentRequestErrorsProps): JSX.Element {
    const now = new Date();
    const future = new Date(now.getTime() + 15 * 1000);

    const redirect = () => {
        dispatchRequestNavigation({ locationPathname: `${redirectionUrl}` });
    };

    return (

        <Styled.ConsentRequestErrorsWrapper>
            <AnimatedCrossMark />

            <Styled.ConsentRequestErrorsCopyWrapper>
                <>
                    <Heading size="md" mb={'2rem'}>{headingCopy}</Heading>
                    <Heading size="sm" fontWeight="500">{infoCopy}</Heading>

                    <Countdown
                        date={future}
                        style={{
                            justifyContent: 'center',
                            gridTemplateColumns: 'repeat(2, auto)',
                        }}
                        onCountdownEnd={redirect}
                    />

                    <Button onClick={redirect} mt={'2rem'}>{buttonCopy}</Button>
                </>
            </Styled.ConsentRequestErrorsCopyWrapper>
        </Styled.ConsentRequestErrorsWrapper>
    );
}


export default ConsentHasExpired;

