import {
    LocationChangeActionScopeTypes,
    NavigationDirectionTypes,
} from '@manigo/manigo-commons';
import { useEffect, useMemo, useState } from 'react';

import {
    calculateShouldUpdateList,
    getLocationHandlers,
} from 'hook/useStandardListCachingLogic/useStandardListCachingLogic.helpers';
import { UseStandardListCachingLogic } from 'hook/useStandardListCachingLogic/useStandardListCachingLogic.types';


export function useStandardListCachingLogic<Items>({
    assignedLocationPathname,
    useGlobalLocation = false,
    customLocation,
    omitDataFetching = false,

    contextEnforcedQueryParams,
    defaultItemsListQueryParams,

    enhancedCurrentLocation,
    listData,
    listWatchers,
    reducerName,

    dispatchFetchItemsList,
    dispatchClearItemsList,
    dispatchSetQueryParams,
    dispatchMarkAsUpdatedList,
}: UseStandardListCachingLogic<Items>) {
    const [areInitialQueryParamsSet, setAreInitialQueryParamsSet] = useState(false);
    const [doesListRequiresCleanup, setDoesListRequiresCleanup] = useState(false);
    const [isListReadyForDisplay, setIsListReadyForDisplay] = useState(false);

    // InitialQuery === default query params + query params enforced by context/props
    const initialQueryParams = {
        ...defaultItemsListQueryParams,
        ...contextEnforcedQueryParams,
    };

    // helper variables for ease of reading
    const currentPathname = useMemo(
        () => enhancedCurrentLocation.pathname,
        [enhancedCurrentLocation],
    );

    const hasValidLocation = useMemo(
        () => enhancedCurrentLocation?.pathname?.length > 0 && enhancedCurrentLocation?.pathname.includes(assignedLocationPathname),
        [enhancedCurrentLocation],
    );

    const isListPristineEmpty = useMemo(
        () => !listData?.totalCount && !listData?.items,
        [enhancedCurrentLocation],
    );

    const shouldUpdateList = useMemo(
        () => calculateShouldUpdateList<Items>({
            listWatchers,
            listData,
            reducerName,
            currentPathname,
        }), [listWatchers, enhancedCurrentLocation, listData.isLoadingList],
    );

    const locationHandlers = getLocationHandlers({
        customLocation,
        initialQueryParams,
        useGlobalLocation,
        omitDataFetching,
        currentPathname,

        setAreInitialQueryParamsSet,
        setIsListReadyForDisplay,
        setDoesListRequiresCleanup,

        dispatchFetchItemsList,
        dispatchClearItemsList,
        dispatchSetQueryParams,
    });

    // XXX very, very important
    const onLocationChangeHandlers = [

        // 1st time on given location, set queryParams and fetch data, spinner ON
        {
            predicate: () =>
                isListPristineEmpty
                && !areInitialQueryParamsSet
                && !doesListRequiresCleanup
                && enhancedCurrentLocation.direction !== NavigationDirectionTypes.BACKWARD,
            handler: () =>
                locationHandlers.handleEmptyStateOnNavForward(),
        },

        // returning to on given location via forward navigation !!unless it is a TAB_CHANGE!!
        // & clear existing query params and list results (except global lists) for given location and fetch new with initialQueryParams, spinner ON
        {
            predicate: () =>
                !isListPristineEmpty
                && !doesListRequiresCleanup
                && enhancedCurrentLocation?.direction === NavigationDirectionTypes.FORWARD
                && enhancedCurrentLocation?.state?.type !== LocationChangeActionScopeTypes.TAB_CHANGE,

            handler: () => locationHandlers.handleExistingDataOnNavForward(),

        },
        // returning to on given location via TAB_CHANGE or go back, just display what is already there, spinner OFF
        // If the list needs to be updated on back navigation, then it should behave like a forward navigation
        {
            predicate: () =>
                !isListPristineEmpty
                && !areInitialQueryParamsSet
                && !doesListRequiresCleanup
                && (enhancedCurrentLocation?.direction !== NavigationDirectionTypes.FORWARD
                    || (enhancedCurrentLocation?.direction === NavigationDirectionTypes.FORWARD
                        && enhancedCurrentLocation?.state?.type === LocationChangeActionScopeTypes.TAB_CHANGE)
                ),
            handler: () => locationHandlers.handleExistingDataOnNavBackward(),
        },

        // default fallback
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];


    const onListDataChangeHandlers = [
        // 1st time on given location - PART TWO, data was fetched, spinner OFF
        {
            predicate: () => isListPristineEmpty
                && areInitialQueryParamsSet
                && !doesListRequiresCleanup
                && !isListReadyForDisplay
                && !listData?.isLoadingList, // XXX important
            handler: () => {
                setIsListReadyForDisplay(true);
            },
        },
        // returning to on given location via forward navigation - PART II (after clearing, new data has arrived), spinner OFF
        {
            predicate: () => !isListPristineEmpty
                && areInitialQueryParamsSet
                && doesListRequiresCleanup
                && !listData?.isLoadingList, // XXX important
            handler: () => {
                setDoesListRequiresCleanup(false);
                setIsListReadyForDisplay(true);
            },
        },
        // default fallback
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];


    useEffect(() => {
        if (hasValidLocation) onLocationChangeHandlers.filter(({ predicate }) => predicate())[0].handler();
    }, [enhancedCurrentLocation]);

    useEffect(() => {
        if (hasValidLocation) onListDataChangeHandlers.filter(({ predicate }) => predicate())[0].handler();
    }, [listData]);

    useEffect(() => {
        if (shouldUpdateList && !listData.isLoadingList) {
            locationHandlers.handleExistingDataOnNavForward();
            dispatchMarkAsUpdatedList({ locationPathname: currentPathname });
        }
    }, [shouldUpdateList]);

    return isListReadyForDisplay;
}

export default useStandardListCachingLogic;
