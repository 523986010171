import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

import StandardTag from './StandardTag.layout';
import { StandardTagOwnProps } from './StandardTag.types';


const decorate = compose<ComponentType<StandardTagOwnProps>>(
    withTranslation('common'),
);

export default decorate(StandardTag);
