import { Button, HStack } from '@chakra-ui/react';
import { ConsentScope, ConsentStatus, Uuid } from '@manigo/manigo-domain-typings';
import React, { JSX } from 'react';
import { MdSend } from 'react-icons/md';

import { LanguageDirection } from 'models/app/i18n';

import { useLanguageDirection } from 'hook/useLanguageDirection';

import StandardTooltip from 'components/common/atoms/StandardTooltip';
import TheIcon from 'components/common/atoms/TheIcon';

import { NewConsentButtonsProps } from './NewConsentButtons.types';


function NewConsentButtons({
    t,
    approvedAccounts,
    newConsentUuid,
    extUserUuid,
    isLoadingChangeConsentStatus,
    dispatchChangeConsentStatus,
    newConsentStatus,
    consentDetails,
}: NewConsentButtonsProps): JSX.Element {
    const languageDirection = useLanguageDirection();
    const reverseIcons = languageDirection === LanguageDirection.rtl;

    const onSubmit = (status: ConsentStatus) => {
        dispatchChangeConsentStatus({
            consentUuid: newConsentUuid as Uuid,
            accounts: approvedAccounts,
            extUserUuid: `${extUserUuid}`,
            status,
        });
    };

    const rightIcon = reverseIcons ? <TheIcon Icon={MdSend} /> : undefined;
    const leftIcon = reverseIcons ? undefined : <TheIcon Icon={MdSend} />;

    return (
        <div>
            <HStack justify={'flex-end'}>
                <Button
                    type="submit"
                    isDisabled={isLoadingChangeConsentStatus}
                    isLoading={isLoadingChangeConsentStatus && newConsentStatus === ConsentStatus.AuthorisationDeclined}
                    rightIcon={rightIcon}
                    leftIcon={leftIcon}
                    data-test-id={'approve-button'}
                    onClick={() => onSubmit(ConsentStatus.AuthorisationDeclined)}
                >
                    {t('consent:buttons.reject.text')}
                </Button>

                <StandardTooltip
                    title={t(`consent:buttons.approve.${consentDetails?.scope === ConsentScope.accounts ? 'disabledTooltip' : 'disabledTooltipPaymentsScope'}`)}
                    isDisabled={approvedAccounts?.length !== 0}
                >
                    <Button
                        type="button"
                        isDisabled={isLoadingChangeConsentStatus || approvedAccounts?.length === 0}
                        isLoading={isLoadingChangeConsentStatus && newConsentStatus === ConsentStatus.Authorised}
                        rightIcon={rightIcon}
                        leftIcon={leftIcon}
                        data-test-id={'approve-button'}
                        onClick={() => onSubmit(ConsentStatus.Authorised)}
                    >
                        {t('consent:buttons.approve.text')}
                    </Button>
                </StandardTooltip>
            </HStack>
        </div>
    );
}

export default NewConsentButtons;
