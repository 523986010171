import React from 'react';
import Highlighter from 'react-highlight-words';

import Styled from './TextWithHighlightedSearch.styled';
import { TextWithHighlightedSearchProps } from './TextWithHighlightedSearch.types';


function TextWithHighlightedSearch({
    record,
    key = '',
    filterValue,
    rawValue,
}: TextWithHighlightedSearchProps) {
    const value = rawValue || record?.[key] || '-';

    return (
        <Styled.TextWithHighlightedSearchWrapper title={value}>
            {filterValue && filterValue.length
                ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[filterValue]}
                        autoEscape
                        textToHighlight={value ? value.toString() : ''}
                        title={value}
                    />
                )
                : value }
        </Styled.TextWithHighlightedSearchWrapper>
    );
}


export default TextWithHighlightedSearch;

