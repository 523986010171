import { Box, Heading, HStack } from '@chakra-ui/react';
import { TFunction, isUndefined } from '@manigo/manigo-commons';
import React, { useMemo } from 'react';

import { QueryParams } from 'models/app/common';
import { StandardFiltersConfiguration } from 'models/app/standardFilters';

import Styled from './NoResultsPlaceholder.styled';


function NoResultsPlaceholder({
    t,
    queryParams,
    filtersConfiguration,
}: {
    t: TFunction,
    queryParams: QueryParams,
    filtersConfiguration: StandardFiltersConfiguration,
}) {
    const isSearchActive = useMemo(
        () => queryParams?.search && queryParams?.search?.length > 0,
        [queryParams],
    );

    const filterNames = useMemo(
        () => (filtersConfiguration?.filtersConfig || []).map((filter) => filter.queryParamName),
        [filtersConfiguration],
    );

    const activeFilterNames = useMemo(
        () => (filterNames || []).filter((filterName) => !(isUndefined(queryParams[filterName])) ? filterName : false),
        [filtersConfiguration],
    );

    const hasActiveFilters = useMemo(
        () => activeFilterNames.length > 0 || isSearchActive,
        [activeFilterNames],
    );

    const getTitle = () => hasActiveFilters
        ? (filtersConfiguration.filterActiveNoDataConfig?.title || t('common:noResultsMatchingSelectedFilters'))
        : (filtersConfiguration.filterInactiveNoDataConfig?.title || t('common:noResults'));

    const getChildren = () => hasActiveFilters ? filtersConfiguration.filterActiveNoDataConfig?.children : filtersConfiguration.filterInactiveNoDataConfig?.children;

    return (
        <Styled.NoResultsPlaceholderWrapper data-test-id="data-list-table-no-results-placeholder">
            <div>
                <Heading paddingBottom={4} size={'lg'}>
                    {getTitle()}
                </Heading>

                <HStack justifyContent={'center'}>
                    <Box w={'min-content'}>
                        {getChildren()}
                    </Box>

                </HStack>
            </div>
        </Styled.NoResultsPlaceholderWrapper>
    );
}


export default NoResultsPlaceholder;
