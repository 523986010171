import styled from 'styled-components';


const ConsentRequestErrorsWrapper = styled.div`
    height: 100%;
    padding: 20px;
    margin: 0 auto;
    width: max-content;
    max-width: 100%;

    display: grid;
    grid-column-gap: 60px;
    align-items: center;


    @media (min-width: 768px) {
        grid-template-columns: 200px minmax(200px, 500px);
        grid-column-gap: 60px;
    }
`;

const ConsentRequestErrorsCopyWrapper = styled.div`
    font-size: 1rem;
    margin-top: 2rem;
    text-align: center;

    @media (min-width: 768px) {
        margin-top: 0;
        font-size: 1.5rem;
        text-align: initial;
        font-weight: 600;
    }
    
`;


export default { ConsentRequestErrorsWrapper, ConsentRequestErrorsCopyWrapper };
