import { CurrencyListItem } from '@manigo/manigo-domain-typings';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'store/currencies/reducer';
import { getStandardListData } from 'store/standardLists.helpers';

import CurrencyRenderer from './CurrencyRenderer.layout';
import { CurrencyRendererOwnProps } from './CurrencyRenderer.types';


const mapStateToProps = (state) => ({
    currenciesList: getStandardListData<CurrencyListItem>({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
});

const decorate = compose<ComponentType<CurrencyRendererOwnProps>>(
    withTranslation('currencies'),
    connect(mapStateToProps),
);

export default decorate(CurrencyRenderer);
