import styled from '@emotion/styled';


const DescriptionWrapper = styled.div<{ $direction?:string, $gutterBottom:boolean }>`
    display: grid;
    grid-template-columns: ${({ $direction }) => $direction === 'row' ? 'minmax(0, 1fr) minmax(0, 1fr)' : 'minmax(0, 1fr)'};
    grid-template-rows: auto;
    grid-template-areas: "label value";
    grid-gap: 8px;
    align-items: center;
    margin-bottom: ${({ $gutterBottom }) => $gutterBottom ? '16px' : '0'};
    > div:first-of-type {
      color: var(--chakra-colors-gray-500);
    }
`;

export { DescriptionWrapper };
