import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { showConfirmation, ShowConfirmationPayload } from 'store/alert/actions';
import { clearCurrentUser } from 'store/current-user/actions';
import { currentUserReducerName } from 'store/current-user/reducer';
import { showModal } from 'store/modal/actions';
import { RootState } from 'store/rootReducer';

import UserDropdown from './UserDropdown.layout';
import { UserDropdownDispatchProps, UserDropdownStateProps } from './UserDropdown.types';


const mapStateToProps = (state: RootState): UserDropdownStateProps => ({
    enhancedCurrentLocation: state.navigation.currentLocation,
    fullName: state[currentUserReducerName].userData?.user?.fullName,
    firstName: state[currentUserReducerName].userData?.user?.firstName,
    lastName: state[currentUserReducerName].userData?.user?.lastName,
});

const mapDispatchToProps = (dispatch: Dispatch): UserDropdownDispatchProps => ({
    dispatchClearCurrentUser: () => dispatch(clearCurrentUser()),
    dispatchShowConfirmation: (payload: ShowConfirmationPayload) => dispatch(showConfirmation(payload)),
    dispatchShowModal: (payload) => dispatch(showModal(payload)),
});

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(UserDropdown);
