import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import withAccessControl from 'hoc/withAccessControl';

import { currentUserReducerName } from 'store/current-user/reducer';
import { changeConsentStatus } from 'store/new-consent/actions';
import { newConsentReducerName } from 'store/new-consent/reducer';
import { RootState } from 'store/rootReducer';

import NewConsentButtons from './NewConsentButtons.layout';
import { NewConsentButtonsDispatchProps, NewConsentButtonsOwnProps, NewConsentButtonsStateProps } from './NewConsentButtons.types';


const mapStateToProps = (state: RootState): NewConsentButtonsStateProps => ({
    extUserUuid: state[currentUserReducerName]?.userData?.identifiers?.extUserUuid,
    newConsentUuid: state[newConsentReducerName].newConsentUuid,
    isLoadingChangeConsentStatus: state[newConsentReducerName].isLoadingChangeConsentStatus,
    newConsentStatus: state[newConsentReducerName].newConsentStatus,
});

const mapDispatchToProps = (dispatch: Dispatch): NewConsentButtonsDispatchProps => ({
    dispatchChangeConsentStatus: (requestPayload) => dispatch(changeConsentStatus(requestPayload)),
    //
});


const decorate = compose<ComponentType<NewConsentButtonsOwnProps>>(
    withTranslation('consent'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(NewConsentButtons);
