import React from 'react';
import { Trans } from 'react-i18next';


import StandardLink from 'components/common/atoms/StandardLink';
import {
    TranslationInlineWrap,

    Anchor,
    Code,
    Emphasis,
    ListItem,
    OrderedList,
    Paragraph,
    Quotation,
    Underline,
    UnorderedList,
} from 'components/common/atoms/StandardTranslationWithMarkup/StandardTranslationWithMarkup.styled';

import { StandardTranslationWithMarkupProps } from './StandardTranslationWithMarkup.types';


const defaultComponents = (values): Record<string, React.ReactElement> => ({
    p: <Paragraph />,
    div: <Paragraph />,
    strong: <Emphasis />,
    u: <Underline />,
    q: <Quotation />,
    code: <Code />,
    ol: <OrderedList />,
    ul: <UnorderedList />,
    li: <ListItem />,
    a: <Anchor target="_blank" rel="noopener" />,
    ref: (
        <StandardLink
            title={''}
            showTooltip={false}
            onClick={values?.onClick}
            path={values?.path}
        />
    ),
});

function StandardTranslationWithMarkup({
    translationKey,
    components,
    values,
    namespace,
    t,
    parent,
}: StandardTranslationWithMarkupProps) {
    return (
        <TranslationInlineWrap>
            <Trans
                i18nKey={translationKey}
                ns={namespace}
                t={t}
                values={{ ...values }}
                components={{ ...defaultComponents(values), ...components }}
                parent={parent}
            />
        </TranslationInlineWrap>
    );
}


export default StandardTranslationWithMarkup;
