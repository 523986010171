import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import withAccessControl from 'hoc/withAccessControl';

import { newConsentHasExpired } from 'store/new-consent/actions';

import NewConsentHeading from './NewConsentHeading.layout';
import { NewConsentHeadingDispatchProps, NewConsentHeadingOwnProps } from './NewConsentHeading.types';


const mapDispatchToProps = (dispatch: Dispatch): NewConsentHeadingDispatchProps => ({
    dispatchNewConsentHasExpired: () => dispatch(newConsentHasExpired()),
    //
});


const decorate = compose<ComponentType<NewConsentHeadingOwnProps>>(
    withTranslation('consent'),
    withAccessControl,
    connect(null, mapDispatchToProps),
);

export default decorate(NewConsentHeading);
