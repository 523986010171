import React from 'react';

import { useLanguageDirection } from 'hook/useLanguageDirection';

import StandardTooltip from 'components/common/atoms/StandardTooltip';
import CountryOrCurrencyFlag from 'components/common/molecules/CountryOrCurrencyFlag';
import StandardValue from 'components/common/molecules/StandardValue';

import Styled from './FlagAndValue.styled';
import { FlagAndValueProps } from './FlagAndValue.types';


function FlagAndValue({
    currencyCode,
    countryCode,
    size = 1.2,
    text = '-',
    valueFirst = false,
    isTextEllipsisDisabled = false,
    useCountryISOAlpha3Code = false,
    showTooltip,
    tooltipPlacement,
    showCopyToClipboard,
    color,
    dataTestId,
}: FlagAndValueProps) {
    const languageDirection = useLanguageDirection();

    const flag = (
        <CountryOrCurrencyFlag
            currencyCode={currencyCode}
            countryCode={countryCode}
            useCountryISOAlpha3Code={useCountryISOAlpha3Code}
            size={size}
        />
    );

    const flagAndValue = (
        <Styled.FlagAndValueWrapper
            $direction={languageDirection}
            $valueFirst={valueFirst}
            className={'flagAndValue'}
        >
            {valueFirst ? (
                <>
                    <StandardValue value={text} showTooltip={showTooltip} color={color} disableTextEllipsis={isTextEllipsisDisabled} dataTestId={dataTestId} />
                    {flag}
                </>
            ) : (
                <>
                    {flag}
                    <StandardValue value={text} showTooltip={showTooltip} color={color} disableTextEllipsis={isTextEllipsisDisabled} dataTestId={dataTestId} />
                </>
            )}
        </Styled.FlagAndValueWrapper>
    );

    return showTooltip ? (
        <StandardTooltip
            title={text}
            showCopyToClipboard={showCopyToClipboard}
            isTextEllipsisDisabled={isTextEllipsisDisabled}
            placement={tooltipPlacement}
        >
            {flagAndValue}
        </StandardTooltip>
    ) : flagAndValue;


}


export default FlagAndValue;
