import { Image } from '@chakra-ui/react';
import React from 'react';

import { WL_CONFIG } from 'config/environment';

import Styled from './AppLogo.styled';


function AppLogo() {

    return (
        <Styled.AuthorisedLogoWrapper>
            {WL_CONFIG?.brandSignetLogoUrl
                ? (
                    <Image
                        src={WL_CONFIG?.brandSignetLogoUrl}
                        boxSize="40px"
                        objectFit="contain"
                        alt={`${WL_CONFIG?.brandFullName}`}
                    />
                )
                : <></>}
            <div>
                <h2>{WL_CONFIG.brandShortName}</h2>
                <h3>{WL_CONFIG.applicationName}</h3>
            </div>
        </Styled.AuthorisedLogoWrapper>
    );
}

export default AppLogo;
