import styled from 'styled-components';


const AuthorisedLayoutOuterWrapper = styled.div`
  max-height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
`;

const AuthorisedLayoutOuterContentWrapper = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  position: relative;
  overflow: hidden;
`;


const AuthorisedLayoutRouteContentWrapper = styled.div`
  padding: 0;
  position: relative;
  min-width: 100%;

`;


export default {
    AuthorisedLayoutOuterWrapper,
    AuthorisedLayoutOuterContentWrapper,
    AuthorisedLayoutRouteContentWrapper,
};
