import { useEffect, useRef } from 'react';

/**
 * @description Runs a side effect only once when a given condition is met.
 */
const useEffectOnce = (
    callback: () => void,
    condition: boolean,
    dependencies: any[],
): void => {
    const isSideEffectHandled = useRef(false);
    useEffect(() => {
        if (!isSideEffectHandled.current && condition) {
            callback();
            isSideEffectHandled.current = true;
        }
    }, [...dependencies]);
};

export default useEffectOnce;
