import styled, { css } from 'styled-components';


const ScrollableContainer = styled.div<{
    $scrollCSS?: ReturnType<typeof css>;
}>`
  ${({ $scrollCSS }) => $scrollCSS};
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  position: relative;
  
  @media (min-width: 768px) {
    padding-right: 8px;
  }

  ::-webkit-scrollbar {
    width: .4rem;
    height: .6rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }

  ::-webkit-scrollbar-track {
    border-radius: .6rem;
  }
`;


const ScrollableContainerInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export default { ScrollableContainer, ScrollableContainerInnerWrapper };
