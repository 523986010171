import { Box, Skeleton } from '@chakra-ui/react';
import React from 'react';

import * as Styled from './Description.styled';
import { DescriptionProps } from './Description.types';


const Description = ({
    label,
    value,
    direction = 'row',
    isLoading,
    gutterBottom = true,
    style,
}:DescriptionProps) => {
    return (
        <Styled.DescriptionWrapper $direction={direction} $gutterBottom={gutterBottom} style={style}>
            {isLoading && (
                <>
                    <Skeleton height="20px" width="100px" />
                    <Skeleton height="20px" width="100px" />
                </>
            )}
            {!isLoading && (
                <>
                    <Box>{`${label}:`}</Box>
                    <Box>{value}</Box>
                </>
            )}
        </Styled.DescriptionWrapper>
    );
};

export default Description;
