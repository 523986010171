import { Box } from '@chakra-ui/react';
import styled from 'styled-components';


const StandardContentWrapper = styled(Box)`
  padding: 15px 5px;
  height: 100%;
`;


export default { StandardContentWrapper };
