import { Container } from '@chakra-ui/react';
import { formatDate } from '@manigo/manigo-commons';
import React from 'react';

import { standardDateAndTimeFormat } from 'config/config';
import { APP_BUILD_DATE, APP_VERSION } from 'config/environment';

import StandardTooltip from '../StandardTooltip';


function AppVersionInfo({ t, ...boxProps }) {
    return (
        <Container {...boxProps}>
            <StandardTooltip
                title={t('unauthorised:appReleaseDate', { dateTime: formatDate({ date: APP_BUILD_DATE, dateFormat: standardDateAndTimeFormat }) })}
            >
                <strong>{`v${APP_VERSION}`}</strong>
            </StandardTooltip>
        </Container>
    );

}


export default AppVersionInfo;
