import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { authorisationReducerName } from 'store/authorisation/reducer';
import { localesReducerName } from 'store/locales/reducer';
import { RootState } from 'store/rootReducer';


import AuthorisedLayout from './AuthorisedLayout.layout';
import { AuthorisedLayoutOwnProps, AuthorisedLayoutStateProps } from './AuthorisedLayout.types';


const mapStateToProps = (state: RootState): AuthorisedLayoutStateProps => ({
    enhancedCurrentLocation: state.navigation.currentLocation,
    isLoadingLanguage: state[localesReducerName].isLoadingLanguage,
    isRefreshingSession: state[authorisationReducerName].isRefreshingSession,
});

const decorate = compose<ComponentType<AuthorisedLayoutOwnProps>>(
    connect(mapStateToProps),
);

export default decorate(AuthorisedLayout);
