export const defaultClipboardContent = '';
export const tabKeySubTabKeySeparator = '🖤';

export enum ApplicationState {
    APPLICATION_STARTED = 'APPLICATION_STARTED',
    UNAUTHORISED_READY = 'UNAUTHORISED_READY',
    AUTHORISED_NEW_CONSENT = 'AUTHORISED_NEW_CONSENT',
    AUTHORISED_READY = 'AUTHORISED_READY',
}

export enum HTMLButtonType {
    button = 'button',
    submit = 'submit',
    reset = 'reset',
}


export enum SortDirections {
    DESCEND = 'desc',
    ASCEND = 'asc',
}


export const DefaultSortByValue = 'id';

export enum SortDirectionsAPI {
    DESC = 'desc',
    ASC = 'asc',
}

export const emptyString = '';

export enum DetailsScreensNames {
    ConsentDetails = 'ConsentDetails',
    myProfile = 'myProfile',
}


export enum StandardFilterTypes {
    SEARCH = 'search',
    DROPDOWN = 'dropdown',
    CHECKBOX = 'checkbox',
}

export enum ReasonsForBlockedNavigation {
    NOT_PRISTINE_ORDER_CARD_FORM = 'NOT_PRISTINE_APPROVE_CONSENT_FORM',
}

export type BlockedNavigationReason = typeof ReasonsForBlockedNavigation;

export interface DefaultQueryParamsMeta {
    location?: Location,
    timestamp?: number,
}


export const defaultQueryParamsMeta: DefaultQueryParamsMeta = {
    location: undefined,
    timestamp: undefined,
};

export function updateQueryParamsMeta(
    clearTimestamp = false,
    clearLocation = false,
): DefaultQueryParamsMeta {
    return {
        location: clearLocation ? undefined : window.location,
        timestamp: clearTimestamp ? undefined : Date.now(),
    };
}

export function normaliseSortOrderValue(
    sortOrder = '',
) {
    return sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC;
}


export enum AllEntityTypes {
    ACCOUNT = 'ACCOUNT',
    BUSINESS_USER = 'BUSINESS_USER',
    MEMBER = 'MEMBER',
    CONSENT = 'CONSENT',
    CLIENT = 'CLIENT',
    COMPANY = 'COMPANY',
    PERMISSION = 'PERMISSION',
}


export default {
    ApplicationState,
    StandardFilterTypes,
    SortDirections,
    DetailsScreensNames,
    ReasonsForBlockedNavigation,
    defaultQueryParamsMeta,
    defaultClipboardContent,
    tabKeySubTabKeySeparator,
};
