import React, { useMemo } from 'react';

import FlagAndValue from 'components/common/molecules/FlagAndValue';

import { CurrencyRendererProps } from './CurrencyRenderer.types';


function CurrencyRenderer({
    t,

    currenciesList,

    currencyIsoCode,
    showFullName = true,
    showTooltip,
    isTextEllipsisDisabled,
    valueFirst,
    color,
    dataTestId,
}: CurrencyRendererProps) {
    const currencyData = useMemo(
        () => {
            if (currenciesList && currenciesList?.items?.length > 0 && showFullName) {
                return currenciesList.items.find((currency) => currency?.short === currencyIsoCode);
            }

            return undefined;
        },
        [currenciesList?.items?.length, currencyIsoCode, showFullName],
    );


    return (
        <FlagAndValue
            color={color}
            valueFirst={valueFirst}
            currencyCode={currencyIsoCode}
            text={showFullName && currencyData ? t(`currencies:currencies.${currencyIsoCode}`) : currencyIsoCode}
            isTextEllipsisDisabled={isTextEllipsisDisabled}
            showTooltip={showTooltip}
            dataTestId={dataTestId}
        />
    );
}

export default CurrencyRenderer;
