import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { currentUserReducerName } from 'store/current-user/reducer';
import { RootState } from 'store/rootReducer';

import StandardNumberInput from './StandardNumberInput.layout';
import { StandardNumberInputOwnProps, StandardNumberInputStateProps } from './StandardNumberInput.types';


const mapStateToProps = (state: RootState):StandardNumberInputStateProps => ({ userPreferences: state[currentUserReducerName].userPreferences });

const decorate = compose<ComponentType<StandardNumberInputOwnProps>>(
    connect(mapStateToProps),
    withTranslation('common'),
);

export default decorate(StandardNumberInput);
