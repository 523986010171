import React from 'react';

import StandardAccordion from 'components/common/atoms/StandardAccordion';

import { GridContainer, GridItemContainer, renderColumn } from './StandardDataListAccordion.helpers';
import { StandardDataListAccordionProps } from './StandardDataListAccordion.types';
import Styled from './StandardDataListTableAccordion.styled';


function StandardDataListAccordion<ItemDefinition>({
    columnsConfiguration,
    items = [],
}: StandardDataListAccordionProps<ItemDefinition>) {

    const renderTabLabel = (item: ItemDefinition) => {
        return (
            <GridContainer>
                {columnsConfiguration.default.map((column) => {
                    return (
                        <GridItemContainer gridItemConfig={column.gridItemConfig} key={column.dataIndex}>
                            <Styled.StandardDataListAccordionHeaderItem
                                key={column.dataIndex}
                            >
                                {column.title && (
                                    <Styled.StandardDataListAccordionHeaderItemTitle
                                        $columnAlign={column.align}
                                    >
                                        {column.title}
                                    </Styled.StandardDataListAccordionHeaderItemTitle>
                                )}
                                <Styled.StandardDataListAccordionHeaderItemValue $columnAlign={column.align}>
                                    {renderColumn(column, item)}
                                </Styled.StandardDataListAccordionHeaderItemValue>
                            </Styled.StandardDataListAccordionHeaderItem>
                        </GridItemContainer>
                    );
                })}
            </GridContainer>
        );
    };

    const renderTabContent = (item: ItemDefinition) => {
        return (
            <GridContainer>
                {columnsConfiguration?.expanded?.map((column) => {
                    const { gridItemConfig } = column;
                    return (
                        <GridItemContainer gridItemConfig={gridItemConfig} key={column.dataIndex}>
                            <Styled.StandardDataListAccordionContentItem>
                                {column.title && (
                                    <Styled.StandardDataListAccordionContentItemTitle>
                                        {`${column.title}: `}
                                    </Styled.StandardDataListAccordionContentItemTitle>
                                )}
                                <Styled.StandardDataListAccordionContentItemValue $columnAlign={column.align}>
                                    {renderColumn(column, item)}
                                </Styled.StandardDataListAccordionContentItemValue>
                            </Styled.StandardDataListAccordionContentItem>
                        </GridItemContainer>
                    );
                })}
            </GridContainer>
        );
    };
    return (
        <Styled.StandardDataListAccordion>
            <StandardAccordion
                itemsConfig={items?.map((item) => {
                    return {
                        id: item[columnsConfiguration.idName],
                        tabLabel: renderTabLabel(item),
                        children: renderTabContent(item),
                    };
                })}
                allowToggle
                allowMultiple={false}
            />
        </Styled.StandardDataListAccordion>
    );
}

export default StandardDataListAccordion;
