import { ConsentListItem } from '@manigo/manigo-domain-typings';
import React, { JSX } from 'react';
import { MdOutlineFilterAltOff } from 'react-icons/md';

import { FilterInputType } from 'models/app/standardFilters';


import { consentsListRootRoutePath } from 'config/routes';

import useStandardListCachingLogic from 'hook/useStandardListCachingLogic';

import { consentsListQueryParams, consentsReducerName } from 'store/consents/reducer';

import StandardButton from 'components/common/atoms/Button';
import StandardDataList from 'components/common/organisms/StandardDataList';
import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';

import { ConsentsListPageProps } from './ConsentsListPage.types';
import { createConsentsListTableColumns } from './tableColumns';


function ConsentsListPage({
    t,
    accessControl,
    accounts,
    isLoadingRevokeConsent,
    enhancedCurrentLocation,
    listData,
    listWatchers,
    dispatchFetchBeneficiariesList,
    dispatchClearBeneficiariesList,
    dispatchSetQueryParams,
    dispatchMarkAsUpdatedList,
    dispatchRevokeConsent,
    dispatchShowConfirmation,
}: ConsentsListPageProps): JSX.Element {
    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams({ queryParams: prams, locationPathname: enhancedCurrentLocation.pathname });
            dispatchFetchBeneficiariesList({ queryParams: prams, locationPathname: enhancedCurrentLocation.pathname });
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic<any>({
        assignedLocationPathname: consentsListRootRoutePath,
        defaultItemsListQueryParams: consentsListQueryParams,
        reducerName: consentsReducerName,

        enhancedCurrentLocation,
        listData,
        listWatchers,

        dispatchFetchItemsList: dispatchFetchBeneficiariesList,
        dispatchClearItemsList: dispatchClearBeneficiariesList,
        dispatchSetQueryParams,
        dispatchMarkAsUpdatedList,
    });

    return (
        <StandardAuthorisedPage pageTitle={t('consent:consentsListPage.screenTitle')}>
            <StandardDataList<ConsentListItem>
                t={t}
                fetchData={fetchData}
                totalCount={listData.totalCount}
                items={listData.items}
                isLoadingData={listData.isLoadingList}
                queryParams={listData.queryParams}
                isListInitialStateDefined={isListInitialStateDefined}
                columnsConfiguration={createConsentsListTableColumns({
                    t,
                    accessControl,
                    accounts,
                    isLoadingRevokeConsent,
                    dispatchRevokeConsent,
                    dispatchShowConfirmation,
                    enhancedCurrentLocation,
                })}
                filtersConfiguration={{
                    filterActiveNoDataConfig: {
                        title: t(`consent:consentsListPage.${listData.queryParams?.fetchAllConsents === true ? 'emptyConsentsLists' : 'emptyActiveConsentsLists'}`),
                        children: listData.queryParams?.fetchAllConsents !== true
                            ? (
                                <StandardButton
                                    id={'reset-filter-to-all'}
                                    dataTestId={'reset-filter-to-all'}
                                    variant={'outline'}
                                    label={t('consent:consentsListPage.buttons.resetFilterToAll.text')}
                                    icon={MdOutlineFilterAltOff}
                                    onClick={() => fetchData({
                                        ...listData.queryParams,
                                        ...{ fetchAllConsents: true },
                                    })}
                                />
                            ) : undefined,
                    },
                    filtersConfig: [
                        {
                            filterInputType: FilterInputType.SWITCH,
                            dataTestId: 'consent-status-filter',
                            queryParamName: 'fetchAllConsents',
                            filterOptions: [
                                {
                                    value: 'all',
                                    label: t('consent:consentsListPage.filters.status.all.text'),
                                },
                            ],
                        },
                    ],
                }}
            />
        </StandardAuthorisedPage>
    );
}


export default ConsentsListPage;
