import { InputLeftElement, InputRightAddon } from '@chakra-ui/react';
import { CurrencyIsoCode } from '@manigo/manigo-domain-typings';
import React from 'react';

import { StandardAmountInputProps } from './AmountInput.types';
import CountryOrCurrencyFlag from '../CountryOrCurrencyFlag';
import NumInput from '../StandardNumberInput';


function StandardAmountInput({ fieldConfiguration }:StandardAmountInputProps) {
    const prefix = fieldConfiguration.optionalConfiguration?.prefix;

    const props = prefix ? {
        inputLeftElement:
            <InputLeftElement>
                <CountryOrCurrencyFlag currencyCode={prefix as CurrencyIsoCode} size={1.2} />
            </InputLeftElement>,
        inputRightElement:
            <InputRightAddon fontSize={'sm'}>{prefix}</InputRightAddon>
        ,
    } : {};
    return (
        <NumInput
            fieldConfiguration={fieldConfiguration}
            {...props}
        />
    );

}
export default StandardAmountInput;
