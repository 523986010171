import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { requestNavigation } from 'store/navigation/actions';

import StandardAuthorisedPage from './StandardAuthorisedPage.layout';
import { StandardAuthorisedPageOwnProps, StandardLinkDispatchProps } from './StandardAuthorisedPage.types';


const mapDispatchToProps = (dispatch: Dispatch): StandardLinkDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    //
});

const decorate = compose<ComponentType<StandardAuthorisedPageOwnProps>>(
    withTranslation('common'),
    connect(null, mapDispatchToProps),
);

export default decorate(StandardAuthorisedPage);
