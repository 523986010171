import { ConsentStatus, Uuid } from '@manigo/manigo-domain-typings';

import { ChangeConsentStatusPayload, UnauthorisedDeclineConsentRequestPayload } from 'store/new-consent/actions.types';

import { changeConsentStatusAPI, declineConsentRequestAPI, fetchConsentDetailsAPI, fetchConsentsAPI, revokeConsentAPI } from './index';


class ConsentsService {
    public fetchConsentDetails(consentUuid: Uuid) {
        return fetchConsentDetailsAPI(consentUuid);
    }

    public fetchConsents(rawQueryParams: {
        status?: ConsentStatus;
    }) {
        const { ...queryParams } = rawQueryParams;
        const amendedQueryParams = { ...(queryParams.status ? { status: queryParams.status } : { }) };
        return fetchConsentsAPI({ params: amendedQueryParams });
    }

    public revokeConsent(consentUuid: Uuid) {
        return revokeConsentAPI(consentUuid);
    }

    public changeConsentStatus(rawRequestPayload: ChangeConsentStatusPayload) {
        const { consentUuid, extUserUuid, status, accounts } = rawRequestPayload;
        // XXX non-standard (3rd party - tell.money) naming conventions
        const requestPayload = {
            Accounts: accounts,
            ConsentId: consentUuid,
            UserId: extUserUuid,
            Outcome: status === ConsentStatus.Authorised ? 'Pass' : 'Fail',
        };

        return changeConsentStatusAPI(requestPayload);
    }

    public declineConsentRequest(rawRequestPayload: UnauthorisedDeclineConsentRequestPayload) {
        const { consentUuid } = rawRequestPayload;

        // XXX non-standard (3rd party - tell.money) naming conventions
        return declineConsentRequestAPI({ ConsentId: consentUuid });
    }

}

export default ConsentsService;
