import styled from 'styled-components';


export const PermissionsContainer = styled.div`
    margin-left: 1rem;
`;

export const PermissionsList = styled.ul`
    columns: 3;
    @media (max-width: 768px) {
        & {
           columns: 2;
        }
    }
    @media (max-width: 576px) {
        & {
            columns: 1;
        }
    }
`;


export default {
    PermissionsContainer,
    PermissionsList,
};
