import {
    Box, Text,
    ButtonGroup, Card, CardBody, CardFooter, Heading, Skeleton, SkeletonText, Stack, CardHeader, GridItem, Grid, Flex,
} from '@chakra-ui/react';
import { getPropObj } from '@manigo/manigo-commons';
import * as React from 'react';
import { Fragment } from 'react';

import Styled from './StandardCardSection.styled';
import { StandardCardSectionOwnProps } from './StandardCardSection.types';


function StandardCardSection<ItemDefinition>({
    columnsConfiguration = [],
    item,
    cardTitle,
    cardSkeletonMode = false,
    gridItemConfig,
}: StandardCardSectionOwnProps<ItemDefinition>) {

    const standardColumns = columnsConfiguration.filter((column) => !column.isAction && !column.isBadge);
    const actions = columnsConfiguration.filter((column) => column.isAction);
    const badges = columnsConfiguration.filter((column) => column.isBadge);

    const renderValue = (column) => {
        const propertyPath = column?.dataIndex;
        const propertyValue = propertyPath && item ? getPropObj(item, propertyPath) : '-';

        return column.render
            ? column.render(propertyValue, item)
            : propertyValue;
    };

    return (
        <GridItem
            colEnd={gridItemConfig?.colEnd}
            colSpan={gridItemConfig?.colSpan ?? 6}
            colStart={gridItemConfig?.colStart}
            rowEnd={gridItemConfig?.rowEnd}
            rowSpan={gridItemConfig?.rowSpan}
            rowStart={gridItemConfig?.rowStart}
            data-test-id="data-list-cards"
        >
            <Styled.CardWrapper>
                <Card
                    size="lg"
                    variant="elevated"
                    boxShadow="lg"
                >
                    {cardTitle ? (
                        <CardHeader>
                            <Flex justifyContent={'space-between'}>
                                {cardSkeletonMode ? (
                                    <Skeleton height="30px" />
                                ) : (
                                    <>
                                        <Heading size="sm">{cardTitle}</Heading>
                                    </>
                                )}
                            </Flex>
                        </CardHeader>
                    ) : (<></>)}


                    <CardBody>
                        <Styled.CardBodyContentWrapper>
                            <Grid
                                templateColumns={{
                                    base: 'repeat(6, minmax(0, 1fr))',
                                    md: 'repeat(12, minmax(0, 1fr))',
                                }}
                                gridColumnGap={{ base: 0, md: 4, lg: 6 }}
                                gridRowGap={{ base: 2, md: 4, lg: 6 }}
                                padding={{ base: 1, md: 2, lg: 3 }}
                            >
                                {standardColumns.map((field, index) => (
                                    <GridItem
                                        key={index}
                                        colEnd={field?.gridItemConfig?.colEnd}
                                        colSpan={field?.gridItemConfig?.colSpan ?? 6}
                                        colStart={field?.gridItemConfig?.colStart}
                                        rowEnd={field?.gridItemConfig?.rowEnd}
                                        rowSpan={field?.gridItemConfig?.rowSpan}
                                        rowStart={field?.gridItemConfig?.rowStart}
                                    >
                                        <Box
                                            px={{ base: '3' }}
                                            py={{ base: '3' }}
                                            bg="bg-surface"
                                            borderRadius="lg"
                                            boxShadow={field?.hideBoxShadow ? undefined : 'sm'}
                                        >
                                            {cardSkeletonMode
                                                ? (
                                                    <Stack>
                                                        <SkeletonText
                                                            noOfLines={1}
                                                            skeletonHeight="10px"
                                                            rounded={'md'}
                                                        />
                                                        <SkeletonText
                                                            noOfLines={1}
                                                            skeletonHeight="20px"
                                                            rounded={'md'}
                                                        />
                                                    </Stack>
                                                ) : (
                                                    <Stack>
                                                        <Text fontSize="sm" color="muted">
                                                            {field?.title}
                                                        </Text>
                                                        <Heading size={{ base: 'sm', md: 'md' }} textAlign={'right'}>
                                                            {renderValue(field)}
                                                        </Heading>
                                                    </Stack>
                                                )}
                                        </Box>
                                    </GridItem>
                                ))}
                            </Grid>
                        </Styled.CardBodyContentWrapper>
                    </CardBody>

                    {actions?.length > 0 || badges?.length > 0
                        ? (
                            <CardFooter>
                                <Styled.CardFooterWrapper>
                                    <Styled.CardFooterBadgesWrapper>
                                        <ButtonGroup spacing="4">
                                            {badges?.map((badge, index) => {
                                                return (
                                                    <Fragment key={`actions-${badge?.dataIndex}-${index}`}>
                                                        {renderValue(badge)}
                                                    </Fragment>
                                                );
                                            })}
                                        </ButtonGroup>
                                    </Styled.CardFooterBadgesWrapper>

                                    <Styled.CardFooterActionsWrapper>
                                        {actions?.map((action, index) => {
                                            return (
                                                <Fragment key={`actions-${action?.dataIndex}-${index}`}>
                                                    {cardSkeletonMode ? (
                                                        <Skeleton
                                                            height="30px"
                                                            width="120px"
                                                            rounded={'md'}
                                                        />
                                                    ) : renderValue(action)}
                                                </Fragment>
                                            );
                                        })}
                                    </Styled.CardFooterActionsWrapper>

                                </Styled.CardFooterWrapper>
                            </CardFooter>
                        )
                        : null}

                </Card>
            </Styled.CardWrapper>
        </GridItem>
    );
}

export default StandardCardSection;
