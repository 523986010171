import { Box, Grid, GridItem, HStack } from '@chakra-ui/react';
import { AccountIdentificationType, CurrencyIsoCode } from '@manigo/manigo-domain-typings';
import React, { JSX, useMemo } from 'react';

import { CommonFormFieldConfigItem, FormInputTypes } from 'models/app/formContols';
import { StandardValueType } from 'models/app/standardValues';

import AccountTypeTag from 'components/common/atoms/StandardTag/AccountTypeTag';
import CountryOrCurrencyFlag from 'components/common/molecules/CountryOrCurrencyFlag';
import FormField from 'components/common/molecules/FormFieldWrapper';
import StandardValue from 'components/common/molecules/StandardValue';

import Styled from './AccountItem.styled';
import { AccountItemProps } from './AccountItem.types';


function AccountItem({
    errors,
    control,
    account,
    t,
}: AccountItemProps): JSX.Element {

    const accountFieldConfiguration = useMemo(
        () => account?.extAccountId
            ? {
                fieldType: FormInputTypes.SWITCH,
                id: `account-${account.extAccountId}`,
                name: `approvedAccounts.${account.extAccountId}`,
                dataTestId: `account-${account.extAccountId}-to`,
                blockLabel: false,
                labelText: t('consent:accountItem.checkboxLabel'),
            } as CommonFormFieldConfigItem
            : undefined,
        [account, t],
    );

    const accountIban = useMemo(
        () => (account?.accountIdentifications || []).find(
            (accountIdentification) => accountIdentification?.identificationType === AccountIdentificationType.IBAN,
        ),
        [account],
    );

    const accountNumber = useMemo(
        () => (account?.accountIdentifications || []).find(
            (accountIdentification) => accountIdentification?.identificationType === AccountIdentificationType.ACCOUNT_NUMBER,
        ),
        [account],
    );

    const attributes = useMemo(
        () => [
            {
                label: t('consent:accountsFields.name.text'),
                renderer: <StandardValue value={`${account?.accountName}`} showCopyToClipboard={false} showTooltip={false} />,
            },
            {
                label: t('consent:accountsFields.balance.text'),
                renderer: (
                    <HStack justifyContent={'flex-start'}>
                        <StandardValue value={account?.availableBalance} valueType={StandardValueType.AMOUNT} />
                        <StandardValue value={account?.currency} valueType={StandardValueType.TEXT} />
                    </HStack>
                ),

            },
            ...(accountIban
                ? [{
                    label: t('consent:accountsFields.iban.text'),
                    renderer: (
                        <StandardValue
                            value={`${accountIban?.identificationValue}`}
                            showCopyToClipboard={false}
                            showTooltip={false}
                            valueType={StandardValueType.IBAN}
                        />
                    ),
                }]
                : []),

            ...(accountNumber
                ? [{
                    label: t('consent:accountsFields.accountNumber.text'),
                    renderer: (
                        <StandardValue
                            value={`${accountNumber?.identificationValue}`}
                            showCopyToClipboard={false}
                            showTooltip={false}
                        />
                    ),
                }]
                : []),
        ],
        [account],
    );


    return accountFieldConfiguration ? (
        <Styled.AccountFormItem className="accountItem">
            <Box
                px={{ base: '3' }}
                py={{ base: '3' }}
                borderRadius="lg"
                boxShadow={'lg'}
                minH={'170px'}
                justifyContent={'space-between'}
                position={'relative'}
            >
                <Styled.CurrencyDecoration>
                    <CountryOrCurrencyFlag currencyCode={account?.currency as CurrencyIsoCode} />
                </Styled.CurrencyDecoration>

                <Styled.AlignedAttributes>
                    <tbody>
                        {attributes.map((attribute) => (
                            <tr key={attribute.label}>
                                <Styled.AttributeName>{`${attribute.label}:`}</Styled.AttributeName>
                                <Styled.AttributeValue>{attribute.renderer}</Styled.AttributeValue>
                            </tr>
                        ))}
                    </tbody>
                </Styled.AlignedAttributes>

                <Grid
                    templateColumns={{ base: 'auto auto' }}
                    gridColumnGap={{ base: 1 }}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <GridItem lineHeight={'normal'}>
                        <AccountTypeTag accountType={account?.type} t={t} />
                    </GridItem>

                    {control ? (
                        <GridItem>
                            <FormField
                                errors={errors}
                                control={control}
                                fieldConfiguration={accountFieldConfiguration}
                            />
                        </GridItem>
                    ) : <></>}
                </Grid>
            </Box>
        </Styled.AccountFormItem>
    ) : <></>;
}

export default AccountItem;
