import { Tooltip } from '@chakra-ui/react';
import styled from 'styled-components';


const StandardTooltipWithOverriddenStyles = styled(Tooltip)`
  outline: 1px solid transparent;
  max-width: 700px;
`;

const CopyTransparentOverlay = styled.div`
  display: inline;
  cursor: pointer;
  color: inherit;
  
`;
const StandardOverlay = styled.div`
  display: inline-block;
  cursor: help !important;
`;

const TooltipCopy2Clipboard = styled.div`
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid white;
  text-align: center;
  font-size: 11px;
`;

const TooltipValue = styled.div`
  text-align: center;
  font-weight: 600;
`;

export default {
    StandardTooltipWithOverriddenStyles,
    CopyTransparentOverlay,
    StandardOverlay,
    TooltipCopy2Clipboard,
    TooltipValue,
};
