import { createStandaloneToast } from '@chakra-ui/react';
import {
    getSessionStorageObjectItem,
    removeSessionStorageObjectItem,
    copyToClipboard,
    clearJwt,
    getJwt,
    isDateInTheFuture,
} from '@manigo/manigo-commons';
import { ofType } from 'redux-observable';
import { combineLatest, EMPTY, map, of, take } from 'rxjs';
import { catchError, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ToastPosition, ToastVariant } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { newConsentKey, permissionsKey, userPreferencesKey } from 'config/config';
import { loginRootRoutePath, rootRoutePath } from 'config/routes';

import {
    applicationReadyAuthorisedUser, applicationReadyAuthorisedUserNewConsent,
    applicationReadyUnauthorisedUser,
    removeJwtTokenFromStorage,
} from 'store/application/actions';
import { createAppInitActionsAndSideEffects, getIcon } from 'store/application/epics.helpers';
import { getTokensForDuplicatedTab, refreshToken } from 'store/authorisation/actions';
import { authorisationReducerName } from 'store/authorisation/reducer';
import { FETCH_CURRENCIES_SUCCESS } from 'store/currencies/actions.types';
import { setCurrentUser } from 'store/current-user/actions';
import { SET_CURRENT_USER_SUCCESS } from 'store/current-user/actions.types';
import SET_CURRENT_USER_VARIANT from 'store/current-user/epics.helpers';
import { INIT_I18NEXT_SUCCESS, LOAD_NAMESPACES_SUCCESS } from 'store/locales/action.types';
import { requestNavigation } from 'store/navigation/actions';
import { setNewConsentMode } from 'store/new-consent/actions';

import {
    APPLICATION_READY_AUTHORISED_USER,
    APPLICATION_READY_UNAUTHORISED_USER,
    GET_JWT_TOKEN_FORM_STORAGE,
    INIT_APPLICATION,
    REMOVE_JWT_TOKEN_FROM_STORAGE,
    SET_CLIPBOARD_CONTENT,
    SET_SESSION_UUID,
    SHOW_TOAST,
} from './action.types';


export const onInitApplication: Epic = (action$, _, { http, history }) => {
    return action$.pipe(
        ofType(INIT_APPLICATION),
        mergeMap(() => {
            return createAppInitActionsAndSideEffects(http, history);
        }),
    );
};


export const onGetJwtTokenFormStorage: Epic = (action$) => {
    return action$.pipe(
        ofType(GET_JWT_TOKEN_FORM_STORAGE),
        switchMap(({ payload }) => {
            const jwtToken = getJwt();
            const permissions = getSessionStorageObjectItem(permissionsKey);
            const userPreferences = getSessionStorageObjectItem(userPreferencesKey);

            // XXX special case - payload is present
            if (payload && jwtToken) {
                return of(getTokensForDuplicatedTab({
                    sessionUuid: payload,
                    accessToken: jwtToken.accessToken,
                },
                { permissions, userPreferences }));
            }

            if (jwtToken && isDateInTheFuture(jwtToken.expirationTimeOfAccessToken)) {
                const newConsentData = getSessionStorageObjectItem(newConsentKey);
                return of(
                    setNewConsentMode(newConsentData),
                    setCurrentUser({ ...jwtToken, permissions, userPreferences }, SET_CURRENT_USER_VARIANT.GET_JWT_FROM_STORAGE),
                );
            }

            if (jwtToken
                && !isDateInTheFuture(jwtToken.expirationTimeOfAccessToken)
                && isDateInTheFuture(jwtToken.expirationTimeOfRefreshToken)
            ) {
                return of(refreshToken(jwtToken));
            }

            if (jwtToken) {
                return of(removeJwtTokenFromStorage());
            }

            return EMPTY;
        }),
    );
};
export const onRemoveJwtTokenFromStorage: Epic = (action$) => {
    return action$.pipe(
        ofType(REMOVE_JWT_TOKEN_FROM_STORAGE),
        tap(
            () => {
                clearJwt();
                removeSessionStorageObjectItem(permissionsKey);
                removeSessionStorageObjectItem(userPreferencesKey);
            },
        ),
        switchMap(() => EMPTY),
    );
};

export const onSetClipboardContent: Epic = (action$) => {
    return action$.pipe(
        ofType(SET_CLIPBOARD_CONTENT),
        tap(({ payload }) => copyToClipboard(payload)),
        switchMap(() => EMPTY),
    );
};

export const onInitApplicationUnauthorisedFlow: Epic = (action$, state$) => combineLatest([
    action$.pipe(ofType(INIT_APPLICATION)),
    action$.pipe(ofType(SET_SESSION_UUID)),
    action$.pipe(ofType(INIT_I18NEXT_SUCCESS)),
]).pipe(
    take(1),
    takeUntil(action$.pipe(ofType(SET_CURRENT_USER_SUCCESS))),
    switchMap(() => {
        const jwtToken = getJwt();
        // XXX user is logged-in -> skip applicationReadyUnauthorisedUser to avoid routes ability changes and possible unwanted redirections
        if ((jwtToken && isDateInTheFuture(jwtToken.expirationTimeOfAccessToken)) || state$.value[authorisationReducerName]?.isRefreshingSession) {
            return EMPTY;
        }
        return of(applicationReadyUnauthorisedUser());
    }),
    catchError(() => EMPTY),
);

export const onInitApplicationAuthorisedFlow: Epic = (action$) =>
    action$.pipe(
        ofType(SET_CURRENT_USER_SUCCESS),
        switchMap(() => combineLatest([
            action$.pipe(ofType(FETCH_CURRENCIES_SUCCESS)),
            action$.pipe(ofType(LOAD_NAMESPACES_SUCCESS)),
        ]).pipe(
            take(1),
            takeUntil(action$.pipe(ofType(APPLICATION_READY_AUTHORISED_USER))),
            map(() => {
                const newConsentData = getSessionStorageObjectItem(newConsentKey);
                if (newConsentData?.newConsentUuid) {
                    return applicationReadyAuthorisedUserNewConsent();
                } else {
                    return applicationReadyAuthorisedUser();
                }
            }),
            catchError(() => EMPTY),
        )),
    );


export const onApplicationReadyUnauthorisedUser: Epic = (action$, state$, { history }) => {
    return action$.pipe(
        ofType(APPLICATION_READY_UNAUTHORISED_USER),
        switchMap(() => {
            const { pathname } = history.location;
            // TODO  parse incoming
            const nextLocation = pathname === rootRoutePath
                ? loginRootRoutePath
                : undefined;

            if (nextLocation) {
                return of(
                    requestNavigation({ locationPathname: nextLocation, meta: { replace: true } }),
                );
            }

            return EMPTY;
        }),
    );
};


export const onShowToast: Epic = (action$) => {
    return action$.pipe(
        ofType(SHOW_TOAST),
        switchMap(({
            payload: {
                id,
                type,
                message,
                description,
            },
        }) => {
            const { toast } = createStandaloneToast();

            toast({
                title: message,
                description,
                status: type,
                variant: ToastVariant.subtle,
                position: ToastPosition.bottomRight,
                duration: 8000,
                isClosable: true,
                id: `${id}-${type}`,
                ...getIcon(type),
            });
            return EMPTY;
        }),
    );
};

export default [
    onInitApplication,
    onInitApplicationUnauthorisedFlow,
    onInitApplicationAuthorisedFlow,
    onApplicationReadyUnauthorisedUser,
    onGetJwtTokenFormStorage,
    onRemoveJwtTokenFromStorage,
    onSetClipboardContent,
    onShowToast,
];
