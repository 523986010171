import React, { JSX } from 'react';

import Alert from 'components/common/molecules/Alert';
import ModalRoot from 'components/common/organisms/ModalRoot';

import AppIdleTimer from './AppIdleTimer';
import Styled from './AuthorisedLayout.styled';
import { AuthorisedLayoutProps } from './AuthorisedLayout.types';
import LoadingLocalesPlaceholder from './LoadingLocalesPlaceholder';
import NavBar from './NavBar';
import AppLoadingPlaceholder from '../../AppLoadingPlaceholder';


function AuthorisedLayout({ isRefreshingSession, children }: AuthorisedLayoutProps): JSX.Element {
    return (
        <>
            <AppIdleTimer>
                {
                    isRefreshingSession && (
                        <AppLoadingPlaceholder asOverlay />
                    )
                }
                <LoadingLocalesPlaceholder>
                    <Styled.AuthorisedLayoutOuterWrapper className="app-height">
                        <NavBar />
                        <Styled.AuthorisedLayoutOuterContentWrapper id="route-wrapper">
                            <Styled.AuthorisedLayoutRouteContentWrapper className="app-height">
                                <Alert />
                                <ModalRoot />

                                {children}
                            </Styled.AuthorisedLayoutRouteContentWrapper>
                        </Styled.AuthorisedLayoutOuterContentWrapper>

                    </Styled.AuthorisedLayoutOuterWrapper>
                </LoadingLocalesPlaceholder>
            </AppIdleTimer>
        </>
    );
}

export default AuthorisedLayout;
