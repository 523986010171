import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { clearAllPasscodeDigits, setPasscodeDigit } from 'store/authorisation/actions';
import { authorisationReducerName } from 'store/authorisation/reducer';
import { RootState } from 'store/rootReducer';

import DigitsCodeInput from './DigitsCodeInput.layout';
import { DigitsCodeInputDispatchProps, DigitsCodeInputOwnProps, DigitsCodeInputStateProps } from './DigitsCodeInput.types';


const mapStateToProps = (state:RootState): DigitsCodeInputStateProps => ({
    loginConfirmationValues: state[authorisationReducerName].loginConfirmationValues,
    loginConfirmFormError: state[authorisationReducerName].loginConfirmFormError,
});

const mapDispatchToProps = (dispatch: Dispatch):DigitsCodeInputDispatchProps => ({
    dispatchSetPasscodeDigit: ({ key, value }) => dispatch(setPasscodeDigit({ key, value })),
    dispatchClearAllPasscodeDigits: () => dispatch(clearAllPasscodeDigits()),
});

const decorate = compose<ComponentType<DigitsCodeInputOwnProps>>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(DigitsCodeInput);
