import {
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react';
import React, { JSX } from 'react';
import { MdFilterAlt } from 'react-icons/md';

import { useDebounce } from 'hook/useDebounce';
import useUpdateEffect from 'hook/useUpdateEffect';

import { getDefaultLightDarkValues } from 'utils/theme-tools';

import StandardAccordion from 'components/common/atoms/StandardAccordion';

import FilterFields from './FilterFields';
import SearchField from './SearchField';
import SorterFields from './SorterFields';
import Styled from './StandardFilters.styled';
import { StandardFiltersProps } from './StandardFilters.types';


function StandardFilters({
    queryParams,
    filtersConfiguration,
    fetchData,
    isListReady,
    t,
}: StandardFiltersProps): JSX.Element {
    const colorScheme = useColorModeValue(...getDefaultLightDarkValues());
    const isDesktop = useBreakpointValue({ base: false, lg: true, md: true });

    const [localQueryParamsObj, setLocalQueryParamsObj] = React.useState(() => [queryParams, null]);
    const debouncedQueryParamsObj = useDebounce(localQueryParamsObj, 500);

    useUpdateEffect(() => {
        const [value, fieldName] = debouncedQueryParamsObj;
        if (!fieldName || !value) return;
        fetchData(value);
    }, [debouncedQueryParamsObj]);

    useUpdateEffect(() => {
        if (JSON.stringify(queryParams) === JSON.stringify(localQueryParamsObj[0])) return;
        setLocalQueryParamsObj([queryParams, null]);
    }, [queryParams]);

    const handleQueryParamsChange = ({ value, fieldName, resetOffset }) => {
        if (!fieldName) return;

        setLocalQueryParamsObj([{
            ...localQueryParamsObj[0],
            ...(resetOffset ? { offset: 0 } : {}),
            [fieldName]: value,
        }, fieldName]);
    };

    const getContent = () => {
        return (
            <Styled.FilterInnerWrapper>
                {
                    filtersConfiguration.searchBy && (
                        <Styled.Col width={{ base: '100%', md: '300px', sm: '100%' }}>
                            <SearchField
                                isListReady={isListReady}
                                filtersConfiguration={filtersConfiguration}
                                handleQueryParamsChange={handleQueryParamsChange}
                                queryParams={localQueryParamsObj[0]}
                                colorScheme={colorScheme}
                                t={t}
                            />
                        </Styled.Col>
                    )
                }

                <Styled.Col marginLeft={'auto'} width={{ base: '100%', md: 'unset', sm: '100%' }}>
                    <SorterFields
                        filtersConfiguration={filtersConfiguration}
                        handleQueryParamsChange={handleQueryParamsChange}
                        queryParams={localQueryParamsObj[0]}
                        isListReady={isListReady}
                        t={t}
                    />

                    <FilterFields
                        filtersConfiguration={filtersConfiguration}
                        queryParams={localQueryParamsObj[0]}
                        isListReady={isListReady}
                        handleQueryParamsChange={handleQueryParamsChange}
                    />
                </Styled.Col>
            </Styled.FilterInnerWrapper>
        );
    };

    return (
        <Styled.FilterOuterWrapper>
            {
                isDesktop || !(filtersConfiguration.searchBy?.length || filtersConfiguration.filtersConfig?.length || filtersConfiguration.filtersConfig?.length) ? getContent() : (
                    <StandardAccordion
                        allowMultiple
                        itemsConfig={[
                            {
                                id: 'filters',
                                tabLabel: t('common:buttons.openFilters.text'),
                                tabIcon: MdFilterAlt,
                                children: getContent(),
                            },
                        ]}
                    />
                )
            }
        </Styled.FilterOuterWrapper>
    );
}

export default StandardFilters;
