import { Text } from '@chakra-ui/react';
import React from 'react';
import { MdPerson } from 'react-icons/md';

import TheIcon from 'components/common/atoms/TheIcon';
import StandardValue from 'components/common/molecules/StandardValue';

import Styled from './UserProfile.styled';
import { UserProfileProps } from './UserProfile.types';


const UserProfile = ({ name }: UserProfileProps) => {

    return (
        <div>
            <Styled.UserProfileItem>
                <TheIcon Icon={MdPerson} />
                <Text color="on-accent" fontWeight="medium">
                    <StandardValue value={`${name}`} />
                </Text>
            </Styled.UserProfileItem>
        </div>
    );
};
export default UserProfile;
