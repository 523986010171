import styled from 'styled-components';


const StandardDataListFooterWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: right;
  grid-template-columns: auto minmax(0, 1fr);
  grid-column-gap: 20px;
  padding: 10px 10px 0 10px;
  margin-top: 5px;
  box-shadow: 0 -5px 5px -5px #333;
`;


const StandardDataListFooterRefreshWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: left;
`;


export default { StandardDataListFooterWrapper, StandardDataListFooterRefreshWrapper };
