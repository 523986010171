import styled from '@emotion/styled';


export const CountdownWrapper = styled.div`
    p {
        padding-left: 1ch;
        display: inline;
    }
`;
export default { CountdownWrapper };
