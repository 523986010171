import { Box } from '@chakra-ui/react';
import styled from 'styled-components';


const StandardValueWrapper = styled(Box)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  
  &.amount-type-value {
    font-family: Menlo, monospace;
    text-overflow: unset;
    overflow: unset;
  }
`;


export default { StandardValueWrapper };

