import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import withAccessControl from 'hoc/withAccessControl';

import { requestNavigation } from 'store/navigation/actions';
import { newConsentReducerName } from 'store/new-consent/reducer';
import { RootState } from 'store/rootReducer';

import ConsentRequestErrors from './ConsentRequestErrors.layout';
import {
    ConsentRequestErrorsDispatchProps,
    ConsentRequestErrorsOwnProps,
    ConsentRequestErrorsStateProps,
} from './ConsentRequestErrors.types';


const mapStateToProps = (state: RootState): ConsentRequestErrorsStateProps => ({
    redirectionUrl: state[newConsentReducerName].newConsentCallbackUrl,
    //
});

const mapDispatchToProps = (dispatch: Dispatch): ConsentRequestErrorsDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    //
});


const decorate = compose<ComponentType<ConsentRequestErrorsOwnProps>>(
    withTranslation('consent'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ConsentRequestErrors);
