import styled, { css } from 'styled-components';

import { LanguageDirection } from 'models/app/i18n';


const FlagAndValueWrapper = styled.span<{ $valueFirst:boolean, $direction: LanguageDirection }>`
  display: inline-grid;
  width: auto;
  max-width: 100%;
  grid-template-columns: auto minmax(max-content, 1fr);
  grid-column-gap: 1ch;
  align-items: center;
  direction:  ${({ $direction }) => $direction};
  
  ${({ $valueFirst = false }) => $valueFirst && css`
    grid-template-columns: minmax(max-content, 1fr) auto;
  `};
`;


export default { FlagAndValueWrapper };
