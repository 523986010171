import React, { useMemo, JSX } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import StandardAccordion from 'components/common/atoms/StandardAccordion';
import StandardTranslationWithMarkup from 'components/common/atoms/StandardTranslationWithMarkup';


function FAQ({ t, tReady }: WithTranslation): JSX.Element {
    const sections = useMemo(
        () => {
            return tReady
                ? t('faq:sections', { returnObjects: true }) as []
                : undefined;
        },
        [t, tReady],
    );

    const itemConfig = sections?.map((section, index) => ({
        id: `${index}`,
        tabLabel: t(`faq:sections.${index}.heading`),
        children: <StandardTranslationWithMarkup
            translationKey={`sections.${index}.content`}
            namespace="faq"
            t={t}
        />,
    }));
    return (
        <>
            {itemConfig && <StandardAccordion itemsConfig={itemConfig} allowMultiple />}
        </>
    );
}


export default withTranslation()(FAQ);
