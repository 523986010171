export const rootRoutePath = '/';
export const loginRootRoutePath = '/log-in';
export const passcodeConfirmRootRoutePath = '/confirm';
export const consentHasExpiredRootRoutePath = '/consent-has-expired';
export const consentNotFoundRootRoutePath = '/consent-not-found';


export const attributionsRoutePath = '/attributions';

export const newConsentRootRoutePath = '/new-consent';
export const approveNewConsentRootRoutePath = '/approve-consent';
export const consentsListRootRoutePath = '/consents';

export const publicRoutes = [
    rootRoutePath,
    loginRootRoutePath,
    passcodeConfirmRootRoutePath,
];

