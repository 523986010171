import styled from 'styled-components';


const DigitsCodeInputWrapper = styled.div`
  display: flex;
  width: fit-content;
  gap: 0.5rem;
  padding-bottom: 2rem;

  margin: 0 auto;

  > input {
    text-align: center;
    caret-color: transparent;
  }
`;

export default { DigitsCodeInputWrapper };
