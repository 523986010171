export const renderSeconds = (t, seconds = 0) => {
    const getTwoDigits = (value: number) => (value < 10 ? `0${value}` : value);

    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor((seconds - hour * 3600) / 60);
    const second = Math.floor(seconds - hour * 3600 - minute * 60);

    const suffixes = {
        hours: 'common:time.hours',
        minutes: 'common:time.minutes',
    };

    // eslint-disable-next-line no-nested-ternary
    const translatedSuffix = hour ? suffixes.hours : suffixes.minutes;

    if (!hour) {
        return `${getTwoDigits(minute)}:${getTwoDigits(second)} ${t(translatedSuffix)}`;
    }
    return `${getTwoDigits(hour)}:${getTwoDigits(minute)}:${getTwoDigits(second)} ${t(translatedSuffix)}`;
};


export const calculateSecondsLeft = (date) => {
    const now = new Date();
    return (date.getTime() - now.getTime()) / 1000;
};


export default { renderSeconds, calculateSecondsLeft };
