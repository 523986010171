import styled from 'styled-components';


const StyledCustomItemOptionWrapper = styled.span<{ $isDisabled?:boolean }>`
  display: block;
  padding: 5px 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  cursor: pointer;
  ${({ $isDisabled }) => $isDisabled && 'opacity: .3; cursor: default;'};
`;


export default { StyledCustomItemOptionWrapper };
