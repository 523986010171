import React, { JSX } from 'react';

import ConsentRequestErrors from 'components/common/templates/ConsentRequestErrors';

import { ConsentNotFoundProps } from './ConsentNotFound.types';


function ConsentNotFound({ t }: ConsentNotFoundProps): JSX.Element {

    return (
        <ConsentRequestErrors
            headingCopy={t('consent:newConsentPage.consentNotFound.heading')}
            infoCopy={t('consent:newConsentPage.consentNotFound.info')}
            buttonCopy={t('consent:newConsentPage.consentNotFound.redirectButtonText')}
        />
    );
}


export default ConsentNotFound;
