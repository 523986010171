import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

import StandardFilters from './StandardFilters.layout';
import { StandardFiltersOwnProps } from './StandardFilters.types';


const decorate = compose<ComponentType<StandardFiltersOwnProps>>(
    withTranslation('common'),
);

export default decorate(StandardFilters);
