import React from 'react';

import ScrollableContainer from 'components/common/atoms/ScrollableContainer';
import StandardContentWrapper from 'components/common/atoms/StandardContentWrapper';
import TermsOfService from 'components/common/templates/TermsOfService';


function ToSModal() {
    return (
        <ScrollableContainer>
            <StandardContentWrapper>
                <TermsOfService />
            </StandardContentWrapper>
        </ScrollableContainer>
    );
}

export default ToSModal;
