import { LocationPathname } from '@manigo/manigo-commons';
import { ConsentDetails, ConsentListItem } from '@manigo/manigo-domain-typings';

import { SetQueryParamsPayload } from 'models/app/queryParams';
import { FetchEntityDetailsFailurePayload, FetchEntityDetailsPayload, FetchEntityDetailsSuccessPayload } from 'models/app/standardEntityDetails';


export const FETCH_CONSENT_DETAILS = 'FETCH_CONSENT_DETAILS';
export const FETCH_CONSENT_DETAILS_SUCCESS = 'FETCH_CONSENT_DETAILS_SUCCESS';
export const FETCH_CONSENT_DETAILS_FAILURE = 'FETCH_CONSENT_DETAILS_FAILURE';
export const CLEAR_CONSENT_DETAILS = 'CLEAR_CONSENT_DETAILS';

export interface FetchConsentDetailsQueryParams {
    consentUuid: ConsentDetails['consentId'],
}

export type FetchConsentDetailsPayload = FetchEntityDetailsPayload<FetchConsentDetailsQueryParams>;
export type FetchConsentDetailsSuccessPayload = FetchEntityDetailsSuccessPayload<ConsentDetails>;
export type FetchConsentDetailsFailurePayload = FetchEntityDetailsFailurePayload;

export type SetConsentDetailsQueryParamsPayload = SetQueryParamsPayload<FetchConsentDetailsQueryParams>;


export const REVOKE_CONSENT = 'REVOKE_CONSENT';
export const REVOKE_CONSENT_SUCCESS = 'REVOKE_CONSENT_SUCCESS';
export const REVOKE_CONSENT_FAILURE = 'REVOKE_CONSENT_FAILURE';

export interface RevokeConsentPayload {
    consentUuid: ConsentListItem['consentId'],
    locationPathname: LocationPathname,
}
