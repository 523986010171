import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { RootState } from 'store/rootReducer';


import PublicLayout from './PublicLayout.layout';
import { PublicLayoutOwnProps } from './PublicLayout.types';


const mapStateToProps = (state: RootState) => ({ enhancedCurrentLocation: state.navigation.currentLocation });


const decorate = compose<ComponentType<PublicLayoutOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(PublicLayout);
