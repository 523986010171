import React, { useRef, useLayoutEffect, JSX } from 'react';


import Styled from './CustomItemOption.styled';
import { CustomItemOptionProps } from './CustomItemOption.types';


export const CustomItemOption = ({
    item,
    props,
    methods,
    children,
    itemLabel,
    state,
    itemIndex,
    dataTestId,
}: CustomItemOptionProps<any>): JSX.Element => {
    const isActive = state.cursor === itemIndex;
    const itemRef = useRef<HTMLSpanElement>(null);

    const isSelected = methods?.isSelected(item);
    const classNames = 'react-dropdown-select-item'
        + `${isSelected ? ' react-dropdown-select-item-selected' : ''}`
        + `${isActive ? ' react-dropdown-select-item-active ' : ''}`;

    const isDisabled = props.disabled || item?.disabled;

    const scrollIntoView = () => itemRef?.current?.scrollIntoView?.({
        behavior: 'auto',
        block: 'nearest',
        inline: 'start',
    });

    useLayoutEffect(() => {
        if (state.cursor !== 0 && isActive) {
            scrollIntoView?.();
        }
    }, [state.cursor, itemIndex]);

    const onClick = isDisabled ? undefined : () => methods?.addItem(item);
    return (
        <Styled.StyledCustomItemOptionWrapper
            ref={itemRef}
            role="option"
            aria-disabled={props?.disabled}
            key={`${item?.id}`}
            tabIndex={-1}
            aria-label={itemLabel}
            aria-selected={methods?.isSelected(item)}
            onKeyDown={(event) => event.key === 'Enter' && !isDisabled ? methods?.addItem(item) : undefined}
            onClick={onClick}
            className={classNames}
            $isDisabled={isDisabled}
            data-test-id={`${dataTestId}-item-option-${item?.value}`}
            data-test-index={itemIndex}
        >
            {children}
        </Styled.StyledCustomItemOptionWrapper>
    );
};


export default CustomItemOption;
