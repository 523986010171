import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

import ConsentHasExpired from './ConsentHasExpired.layout';


const decorate = compose<ComponentType>(
    withTranslation('consent'),
);

export default decorate(ConsentHasExpired);
