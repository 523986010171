export const sortingDirectionMapper = (sortingOrder) => {
    if (sortingOrder === 'ascend') {
        return 'asc';
    }

    return 'desc';
};


export function humanFriendlyPermissionName(input: string): string {
    return input
        // Insert a space before capital letters that are not followed by another capital letter (to preserve acronyms)
        .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
        // Insert a space before capital letters that are followed by lowercase letters
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // Trim any leading space, just in case
        .trim();
}


export default {
    sortingDirectionMapper,
    humanFriendlyPermissionName,
};
