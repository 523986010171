import { Box, HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { JSX } from 'react';

import { ButtonConfiguration } from 'models/app/common';


import StandardButton from 'components/common/atoms/Button';

import { AdditionalButtonsProps } from './AdditionalButtons.types';


const Wrapper = styled(Box)`
  flex: 1;
  min-width: fit-content;
  > div > * {
    flex: 0 1;
    min-width: fit-content;
  }
`;
function AdditionalButtons({ additionalButtons }: AdditionalButtonsProps): JSX.Element {
    return (
        <Wrapper>
            {additionalButtons && additionalButtons?.length > 0
                ? (
                    <HStack flexWrap={'wrap'} justifyContent={'flex-end'}>
                        {additionalButtons.map((buttonConfiguration:ButtonConfiguration) =>
                            <StandardButton key={buttonConfiguration.id} {...buttonConfiguration} />)}
                    </HStack>
                )
                : (<></>)}

        </Wrapper>
    );
}

export default AdditionalButtons;
