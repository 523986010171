import React, { forwardRef, useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import Styled from './CustomSelectedItem.styled';
import { CustomSelectedItemProps } from './CustomSelectedItem.types';


const CustomSelectedItem = forwardRef<HTMLInputElement, CustomSelectedItemProps<any>>((
    {
        t,
        props,
        state,
        methods,
        placeholder,
        children,
        renderLabel,
        dataTestId,
    },
    ref,
) => {
    const isSelected = !!state.values?.length;
    const isSearchable = props.searchable;

    const label = useMemo(() => {
        const isMulti = props.multi;
        const singleLabel = isSelected ? state.values[0].label : null;
        const multiLabel = t('standardSelect.nItemsSelected', {
            length: state.values.length,
            selected: props.options.length,
        });

        return isMulti ? multiLabel : singleLabel;
    }, [state.values]);

    return (
        <Styled.Wrapper data-test-id={`${dataTestId}`}>
            {!isSelected && !isSearchable && <Styled.Placeholder>{placeholder}</Styled.Placeholder>}
            {!isSelected && isSearchable && (
                <input
                    ref={ref}
                    placeholder={placeholder}
                    onChange={(event) => {
                        methods.setSearch(event);
                        if (event.target.value.length > 0) {
                            methods.dropDown('open');
                        }
                    }}
                    value={state.search}
                />
            )}
            {isSelected && renderLabel && label}
            {isSelected && !renderLabel && children}
        </Styled.Wrapper>
    );
});

export default withTranslation('common', { withRef: true })(CustomSelectedItem);
CustomSelectedItem.displayName = 'CustomSelectedItem';
