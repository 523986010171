import { Spinner, useColorModeValue } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/system';
import React, { useMemo, JSX, useState, useEffect, useRef } from 'react';
import Select, { SelectItemRenderer, SelectRenderer } from 'react-dropdown-select';
import { withTranslation } from 'react-i18next';

import { SelectOptionFactory } from 'models/app/formContols';

import { useDebounce } from 'hook/useDebounce';
import { useLanguageDirection } from 'hook/useLanguageDirection';
import useScrollThemeCss from 'hook/useScrollThemeCss';

import { getChakraVarColorByChakraHookReturn, getDefaultLightDarkValues } from 'utils/theme-tools';

import CustomItemOption from 'components/common/atoms/select-helpers/custom-options/CustomItemOption';
import CustomSelectedItem from 'components/common/atoms/select-helpers/custom-selected-item/CustomSelectedItem';

import { extractValuesOnlyFromSelectedOptions, findSelectedOptions, getByPath } from './StandardSelect.helpers';
import Styled from './StandardSelect.styled';
import { StandardSelectProps } from './StandardSelect.types';


function StandardSelect({
    t,
    fieldConfiguration,
    useOptionValueOnly = true,
    style,
}: StandardSelectProps): JSX.Element {
    const inputRef = useRef<HTMLInputElement>(null);
    const languageDirection = useLanguageDirection();
    const [searchValue, setSearchValue] = useState('');
    const debouncedValue = useDebounce<SelectOptionFactory['label']>(searchValue || '');

    const { colorMode } = useColorMode();
    const colorScheme = useColorModeValue(...getDefaultLightDarkValues());
    const activeColorScheme = useColorModeValue('gray.200', 'gray.700');
    const errorBorderColor = useColorModeValue('error', 'error_dark');

    const scrollCSS = useScrollThemeCss();


    const handleOnChange = (selectedOptions) => {
        if (fieldConfiguration?.onChange) {
            const tmp = extractValuesOnlyFromSelectedOptions({
                selectedOptions,
                useOptionValueOnly,
                fieldConfiguration,
            });

            fieldConfiguration?.onChange(tmp);
        }
    };

    const isMulti = Boolean(fieldConfiguration?.optionalConfiguration?.isMultiSelect);

    const selectedOptions = useMemo(
        () => {
            return findSelectedOptions({
                queryParamsValue: fieldConfiguration.value,
                useOptionValueOnly,
                fieldConfiguration,
            });
        },
        [fieldConfiguration.value, fieldConfiguration?.optionalConfiguration?.options],
    );
    const customLoadingRenderer = () => (
        <Spinner
            color={colorScheme}
            data-test-id={`${fieldConfiguration.dataTestId}-loading`}
        />
    );
    const getNoDataContent = () => {
        const Component = fieldConfiguration.optionalConfiguration?.customNoDataItem;
        return Component ? <Component /> : t('common:noData');
    };

    const customItemRenderer = ({ item, itemIndex, props, methods, state }: SelectItemRenderer<any>) => {
        const Component = fieldConfiguration?.optionalConfiguration?.customItemRenderer;
        return Component ? (
            <Component
                item={item}
                itemIndex={itemIndex}
                props={props}
                state={state}
                methods={methods}
                dataTestId={fieldConfiguration.dataTestId}
            />
        )
            : (
                <CustomItemOption
                    itemLabel={fieldConfiguration.name}
                    item={item}
                    props={props}
                    state={state}
                    methods={methods}
                    dataTestId={fieldConfiguration.dataTestId}
                >
                    {item?.label}
                </CustomItemOption>
            );
    };
    const customNoDataRenderer = ({ state }) => (
        <Styled.CustomNoDataWrapper>
            {!fieldConfiguration?.isLoading && state.search
                ? (
                    t('standardSelect.nothingFound', { text: state.search })
                )
                : null}

            {!fieldConfiguration?.isLoading && !state.search
                ? getNoDataContent()
                : null}

            {fieldConfiguration?.isLoading
                ? (t('standardSelect.loading'))
                : null}
        </Styled.CustomNoDataWrapper>
    );

    const customContentRenderer = ({ props, state, methods }: SelectRenderer<any>) => {
        if (fieldConfiguration?.optionalConfiguration?.customContentRenderer) {
            const Component = fieldConfiguration?.optionalConfiguration?.customContentRenderer;
            return (
                <Component
                    ref={inputRef}
                    state={state}
                    props={props}
                    methods={methods}
                    placeholder={fieldConfiguration.placeholderText}
                    dataTestId={fieldConfiguration.dataTestId}
                />
            );
        } else {
            return (
                <CustomSelectedItem
                    ref={inputRef}
                    props={props}
                    state={state}
                    methods={methods}
                    placeholder={fieldConfiguration.placeholderText}
                    renderLabel
                    dataTestId={fieldConfiguration.dataTestId}
                />
            );
        }
    };
    const onSearch = ({ props, state, methods }) => {
        const safeString = methods.safeString(state.search);
        if (fieldConfiguration.onSearch) setSearchValue(safeString);
        const regexp = new RegExp(safeString, 'i');
        return methods
            .sortBy()
            .filter((item) => fieldConfiguration.optionalConfiguration?.searchBy?.length ? fieldConfiguration?.optionalConfiguration?.searchBy?.some((key) => {
                return regexp.test(getByPath(item, key) || getByPath(item, props.valueField));
            }) : regexp.test(getByPath(item, props.searchBy) || getByPath(item, props.valueField)));
    };

    useEffect(() => {
        if (fieldConfiguration.onSearch) fieldConfiguration.onSearch(debouncedValue || '');

    }, [debouncedValue]);
    return (
        <Styled.StandardSelectWrapper
            $colorScheme={getChakraVarColorByChakraHookReturn(colorScheme)}
            $activeColorScheme={getChakraVarColorByChakraHookReturn(activeColorScheme)}
            $colorMode={colorMode}
            $scrollCSS={scrollCSS}
            $errorBorderColor={getChakraVarColorByChakraHookReturn(errorBorderColor)}
            $hasError={fieldConfiguration.hasError}
            style={style}
        >
            <Select<SelectOptionFactory>
                direction={languageDirection}
                placeholder={fieldConfiguration.placeholderText || ''}
                values={selectedOptions}
                name={fieldConfiguration.name}
                onChange={handleOnChange}
                color={getChakraVarColorByChakraHookReturn(colorScheme)}
                dropdownPosition={fieldConfiguration?.optionalConfiguration?.dropdownPosition || 'auto'}
                multi={isMulti}
                options={fieldConfiguration.isLoading ? [] : fieldConfiguration?.optionalConfiguration?.options as SelectOptionFactory[]}
                clearable={!!fieldConfiguration?.optionalConfiguration?.isClearable && selectedOptions?.length > 0}
                searchable={!!fieldConfiguration?.optionalConfiguration?.isSearchable}
                loading={fieldConfiguration?.isLoading}
                loadingRenderer={customLoadingRenderer}
                contentRenderer={customContentRenderer}
                itemRenderer={customItemRenderer as any}
                noDataRenderer={customNoDataRenderer}
                searchFn={onSearch}
                disabled={fieldConfiguration?.optionalConfiguration?.isDisabled}
                closeOnClickInput
                onDropdownOpen={() => inputRef?.current?.focus()}
                onDropdownClose={() => inputRef?.current?.blur()}
                onClearAll={() => inputRef?.current?.focus()}
            />
        </Styled.StandardSelectWrapper>
    );
}


export default withTranslation('common')(StandardSelect);
