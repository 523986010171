import { createAction } from '@reduxjs/toolkit';
import { JSX } from 'react';

import { HIDE_CONFIRMATION_ALERT, SHOW_CONFIRMATION_ALERT } from './actions.types';


export interface ShowConfirmationPayload {
    confirmationHeader?: string;
    confirmationQuestion?: string | JSX.Element;
    confirmationSubmitText?: string;
    confirmationDecoration?: JSX.Element;
    onConfirm?: () => void;
}

export const showConfirmation = createAction(SHOW_CONFIRMATION_ALERT, (
    payload: ShowConfirmationPayload,
) => {
    return { payload };
});

export const hideConfirmation = createAction(HIDE_CONFIRMATION_ALERT);

export default {
    showConfirmation,
    hideConfirmation,
};
