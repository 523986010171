import { createReducer } from '@reduxjs/toolkit';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    CLEAR_CONSENTS_LIST,
    FETCH_CONSENTS_LIST, FETCH_CONSENTS_LIST_FAILURE, FETCH_CONSENTS_LIST_SUCCESS,
} from './actions.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from '../standardLists.helpers';


export const consentsReducerName = 'consents';
export const consentsListsPerLocationStoreKeyName = 'consentsListsPerLocation';

export const consentsListQueryParams = {
    limit: 0,
    offset: 0,
    fetchAllConsents: false,
};

export const accountsInitialState = {
    [consentsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    //
};

const createAccountsBuilderCases = (builder) => builder
    .addCase(FETCH_CONSENTS_LIST, (state, action) => {
        state[consentsListsPerLocationStoreKeyName] = updateStandardLists({
            listsState: state[consentsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(FETCH_CONSENTS_LIST_SUCCESS, (state, action) => {
        state[consentsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
            listsState: state[consentsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(FETCH_CONSENTS_LIST_FAILURE, (state, action) => {
        state[consentsListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
            listsState: state[consentsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(CLEAR_CONSENTS_LIST, (state, action) => {
        state[consentsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
            listsState: state[consentsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(SET_QUERY_PARAMS, (state, action) => {
        if (action.payload?.reducerName === consentsReducerName
            && action.payload?.fieldName === consentsListsPerLocationStoreKeyName
        ) {
            state[consentsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                listsState: state[consentsListsPerLocationStoreKeyName],
                action,
            });
        }
    })
    .addCase(CLEAR_CURRENT_USER, (state) => {
        state[consentsListsPerLocationStoreKeyName] = {};
    });


export default createReducer(accountsInitialState, createAccountsBuilderCases);
