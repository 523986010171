import React from 'react';

import ScrollableContainer from 'components/common/atoms/ScrollableContainer';
import StandardContentWrapper from 'components/common/atoms/StandardContentWrapper';
import FAQ from 'components/common/templates/FAQ';


function FAQModal() {
    return (
        <ScrollableContainer>
            <StandardContentWrapper>
                <FAQ />
            </StandardContentWrapper>
        </ScrollableContainer>
    );
}

export default FAQModal;
