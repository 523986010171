import { ConsentDetails, ConsentListItem, DateFullIsoString } from '@manigo/manigo-domain-typings';


const normaliseTellMoneyDate = (date: string): DateFullIsoString | undefined => date
    ? new Date(`${date} GMT+0000`).toISOString()
    : undefined;

export const normaliseTellMoneyDates = (consent: ConsentDetails | ConsentListItem): ConsentDetails | ConsentListItem => {
    return {
        ...consent,
        ...(consent?.consentRequestCreated ? { consentRequestCreated: normaliseTellMoneyDate(consent.consentRequestCreated) } : {}),
        ...(consent?.consentRequestExpiry ? { consentRequestExpiry: normaliseTellMoneyDate(consent.consentRequestExpiry) } : {}),
        ...(consent?.consentGranted ? { consentGranted: normaliseTellMoneyDate(consent.consentGranted) } : {}),
        ...(consent?.consentExpiry ? { consentExpiry: normaliseTellMoneyDate(consent.consentExpiry) } : {}),
        ...(consent?.consentRevoked ? { consentRevoked: normaliseTellMoneyDate(consent.consentRevoked) } : {}),
    };
};


export default {
    normaliseTellMoneyDates,
    //
};
