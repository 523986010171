import { TFunction } from '@manigo/manigo-commons';
import { ConsentStatus } from '@manigo/manigo-domain-typings';


export const createConsentStatusTagHandlers = ({ value, t }: {
    value: ConsentStatus,
    t: TFunction
}):{
    color: string,
    text: string
} => {
    const handlers = [
        {
            predicate: () => value === ConsentStatus.Authorised,
            handler: () => ({ color: 'green', text: t('consent:consentFields.status.values.Authorised') }),
        },
        {
            predicate: () => value === ConsentStatus.AwaitingAuthorisation,
            handler: () => ({ color: 'blue', text: t('consent:consentFields.status.values.AwaitingAuthorisation') }),
        },
        {
            predicate: () => value === ConsentStatus.AuthorisationDeclined,
            handler: () => ({ color: 'red', text: t('consent:consentFields.status.values.AuthorisationDeclined') }),
        },
        {
            predicate: () => value === ConsentStatus.Expired,
            handler: () => ({ color: 'red', text: t('consent:consentFields.status.values.Expired') }),
        },
        {
            predicate: () => value === ConsentStatus.Consumed,
            handler: () => ({ color: 'blue', text: t('consent:consentFields.status.values.Consumed') }),
        },
        {
            predicate: () => value === ConsentStatus.AwaitingExchange,
            handler: () => ({ color: 'blue', text: t('consent:consentFields.status.values.AwaitingExchange') }),
        },
        {
            predicate: () => value === ConsentStatus.Revoked,
            handler: () => ({ color: 'red', text: t('consent:consentFields.status.values.Revoked') }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};
