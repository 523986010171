import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import StandardNumber from 'components/common/atoms/StandardNumber';

import { calculateSecondsLeft, renderSeconds } from './Countdown.helpers';
import { CountdownProps } from './Countdown.types';


const Countdown = ({
    t,
    i18n, tReady, // XXX need to extract from restTextProps to prevent passing down to DOM elements in StandardNumber
    date,
    onCountdownEnd,
    ...restTextProps
}: CountdownProps) => {
    const timer = useRef<undefined | NodeJS.Timeout>(undefined);
    const [secondsLeft, setSecondsLeft] = useState<undefined | number>(undefined);

    useEffect(() => {
        if (date) {
            const seconds = calculateSecondsLeft(date);
            if (seconds > 1) {
                setSecondsLeft(seconds);
            }
        }
        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            timer ? clearTimeout(timer.current) : undefined;
        };
    }, [date]);

    useEffect(() => {
        if (secondsLeft && secondsLeft > 1) {
            timer.current = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
        } else if (secondsLeft && onCountdownEnd) {
            onCountdownEnd();
        }

        return () => {
            if (timer) {
                clearTimeout(timer.current);
            }
        };
    }, [secondsLeft]);

    return (
        <StandardNumber {...restTextProps}>
            {renderSeconds(t, secondsLeft)}
        </StandardNumber>
    );
};

export default withTranslation()(Countdown);
