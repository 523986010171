import { isUndefined, isArray } from '@manigo/manigo-commons';

import { CommonFormFieldConfigItem } from 'models/app/formContols';


const findOptionByValue = ({
    fieldNameToCompare = 'value',
    options,
    value,
}) => {
    return (options || []).find((option) => {
        return value?.value
            ? option?.[fieldNameToCompare] === value?.value
            : option?.[fieldNameToCompare] === value;
    });
};


export function findSelectedOptions({
    queryParamsValue,
    useOptionValueOnly,
    fieldConfiguration,
}: {
    queryParamsValue?: any, // TODO string, number, undefined, object, string[], object[] ?
    useOptionValueOnly?: boolean,
    fieldConfiguration: CommonFormFieldConfigItem,
}) {
    const isMultiSelect = Boolean(fieldConfiguration.optionalConfiguration?.isMultiSelect);
    const matchingSingleOption = findOptionByValue({
        value: queryParamsValue,
        options: fieldConfiguration?.optionalConfiguration?.options,
    });

    const handlers = [
        {
            predicate: () => isUndefined(queryParamsValue),
            handler: () => [],
        },
        {
            predicate: () => !isMultiSelect,
            handler: () => matchingSingleOption ? [matchingSingleOption] : [],
        },
        {
            predicate: () => useOptionValueOnly && isMultiSelect,
            handler: () => isArray(queryParamsValue)
                ? queryParamsValue.map((value) => findOptionByValue({ value, options: fieldConfiguration?.optionalConfiguration?.options }))
                : [],
        },

        {
            predicate: () => true,
            handler: () => [],
        },
    ];


    return handlers.filter(({ predicate }) => predicate())[0].handler();

}


export function extractValuesOnlyFromSelectedOptions({
    selectedOptions,
    useOptionValueOnly,
    fieldConfiguration,

}: {
    selectedOptions?: any, // TODO string, number, undefined, object, string[], object[] ?
    useOptionValueOnly?: boolean,
    fieldConfiguration: CommonFormFieldConfigItem,
}) {

    const isMultiSelect = Boolean(fieldConfiguration.optionalConfiguration?.isMultiSelect);

    const handlers = [
        {
            predicate: () => useOptionValueOnly && !isMultiSelect,
            handler: () => selectedOptions?.[0]?.value,
        },
        {
            predicate: () => useOptionValueOnly && isMultiSelect,
            handler: () => isArray(selectedOptions) ? selectedOptions.map((option) => option?.value) : undefined,
        },

        {
            predicate: () => !useOptionValueOnly && !isMultiSelect,
            handler: () => selectedOptions?.[0],
        },
        {
            predicate: () => true,
            handler: () => selectedOptions,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

export const getByPath = (object, path) => {
    if (!path) {
        return;
    }

    return path.split('.').reduce((acc, value) => acc[value], object);
};
