import { useBreakpointValue } from '@chakra-ui/react';
import React, { useRef } from 'react';

import { WL_CONFIG } from 'config/environment';

import useIsOverflowing from 'hook/useIsOverflowing';

import ColorModeToggler from 'components/common/atoms/CollorModeToggler';
import LanguageSelector from 'components/common/organisms/LanguageSelector';

import Styled from './PublicNavbar.styled';


const PublicNavbar = () => {
    const navRef = useRef<HTMLDivElement>(null);

    const { isOverflowing } = useIsOverflowing(navRef);
    const isDesktop = Boolean(useBreakpointValue({ base: false, lg: true })) && !isOverflowing;
    return (
        <Styled.UnauthorisedHeadingWrapper $isDesktop={isDesktop}>
            <Styled.UnauthorisedLogoWrapper>
                {WL_CONFIG?.brandFullLogoUrl || WL_CONFIG?.brandSignetLogoUrl
                    ? (
                        <Styled.UnauthorisedLogo
                            src={WL_CONFIG?.brandFullLogoUrl || WL_CONFIG?.brandSignetLogoUrl}
                            srcDark={WL_CONFIG?.brandFullDarkLogoUrl || WL_CONFIG?.brandSignetDarkLogoUrl}
                            alt={`${WL_CONFIG?.brandFullName} logo`}
                            boxSize="12"
                        />
                    )
                    : <></>}
                <div>
                    <h2>{WL_CONFIG.brandShortName}</h2>
                    <h3>{WL_CONFIG.applicationName}</h3>
                </div>
            </Styled.UnauthorisedLogoWrapper>

            <Styled.ActionButtons>
                {isDesktop && <LanguageSelector />}
                {isDesktop && <ColorModeToggler />}
            </Styled.ActionButtons>
        </Styled.UnauthorisedHeadingWrapper>
    );
};

export default PublicNavbar;
