import { HttpStatus, ApiError } from '@manigo/manigo-commons';

import { ToastType } from 'models/app/toast';

import { developmentMode } from 'config/environment';

import { showToast } from 'store/application/actions';
import { clearCurrentUser } from 'store/current-user/actions';


export const withSkipImages = { skipImages: true };

export const createErrorActions = (error, i18n) => {
    const handlers = [
        {
            predicate: () => error.unauthorized && !error.handled,
            handler: () => [clearCurrentUser()],
        },
        {
            predicate: () => true,
            handler: () => [showToast(
                {
                    type: ToastType.error,
                    message: developmentMode ? error?.message : i18n.t('unauthorised:genericApiError'),
                    id: 'network',
                },
            )],
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createApiError = (fetchError, i18n): ApiError => {
    const { config } = fetchError;
    const status = fetchError.response?.status;

    const error = new Error(fetchError?.apiResponseObject?.message || '') as ApiError;
    error.messageCode = fetchError.apiResponseObject?.message_code;
    error.handled = !config.noErrorHandling;
    error.forbidden = status === HttpStatus.FORBIDDEN;
    error.unauthorized = status === HttpStatus.UNAUTHORIZED || (status === HttpStatus.INTERNAL_SERVER_ERROR && fetchError.message === 'jwt expired');
    error.offline = status === HttpStatus.SERVICE_UNAVAILABLE || status === HttpStatus.GATEWAY_TIMEOUT;

    error.status = status;
    error.message = fetchError.response?.message || fetchError.apiResponseObject.message;
    error.data = fetchError.apiResponseObject;
    error.actions = createErrorActions(error, i18n);

    return error;
};
