import { TFunction } from '@manigo/manigo-commons';
import { ConsentScope } from '@manigo/manigo-domain-typings';


export const createConsentScopeTagHandlers = ({ value, t }: {
    value: ConsentScope,
    t: TFunction
}):{
    color: string,
    text: string
} => {
    const handlers = [
        {
            predicate: () => value === ConsentScope.accounts,
            handler: () => ({ color: 'blue', text: t('consent:consentFields.scope.values.accounts') }),
        },
        {
            predicate: () => value === ConsentScope.fundsconfirmations,
            handler: () => ({ color: 'blue', text: t('consent:consentFields.scope.values.fundsConfirmations') }),
        },
        {
            predicate: () => value === ConsentScope.payments,
            handler: () => ({ color: 'blue', text: t('consent:consentFields.scope.values.payments') }),
        },
        {
            predicate: () => true,
            handler: () => ({ color: 'blue', text: t('consent:consentFields.scope.values.unknown') }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};
