import { configureStore } from '@reduxjs/toolkit';
// import storageSession from 'redux-persist/lib/storage/session';
// import { persistStore, persistReducer } from 'redux-persist';
import * as Sentry from '@sentry/react';
import { User } from '@sentry/react';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { isObservable } from 'rxjs';

import { developmentMode, ENABLE_DEV_TOOLS, ENABLE_REDUX_LOGGER } from 'config/environment';

import rootEpics from 'store/rootEpics';

import { rootReducer } from './rootReducer';


const logger = createLogger({ level: 'info', diff: true, duration: true });

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    configureScopeWithState: (scope, state) => {
        if (state.currentUser && state.currentUser.userData) {
            Sentry.setUser({
                sessionUuid: state.application.state,
                id: state.currentUser.userData.identifiers.id,
                email: state.currentUser.userData.user.email,
                username: state.currentUser.userData.user.username,
                clientId: state.currentUser.userData.identifiers.clientId,
                roleId: state.currentUser.userData.identifiers.roleId,
            } as User);
        }
    },
});

// const persistConfig = {
//     key: 'root',
//     storage: storageSession,
// };

export function createStore(dependencies) {
    const epicMiddleware = createEpicMiddleware({ dependencies });

    const middleware = [
        ...(developmentMode || ENABLE_REDUX_LOGGER ? [logger] : []),
        epicMiddleware,
    ];
    // const persistedReducer = persistReducer(persistConfig, rootReducer);

    const storeInstance = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
            // @ts-expect-error TMP quick fix, no where the diff (to BWP) is coming from
        }).concat(middleware),
        devTools: developmentMode || ENABLE_DEV_TOOLS,
        enhancers: [sentryReduxEnhancer],
    });


    const services = [...Object.values(dependencies)];
    // XXX see `get action$()` in Location Service!
    services
        .map((item: any) => item.action$)
        .filter(isObservable)
        .forEach((action$) => action$.subscribe(storeInstance.dispatch));

    epicMiddleware.run(rootEpics);

    // const persistor = persistStore(storeInstance);

    return { store: storeInstance };
    // return { store: storeInstance, persistor };
}


export default createStore;
