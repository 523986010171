import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { hideConfirmation } from 'store/alert/actions';
import { RootState } from 'store/rootReducer';

import Alert from './Alert.layout';
import { AlertStateProps } from './Alert.types';


const mapStateToProps = (state:RootState): AlertStateProps => ({ ...state.alert });
const mapDispatchToProps = (dispatch) => ({ dispatchHide: () => dispatch(hideConfirmation()) });

const decorate = compose < ComponentType >(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(Alert);
