import React, { JSX, ReactNode } from 'react';

import Styled from './AppBlurOverlay.styled';


function AppBlurOverlay({
    children,
    activeBlur,
}: { activeBlur: boolean, children: ReactNode }) : JSX.Element {
    return (
        <Styled.AppBlurOverlay $activeBlur={activeBlur} className="app-height">
            {children}
        </Styled.AppBlurOverlay>
    );
}


export default AppBlurOverlay;
